import i18n from '../../shared/i18n/i18n';
const UnitUtilities = {
	unitTypes: {
		scorm: "Interactive",
		form: "Form",
		event: "Face to Face",
		document: "Document",
		certificate: "Certificate",
		action: "Action",
		approval: "Approval",
		download: "Download",
		upload: "Upload"
	},
	getUnitTypeIcon: function (type) {
		return (type === UnitUtilities.unitTypes.scorm || type === "Scorm") ? "icon-grid"
			: (type === UnitUtilities.unitTypes.event || type === "Event") ? "icon-user-pair"
				: type === UnitUtilities.unitTypes.certificate ? "icon-certificate-2"
					: type === UnitUtilities.unitTypes.document ? "icon-document"
						: type === UnitUtilities.unitTypes.form ? "icon-document-edit"
							: type === UnitUtilities.unitTypes.action ? "icon-star"
								: type === UnitUtilities.unitTypes.approval ? "icon-checkbox-checked"
									: type === UnitUtilities.unitTypes.download ? "icon-download"
										: type === UnitUtilities.unitTypes.upload ? "icon-download rotate-180"
											: "";
	},
	getUnitTypeName: function (type) {
		return type === UnitUtilities.unitTypes.scorm || type === "Scorm" ? i18n.t("interactive")
			: type === UnitUtilities.unitTypes.event || type === "Event" ? i18n.t("face_to_face")
				: type === UnitUtilities.unitTypes.certificate ? i18n.t("certificate")
					: type === UnitUtilities.unitTypes.document ? i18n.t("document")
						: type === UnitUtilities.unitTypes.form ? i18n.t("form")
							: type === UnitUtilities.unitTypes.action ? i18n.t("action")
								: type === UnitUtilities.unitTypes.approval ? i18n.t("approval")
									: type === UnitUtilities.unitTypes.download ? i18n.t("download")
										: type === UnitUtilities.unitTypes.upload ? i18n.t("upload")
											: "";
	}
};

export default UnitUtilities;
