import m from 'mithril';
import Component_SubNavBar from '../layout/subNavBar';
import PublicComponent_All_Courses from "./component_all_courses";
import PublicComponent_My_Courses from "./component_my_courses";
import SessionController from "../../shared/session";

let loading = false;
let tab = 'allCourses';

function setTab(newTab) {
    tab = newTab;
}
let currentPage;
const PublicPage_Courses = {
    oninit: function () {
        currentPage = 'courses';
        if(SessionController.session && SessionController.session.identity) {
            if(SessionController.session.identity.isGuest){
                tab = 'allCourses';
            }else {
                tab = 'myCourses';
            }
        }else {
            tab = 'allCourses';
        }

    },
    onremove: function () {
        document.body.classList.remove('profile-style');
    },
    view: function () {
        return [
            m('.content-wrapper SecondaryTheme library', [
                m(Component_SubNavBar, {tab: tab, callback:setTab}),
                m('.centered-layout', [
                    m('.content-box', [
                        !loading ? [
                            tab === 'allCourses' ? m(PublicComponent_All_Courses)
                            : (tab === 'myCourses' &&  SessionController.session && SessionController.session.identity) ? m(PublicComponent_My_Courses)
                                    : ''
                        ] : m(Loading)])
                ])
            ])
        ];
    }
};
export default PublicPage_Courses;

