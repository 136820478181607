import m from 'mithril';
import RouterService from './services/router.service';

const Requester = {
    headers: function (extraHeaders) {
        const headers = {
            "x-request-id": Requester.generateRandomString(),
            "TimeOffset": new Date().getTimezoneOffset(),
            "Locale": localStorage.getItem("selected-locale") || localStorage.getItem("preferred-locale") || ""
        }
        if (!!extraHeaders) {
            for (let attrName in extraHeaders) {
                headers[attrName] = extraHeaders[attrName];
            }
        }
        return headers;
    },
    abort: function (ref) {
        if (ref)
            ref.abort();
        ref = null;
    },
    get: function (url) {
        return m.request({
            method: "GET",
            url: url,
            withCredentials: true,
            headers: Requester.headers()
        }).catch(function (error) {
            Requester.handleError(error);
        });
    },
    getFile: function (url) {
        return m.request({
            method: "GET",
            url: url,
            withCredentials: true,
            responseType: "arraybuffer",
            headers: Requester.headers()
        }).catch(function (error) {
            Requester.handleError(error);
        });
    },
    post: function (url, data, headers) {
        return m.request({
            method: "POST",
            url: url,
            body: data,
            withCredentials: true,
            headers: Requester.headers(headers)
        }).catch(function (error) {
            Requester.handleError(error);
        });
    },
    upload: function (url, data, bar) {
        return m.request({
            method: "POST",
            url: url,
            body: data,
            withCredentials: true,
            headers: Requester.headers(),
            config: function (xhr) {
                xhr.upload.addEventListener("progress", function (e) {
                    let progress = e.loaded / e.total;
                    if (document.querySelectorAll(".with-info" + (bar || ""))[0])
                        document.querySelectorAll(".with-info" + (bar || ""))[0].setAttribute("data-info", Math.round(progress * 100) + "% uploaded");

                    if (document.querySelectorAll(".loading-width" + (bar || ""))[0]) {
                        document.querySelectorAll(".loading-width" + (bar || ""))[0].style.width = Math.round(progress * 100) + "%";

                        if (Math.round(progress * 100) === 100)
                            document.querySelectorAll(".loading-width" + (bar || ""))[0].classList.add("upload-complete");
                    }

                    m.redraw();
                });
            }
        }).catch(function (error) {
            Requester.handleError(error);
        });
    },
    delete: function (url, data) {
        return m.request({
            method: "DELETE",
            url: url,
            body: data,
            withCredentials: true,
            headers: Requester.headers()
        }).catch(function (error) {
            Requester.handleError(error);
        });
    },
    handleError: function (error) {
        console.log(error);
        if (error.code === 401 || error.code === 403) {
            console.log('Authentication ERROR', error.code);
            RouterService.navigateToAccountLogout();
        } else
            throw error;
    },
    generateRandomString: function() {
        return `${Date.now()}-${Math.random().toString(36).substring(2)}`;
    }
};

export default Requester;
