import m from 'mithril';
import i18n from "../../shared/i18n/i18n";
import iconGridSolid from '../../shared/img/icon-grid-solid.svg';
import iconCertificate from '../../shared/img/icon-certificate.svg';
import iconCrews from '../../shared/img/icon-crews.svg';
import iconSettings from '../../shared/img/icon-settings.svg';
import iconAssessment from '../../shared/img/icon-settings.svg';
import SessionController from "../../shared/session";

// Global let to keep track of current page for menu
let getCurrentPage = (pageUrl) => {
    switch (pageUrl) {
        case '/public':
            return 'courses';
        case '/public/':
            return 'courses';
        case '/public/profile':
            return 'profile';
        default:
            return '';
    }
};

let tab, callback;

const Component_SubNavBar = {
    oninit: function (vnode) {
        if(!SessionController.session || !SessionController.session.identity || !tab) {
            tab = 'allCourses';
        }
        callback = vnode.attrs.callback;
    },
    view: function (vnode) {
        tab = vnode.attrs.tab;
        let currentPage = getCurrentPage(m.route.get());
        return [
            currentPage === 'courses' && m('.SubNavBar', [
                m('.SubNavBar__width', [

                    m(tab === 'allCourses' ? '.SubNavBar__navItem-selected': '.SubNavBar__navItem', {
                        onclick: function () {
                            callback('allCourses');
                        }
                    }, [
                        m('.SubNavBar__navIcon', { style: 'width: 2rem;' }, [
                            m.trust(iconGridSolid)
                        ]),
                        i18n.t('allCourses')
                    ]),
                    SessionController.session && SessionController.session.identity?  m(tab === 'myCourses' ? '.SubNavBar__navItem-selected' : '.SubNavBar__navItem',{
                        onclick: function () {
                            callback('myCourses');
                        }
                    }, [
                        m('.SubNavBar__navIcon', { style: 'width: 2rem;' }, [
                            m.trust(iconGridSolid)
                        ]),
                        i18n.t('myCourses')
                    ]) : '',
                ])
            ]),
            currentPage === 'profile' && m('.SubNavBar', [
                m('.SubNavBar__width', [
                    m(tab === 'enrolments' ? '.SubNavBar__navItem-selected.m-tooltip' : '.SubNavBar__navItem.m-tooltip', {
                        onclick: function () {
                            callback('enrolments');
                        }, "m-data-tooltip" : i18n.t('myEnrolments')
                    }, [
                        m('.SubNavBar__navIcon', { style: 'width: 2rem;' }, [
                            m.trust(iconAssessment)
                        ]),
                       m('span.SubNavBar_text', i18n.t('myEnrolments'))
                    ]),
                    m(tab === 'certs' ? '.SubNavBar__navItem-selected.m-tooltip' : '.SubNavBar__navItem.m-tooltip', {
                        onclick: function () {
                            callback('certs');
                        }, "m-data-tooltip" : i18n.t('myCertificates')
                    }, [
                        m('.SubNavBar__navIcon', { style: 'width: 2rem;' }, [
                            m.trust(iconCertificate)
                        ]),
                        m('span.SubNavBar_text.m-data-tooltip', i18n.t('myCertificates'))
                    ]),
                    m(tab === 'orgs' ? '.SubNavBar__navItem-selected.m-tooltip' : '.SubNavBar__navItem.m-tooltip', {
                        onclick: function () {
                            callback('orgs');
                        }, "m-data-tooltip" : i18n.t('myOrganisations')
                    }, [
                        m('.SubNavBar__navIcon', { style: 'width: 2rem;' }, [
                            m.trust(iconCrews)
                        ]),
                        m('span.SubNavBar_text.m-data-tooltip', i18n.t('myOrganisations'))
                    ]),
                    m(tab === 'settings' ? '.SubNavBar__navItem-selected.m-tooltip' : '.SubNavBar__navItem.m-tooltip', {
                        onclick: function () {
                            callback('settings');
                        }, "m-data-tooltip" : i18n.t('mySettings')
                    }, [
                        m('.SubNavBar__navIcon', { style: 'width: 2rem;' }, [
                            m.trust(iconSettings)
                        ]),
                        m('span.SubNavBar_text.m-data-tooltip', i18n.t('mySettings'))
                    ]),
                ])
            ]),
        ];
    }
};

export default Component_SubNavBar;

