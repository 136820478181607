import Requester from "./request";
import Gateway from "./app_settings";
import Toasts from "./toasts";
import i18n from "./i18n/i18n";

const UniqueName = {
    checkUniqueCourseName : function (courseId, courseName) {
         UniqueName.courseLoading = true;
        Requester.get(Gateway.admin + `courses/isUnique?courseId=${courseId}&courseName=${courseName}`)
            .then(function (result) {
                UniqueName.isCourseNameUnique = result.value;
                if(!result.value) {
                    Toasts.add("error", i18n.t("error"), i18n.t("unique"))
                }
            }).catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        }).finally(function () {
            UniqueName.courseLoading = false;
        })
    },

    checkUniqueUnitName: function (unitId, unitName) {
    Requester.get(Gateway.admin + `units/isUnique?unitId=${unitId}&unitName=${unitName}`)
        .then(function (result) {
            UniqueName.isUnitNameUnique = result.value;
            if(!result.value) {
                Toasts.add("error", i18n.t("error"), i18n.t("unique"))
            }
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        UniqueName.unitLoading = false;
    })
}
}

export default UniqueName;
