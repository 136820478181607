import m from "mithril";
import i18n from "../../shared/i18n/i18n";
import Auth from "../../shared/auth";
import ModalManager from "../../shared/modal";
import Component_Enrolments from "./component_enrolments";
import Modal_Enrolments_New from "./modal_enrolments_new";

let enrolments, loading, currentPage;

function setEnrolments(enrs) {
    enrolments = enrs;
}

function createEnrolments() {
    ModalManager.open(i18n.t("create_enrolments"), [], Modal_Enrolments_New);
}


const Page_Enrolments = {

    oninit: function () {
        currentPage = "enrolments";
    },
    view: function () {
        return [
            m(".content-box", [
                m(".toolbar", {class: loading ? "disable-buttons" : ""}, [
                    m(".toolbar-section", [
                        m("h1", i18n.t("enrolments"))
                    ]),
                    m(".toolbar-section", [
                        Auth.validateRights(["enrolments_create"], m("button.with-icon btn-cta", {onclick: createEnrolments}, [m("i.icon-plus"), i18n.t("create_enrolment")]))
                    ])
                ])
            ]),
            m(Component_Enrolments, {callback: setEnrolments})
        ];
    }
};

export default Page_Enrolments;

