import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import i18n from "../../shared/i18n/i18n";
import SessionController from "../../shared/session";
import Loading from "../../shared/loading";
import RouterService from "../../shared/services/router.service"

const PublicPage_Verify = {
    oninit: function (vnode) {
        if (SessionController.session && SessionController.session.identity) {
            RouterService.navigateOnLogin();
            Toasts.add("success", i18n.t("already_logged_in"), "");
        }

        let user = m.route.param("user");
        let token = m.route.param("token");

        if (!user || !token) {
            RouterService.navigateToPublicLogin();
        } else {
            Requester.post(Gateway.identity + "verify", {user: user, token: token})
                .then(function (result) {
                    SessionController.setSession(result);
                    RouterService.navigateOnLogin();
                })
                .catch(function (error) {
                    RouterService.navigateToPublicLogin();
                    Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
                });
        }
    },
    view: function () {
        return [
            m(Loading, {class: "loading-inline"})
        ];
    }
};
export default PublicPage_Verify;
