import m from "mithril";
import Component_Unit_Event_Sessions from "./component_unit_event_sessions";
import Component_Unit_Event_Session from "./component_unit_event_session";
import Component_Unit_Event_Settings from "./component_unit_event_settings";
import i18n from "../../../shared/i18n/i18n";
import DateUtilities from "../../../shared/utilities/date_utilities";

let loading, unit, selectedSession, tab = "sessions";

function viewSession(session) {
    selectedSession = session;
    tab = "session";
}

function closeSession() {
    tab = "sessions";
    selectedSession = null;
}

function getSessionTime() {
    if (!selectedSession) {
        return null;
    }
    return DateUtilities.getFormattedDate(selectedSession.properties.start ? selectedSession.properties.start : selectedSession.properties.startDateTime);
}

function getSessionLocation() {
    return selectedSession?.properties.location;
}

const Page_Unit_Event = {
    oninit: function (vnode) {
        unit = vnode.attrs.unit;
        tab = "sessions";
        selectedSession = null;
    },
    view: function () {
        return [
            m(".content-box", [
                m("h1", i18n.t("face_to_face_unit") + ": " + unit.properties.name),
                selectedSession ? m(".pt-2", [
                    m("span.pr-2", getSessionTime()),
                    m("span.pl-2", getSessionLocation())
                ]) : null,
                m(".toolbar", {class: loading ? "disable-buttons" : ""}, [
                    m(".toolbar-section with-menu", [
                        m(".toolbar-menu", [
                            m("div", {
                                class: tab === "sessions" ? "active" : "", onclick: function () {
                                    tab = "sessions";
                                }
                            }, i18n.t("sessions")),
                            m("div", {
                                class: tab === "settings" ? "active" : "", onclick: function () {
                                    tab = "settings";
                                }
                            }, i18n.t("settings"))
                        ])
                    ])
                ])
            ]),
            !loading ? [
                unit ? [
                    tab === "sessions" ? m(Component_Unit_Event_Sessions, {unit: unit, sessionCallback: viewSession})
                        : tab === "session" ? m(Component_Unit_Event_Session, {
                                unit: unit,
                                session: selectedSession,
                                callback: closeSession
                            })
                            : tab === "settings" ? m(Component_Unit_Event_Settings, {unit: unit.properties})
                                : ""
                ] : m(".text-gray padding", i18n.t("unable_to_load_unit"))
            ] : m(".text-gray padding", [i18n.t("loading"), m(".loading-dots")])
        ];
    }
};
export default Page_Unit_Event;
