import m from "mithril";
import SessionController from "../../shared/session";
import Toasts from "../../shared/toasts";
import Loading from "../../shared/loading";
import AdminSidebar from "./sidebar";
import i18n from "../../shared/i18n/i18n";
import RouterService from "../../shared/services/router.service";
import ModalManager from "../../shared/modal";
import Modal_Consent_Form from "../../shared/components/modal_consent_form";

let loading = false;

function openConsent() {
    if (SessionController?.session?.identity.consentRequired) {
        ModalManager.open(i18n.t("terms_and_privacy"), ['no-close', 'modal-mid'], Modal_Consent_Form);
    }
}

function auth() {
    SessionController.checkSession();
    if (!SessionController.session || !SessionController.session.identity || !SessionController.session.identity.rights.length > 0) {
        loading = true;
        SessionController.getSession()
            .then(function () {
                if (!SessionController.session.identity) {
                    RouterService.navigateToAccountLogin();
                } else if (!SessionController.session.identity.rights.length > 0) {
                    RouterService.navigateToAdmin();
                }
            })
            .catch(function () {
                RouterService.navigateToAdmin();
            })
            .finally(function () {
                loading = false;
                m.redraw();
            });
    }
}

const AdminLayout = {
    oninit: function (vnode) {
        if (m.route.param("sso-auth"))
            SessionController.verifySso(m.route.param("sso-auth"))
                .catch(function (error) {
                    Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
                    auth();
                });
        else
            auth();

        let theme = localStorage.getItem("theme");
        if (theme) {
            document.documentElement.setAttribute("theme", theme);
        }
        openConsent();
    },
    view: function (vnode) {
        return [m("main", [
                !loading ? [
                    m(AdminSidebar),
                    m("#content", vnode.children),
                ] : m(Loading)
            ]),
            m("#toaster")
        ];
    }
};

export default AdminLayout;
