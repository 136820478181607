import m from "mithril";
import FormUtilities from "../../../shared/utilities/form_utilities";
import Requester from "../../../shared/request";
import Gateway from "../../../shared/app_settings";
import ModalManager from "../../../shared/modal";
import Toasts from "../../../shared/toasts";
import i18n from "../../../shared/i18n/i18n";
import UniqueName from "../../../shared/uniqueName";
import RouterService from "../../../shared/services/router.service";

let saving = false;
let uploading = false;
let unit = {
    name: "",
    description: ""
};
let modalId;

function saveUnit() {
    if (FormUtilities.validateFields(".modal input")) {
        saving = true;
        Requester.post(Gateway.admin + "units/document", unit)
            .then(function (resultDocumentUnitId) {
                var docsToUpload = document.getElementById("document-file-input");

                if (docsToUpload.files.length > 0)
                    uploadDocument(resultDocumentUnitId);
                else {
                    saving = false;
                    ModalManager.close(modalId);
                    RouterService.navigateToUnit(resultDocumentUnitId);
                }
            }).catch(function (error) {
            saving = false;
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        });
    }
}

function uploadDocument(documentUnitId) {
    let d = new FormData();
    if (document.getElementById("document-file-input").files[0] !== null) {
        let file = document.getElementById("document-file-input").files[0];
        d.append("file", file, file.name);
    }

    saving = false;
    uploading = true;

    Requester.upload(Gateway.admin + "units/document/" + documentUnitId + "/upload-file", d)
        .then(function () {
            Toasts.add("success", i18n.t("saved_changes"), "");
            ModalManager.close(modalId);
            RouterService.navigateToUnit(documentUnitId);
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .finally(function () {
            uploading = false;
        });
}

const Modal_Unit_Document_New = {
    oninit: function() {
        unit = {
            name: "",
            description: ""
        };
    },
    view: function () {
        return [
            m(".form-section required", [
                m(".form-label", i18n.t("name")),
                m("input[type=text]", {
                    onchange: function () {
                        UniqueName.checkUniqueUnitName("new", this.value)
                    },
                    oninput: function () {
                        unit.name = this.value;
                    }, value: unit.name
                })
            ]),
            m(".form-section", [
                m(".form-label", i18n.t("description")),
                m("textarea", {
                    oninput: function () {
                        unit.description = this.value;
                    }, value: unit.description
                })
            ]),
            m(".form-section", [
                m(".form-label", i18n.t("document")),
                m("div", i18n.t("supported_file_types_pdf")),
                m("input[type=file].form-input#document-file-input"),
                uploading ? [
                    m(".loading-bar mt-1", m(".loading-width"))
                ] : ""
            ]),
            m(".form-buttons", [
                !saving && !uploading && (UniqueName.isUnitNameUnique) && !(UniqueName.unitLoading) ? m("button", {onclick: saveUnit}, i18n.t("save")) : (UniqueName.isUnitNameUnique) ? m("button.btn-text no-click", [i18n.t("saving"), m(".loading-dots")]) :
                    m("button.btn-text no-click", [i18n.t("save")])
            ])
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
};
export default Modal_Unit_Document_New;
