import m from 'mithril';

let Loading = {
    view: function (vnode) {
        return [
            m(".loading", { class: vnode.attrs.class }, [
                m("span"),
                m("span"),
                m("span"),
                m("span"),
                m("span")
            ])
        ];
    }
}

export default Loading;
