import sanitizeHtml from 'sanitize-html';
import i18n from '../i18n/i18n';
import m from 'mithril';
import Toasts from '../toasts';

const TextUtilities = {
    sanitizeAllTagsAndAttributes: function(text) {
        const options = {
            allowedTags: [], // No tags are allowed
            allowedAttributes: {} // No attributes are allowed
        };
        return sanitizeHtml(text, options);
    },
    sanitizeUrl: function(url) {
        try {
            const parsedUrl = new URL(url);
            return parsedUrl.href; // Ensures the URL is valid
        } catch (error) {
            console.error('Invalid URL:', url);
            return '#'; // Return a safe fallback URL
        }
    },
    createTextWithLinks: function (textKey, links) {
        let text = this.sanitizeAllTagsAndAttributes(i18n.t(textKey));
        let hyperlinkHTML = '';

        links.forEach((linkPair, index) => {
            const { linkTextKey, linkUrl } = linkPair;
            const hyperlink = m('a.hyperlink', { href: this.sanitizeUrl(linkUrl), target: '_blank' }, this.sanitizeAllTagsAndAttributes(i18n.t(linkTextKey)));

            const tempDiv = document.createElement('div');
            m.render(tempDiv, hyperlink);
            hyperlinkHTML = tempDiv.innerHTML;

            const placeholder = `{${index}}`;
            text = text.replace(placeholder, hyperlinkHTML);
        });

        return text;
    },
    safeDecodeURIComponent: function (textMayEncoded, ignoreError) {
        try {
            return textMayEncoded ? decodeURIComponent(textMayEncoded) : "";
        } catch (e) {
            // Set ignoreError to true to bypass errors from double-decoding.
            if (ignoreError) {
                return textMayEncoded;
            } else if (e instanceof URIError) {
                console.log('ERROR:', i18n.t('decodeURI_error_dev'), e);
                Toasts.add('error', i18n.t('error'), i18n.t('decodeURI_error_user'));
                return '';
            } else {
                throw e;
            }
        }
    }
};

export default TextUtilities;
