import m from "mithril";
import DateUtilities from "../../shared/utilities/date_utilities";
import OrgFileService from "../../shared/storage/org_file_service";
import i18n from "../../shared/i18n/i18n";
let org;

const Component_Organisation_Details = {
    oninit: function (vnode) {
        org = vnode.attrs.org;
    },
    view: function () {
        return [
            m(".content-box details-list", [
                org ? [
                    m(".form-section", [
                        m(".text-label", i18n.t("name")),
                        org.name
                    ]),
                    m(".form-section required", [
                        m(".form-label", i18n.t("onsite_scope_id")),
                        m("input[type=number]", { oninput: function () {  org.scopeId = this.value; }, value:  org.scopeId })
                    ]),
                    m(".form-section", [
                        m(".text-label", i18n.t("logo")),
                        org && org.logoName ? m("img.logo-preview mt-2", {src: OrgFileService.getLogoPath(org.id, org.logoName)}) : ""
                    ]),
                    m(".form-section", [
                        m(".text-label", i18n.t("hostname")),
                        org.hostname
                    ]),
                    m(".form-section", [
                        m(".text-label", i18n.t("created")),
                        DateUtilities.getFormattedDate(org.creationDate)
                    ])
                ] : ""
            ])
        ];
    }
};

export default Component_Organisation_Details;
