const RoleService = {
    getAccountRoleLabel: function(role) {
        switch(role) {
            case this.roles.owner:
                return "AccountOwner";
            case this.roles.internalSuperuser:
                return "AccountInternalSuperuser";
            case this.roles.configuration:
                return "AccountConfiguration";
            case this.roles.supportAdministrator:
                return "AccountSupportAdministrator";
            case this.roles.clientSuperuser:
                return "OrganisationClientSuperuser";
            case this.roles.clientAdministrator:
                return "OrganisationClientAdministrator";
            case this.roles.clientTrainer:
                return "OrganisationClientTrainer";
            case this.roles.learner:
                return "LearnerDefault";
        }
    },

    isInternalRole: function(role) {
        return role === this.roles.owner
            || role === this.roles.internalSuperuser
            || role === this.roles.configuration
            || role === this.roles.supportAdministrator;
    },

    roles: {
        learner: "Learner",
        clientTrainer: "ClientTrainer",
        clientAdministrator: "ClientAdministrator",
        clientSuperuser: "ClientSuperuser",
        supportAdministrator: "SupportAdministrator",
        configuration: "Configuration",
        internalSuperuser: "InternalSuperuser",
        owner: "Owner",
        none: "None"
    },

    intToRole: function(value) {
        switch (value) {
            case 0:
                return this.roles['none'];
            case 1:
                return this.roles['owner'];
            case 2:
                return this.roles['internalsuperuser'];
            case 3:
                return this.roles['configuration'];
            case 4:
                return this.roles['supportadministrator'];
            case 5:
                return this.roles['clientsuperuser'];
            case 6:
                return this.roles['clientadministrator'];
            case 7:
                return this.roles['clienttrainer'];
            default :
                return Learner;
        }
    }

}

export default RoleService;
