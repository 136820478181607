import m from "mithril";
import SignaturePad from "signature_pad";
import i18n from "../../shared/i18n/i18n";

const canvasAspectRatio = 2.4;

const Component_Signature_Editor = {
    field: null, 
    id: "",
    signaturePad: null,
    canvas: null,
    context: null,
    onUpdate: null,
    readOnly: false,
    oninit: function (vnode) {
        this.field = vnode.attrs.field;
        this.id = vnode.attrs.id;
        this.onUpdate = vnode.attrs.updateCallback;
        this.readOnly = vnode.attrs.readOnly;
        window.addEventListener("resize", () => this.resizeCanvas());
    },
    oncreate: function () {
        this.canvas = document.getElementById("signature-canvas-" + this.id);
        this.context = this.canvas.getContext("2d");
        this.signaturePad = new SignaturePad(this.canvas);
        this.signaturePad.addEventListener("endStroke", () => this.setFieldFromSignaturePad())
        this.resizeCanvas();
        this.loadField();
    },
    loadField: function () {
        if (this.field.value) {
            this.signaturePad.fromDataURL(this.field.value, { width: this.canvas.width, height: this.canvas.height, xOffset: 0, yOffset: 0 });
        }
    },
    resizeCanvas: function () {
        const parentElement = document.getElementById('signature-elements-' + this.id);
        const computedStyle = window.getComputedStyle(parentElement);
        const width = parseInt(computedStyle.width, 10);
        this.canvas.width = width;
        this.canvas.height = width / canvasAspectRatio;
        const data = this.signaturePad.toData();
        this.signaturePad.clear(); // otherwise isEmpty() might return incorrect value. This is a note from https://github.com/szimek/signature_pad
        this.signaturePad.fromData(data);
    },
    onClear: function () {
        this.signaturePad.clear();
        this.setFieldValue(null);
    },
    setFieldFromSignaturePad: function () {
        this.setFieldValue(this.signaturePad.toDataURL());
    },
    setFieldValue: function (value) {
        this.field.value = value;
        this.updateCallback();
    },
    updateCallback: function () {
        if (this.onUpdate) {
            this.onUpdate(this.field);
        }
    },
    view: function () {
        return [
            m(".signature-editor", [
                m(".signature-elements", { id: 'signature-elements-' + this.id }, [
                    !this.readOnly ? m("button.clear-button", { onclick: () => this.onClear() }, i18n.t("clear")) : "",
                    m("canvas", { id: 'signature-canvas-' + this.id, class: 'signature-canvas' }),
                    m("hr", { class: 'signature-line' }),
                    m(".signature-hint", i18n.t("signature_placeholder"))
                ])
            ])
        ];
    }
};
export default Component_Signature_Editor;
