const SharedConstants = {
    dateFormats: {
        "af-ZA": "YYYY/MM/DD",
        "am-ET": "DD/MM/YYYY",
        "ar-AE": "DD/MM/YYYY",
        "ar-BH": "DD/MM/YYYY",
        "ar-DZ": "DD-MM-YYYY",
        "ar-EG": "DD/MM/YYYY",
        "ar-IQ": "DD/MM/YYYY",
        "ar-JO": "DD/MM/YYYY",
        "ar-KW": "DD/MM/YYYY",
        "ar-LB": "DD/MM/YYYY",
        "ar-LY": "DD/MM/YYYY",
        "ar-MA": "DD-MM-YYYY",
        "ar-OM": "DD/MM/YYYY",
        "ar-QA": "DD/MM/YYYY",
        "ar-SA": "DD/MM/YY",
        "ar-SY": "DD/MM/YYYY",
        "ar-TN": "DD-MM-YYYY",
        "ar-YE": "DD/MM/YYYY",
        "arn-CL": "DD-MM-YYYY",
        "as-IN": "DD-MM-YYYY",
        "az-Cyrl-AZ": "DD.MM.YYYY",
        "az-Latn-AZ": "DD.MM.YYYY",
        "ba-RU": "DD.MM.YY",
        "be-BY": "DD.MM.YYYY",
        "bg-BG": "DD.M.YYYY",
        "bn-BD": "DD-MM-YY",
        "bn-IN": "DD-MM-YY",
        "bo-CN": "YYYY/M/DD",
        "br-FR": "DD/MM/YYYY",
        "bs-Cyrl-BA": "DD.M.YYYY",
        "bs-Latn-BA": "DD.M.YYYY",
        "ca-ES": "DD/MM/YYYY",
        "co-FR": "DD/MM/YYYY",
        "cs-CZ": "DD.M.YYYY",
        "cy-GB": "DD/MM/YYYY",
        "da-DK": "DD/MM/YYYY",
        "de": "DD.MM.YYYY",
        "de-AT": "DD.MM.YYYY",
        "de-CH": "DD.MM.YYYY",
        "de-DE": "DD.MM.YYYY",
        "de-LI": "DD.MM.YYYY",
        "de-LU": "DD.MM.YYYY",
        "dsb-DE": "DD. M. YYYY",
        "dv-MV": "DD/MM/YY",
        "el-GR": "DD/MM/YYYY",
        "en-029": "MM/DD/YYYY",
        "en-AU": "DD/MM/YYYY",
        "en-BZ": "DD/MM/YYYY",
        "en-CA": "YYYY-MM-DD",
        "en-GB": "DD/MM/YYYY",
        "en-IE": "DD/MM/YYYY",
        "en-IN": "DD-MM-YYYY",
        "en-JM": "DD/MM/YYYY",
        "en-MY": "DD/MM/YYYY",
        "en-NZ": "DD/MM/YYYY",
        "en-PH": "M/DD/YYYY",
        "en-SG": "DD/MM/YYYY",
        "en-TT": "DD/MM/YYYY",
        "en-US": "MM/DD/YYYY",
        "en-ZA": "YYYY/MM/DD",
        "en-ZW": "M/DD/YYYY",
        "es-AR": "DD/MM/YYYY",
        "es-BO": "DD/MM/YYYY",
        "es-CL": "DD-MM-YYYY",
        "es-CO": "DD/MM/YYYY",
        "es-CR": "DD/MM/YYYY",
        "es-DO": "DD/MM/YYYY",
        "es-EC": "DD/MM/YYYY",
        "es-ES": "DD/MM/YYYY",
        "es-GT": "DD/MM/YYYY",
        "es-HN": "DD/MM/YYYY",
        "es-MX": "DD/MM/YYYY",
        "es-NI": "DD/MM/YYYY",
        "es-PA": "MM/DD/YYYY",
        "es-PE": "DD/MM/YYYY",
        "es-PR": "DD/MM/YYYY",
        "es-PY": "DD/MM/YYYY",
        "es-SV": "DD/MM/YYYY",
        "es-US": "M/DD/YYYY",
        "es-UY": "DD/MM/YYYY",
        "es-VE": "DD/MM/YYYY",
        "et-EE": "DD.MM.YYYY",
        "eu-ES": "YYYY/MM/DD",
        "fa-IR": "MM/DD/YYYY",
        "fi-FI": "DD.M.YYYY",
        "fil-PH": "M/DD/YYYY",
        "fo-FO": "DD-MM-YYYY",
        "fr-BE": "DD/MM/YYYY",
        "fr-CA": "DD/MM/YYYY",
        "fr-CH": "DD.MM.YYYY",
        "fr-FR": "DD/MM/YYYY",
        "fr-LU": "DD/MM/YYYY",
        "fr-MC": "DD/MM/YYYY",
        "fy-NL": "DD-M-YYYY",
        "ga-IE": "DD/MM/YYYY",
        "gd-GB": "DD/MM/YYYY",
        "gl-ES": "DD/MM/YY",
        "gsw-FR": "DD/MM/YYYY",
        "gu-IN": "DD-MM-YY",
        "ha-Latn-NG": "DD/MM/YYYY",
        "he-IL": "DD/MM/YYYY",
        "hi-IN": "DD-MM-YYYY",
        "hr-BA": "DD.M.YYYY.",
        "hr-HR": "DD.M.YYYY",
        "hsb-DE": "DD. M. YYYY",
        "hu-HU": "YYYY. MM. DD",
        "hy-AM": "DD.MM.YYYY",
        "id-ID": "DD/MM/YYYY",
        "ig-NG": "DD/MM/YYYY",
        "ii-CN": "YYYY/M/DD",
        "is-IS": "DD.M.YYYY",
        "it-CH": "DD.MM.YYYY",
        "it-IT": "DD/MM/YYYY",
        "iu-Cans-CA": "DD/MM/YYYY",
        "iu-Latn-CA": "DD/MM/YYYY",
        "ja-JP": "YYYY/MM/DD",
        "ka-GE": "DD.MM.YYYY",
        "kk-KZ": "DD.MM.YYYY",
        "kl-GL": "DD-MM-YYYY",
        "km-KH": "YYYY-MM-DD",
        "kn-IN": "DD-MM-YY",
        "ko-KR": "YYYY. MM. DD",
        "kok-IN": "DD-MM-YYYY",
        "ky-KG": "DD.MM.YY",
        "lb-LU": "DD/MM/YYYY",
        "lo-LA": "DD/MM/YYYY",
        "lt-LT": "YYYY.MM.DD",
        "lv-LV": "YYYY.MM.DD.",
        "mi-NZ": "DD/MM/YYYY",
        "mk-MK": "DD.MM.YYYY",
        "ml-IN": "DD-MM-YY",
        "mn-MN": "YY.MM.DD",
        "mn-Mong-CN": "YYYY/M/DD",
        "moh-CA": "M/DD/YYYY",
        "mr-IN": "DD-MM-YYYY",
        "ms-BN": "DD/MM/YYYY",
        "ms-MY": "DD/MM/YYYY",
        "mt-MT": "DD/MM/YYYY",
        "nb-NO": "DD.MM.YYYY",
        "ne-NP": "M/DD/YYYY",
        "nl-BE": "DD/MM/YYYY",
        "nl-NL": "DD-M-YYYY",
        "nn-NO": "DD.MM.YYYY",
        "nso-ZA": "YYYY/MM/DD",
        "oc-FR": "DD/MM/YYYY",
        "or-IN": "DD-MM-YY",
        "pa-IN": "DD-MM-YY",
        "pl-PL": "DD.MM.YYYY",
        "prs-AF": "DD/MM/YY",
        "ps-AF": "DD/MM/YY",
        "pt-BR": "DD/MM/YYYY",
        "pt-PT": "DD/MM/YYYY",
        "qut-GT": "DD/MM/YYYY",
        "quz-BO": "DD/MM/YYYY",
        "quz-EC": "DD/MM/YYYY",
        "quz-PE": "DD/MM/YYYY",
        "rm-CH": "DD/MM/YYYY",
        "ro-RO": "DD.MM.YYYY",
        "ru-RU": "DD.MM.YYYY",
        "rw-RW": "M/DD/YYYY",
        "sa-IN": "DD-MM-YYYY",
        "sah-RU": "MM.DD.YYYY",
        "se-FI": "DD.M.YYYY",
        "se-NO": "DD.MM.YYYY",
        "se-SE": "YYYY-MM-DD",
        "si-LK": "YYYY-MM-DD",
        "sk-SK": "DD. M. YYYY",
        "sl-SI": "DD.M.YYYY",
        "sma-NO": "DD.MM.YYYY",
        "sma-SE": "YYYY-MM-DD",
        "smj-NO": "DD.MM.YYYY",
        "smj-SE": "YYYY-MM-DD",
        "smn-FI": "DD.M.YYYY",
        "sms-FI": "DD.M.YYYY",
        "sq-AL": "YYYY-MM-DD",
        "sr-Cyrl-BA": "DD.M.YYYY",
        "sr-Cyrl-CS": "DD.M.YYYY",
        "sr-Cyrl-ME": "DD.M.YYYY",
        "sr-Cyrl-RS": "DD.M.YYYY",
        "sr-Latn-BA": "DD.M.YYYY",
        "sr-Latn-CS": "DD.M.YYYY",
        "sr-Latn-ME": "DD.M.YYYY",
        "sr-Latn-RS": "DD.M.YYYY",
        "sv-FI": "DD.M.YYYY",
        "sv-SE": "YYYY-MM-DD",
        "sw-KE": "M/DD/YYYY",
        "syr-SY": "DD/MM/YYYY",
        "ta-IN": "DD-MM-YYYY",
        "te-IN": "DD-MM-YY",
        "tg-Cyrl-TJ": "DD.MM.YY",
        "th-TH": "DD/MM/YYYY",
        "tk-TM": "DD.MM.YY",
        "tn-ZA": "YYYY/MM/DD",
        "tr-TR": "DD.MM.YYYY",
        "tt-RU": "DD.MM.YYYY",
        "tzm-Latn-DZ": "DD-MM-YYYY",
        "ug-CN": "YYYY-M-DD",
        "uk-UA": "DD.MM.YYYY",
        "ur-PK": "DD/MM/YYYY",
        "uz-Cyrl-UZ": "DD.MM.YYYY",
        "uz-Latn-UZ": "DD/MM YYYY",
        "vi-VN": "DD/MM/YYYY",
        "wo-SN": "DD/MM/YYYY",
        "xh-ZA": "YYYY/MM/DD",
        "yo-NG": "DD/MM/YYYY",
        "zh-CN": "YYYY/M/DD",
        "zh-HK": "DD/MM/YYYY",
        "zh-MO": "DD/MM/YYYY",
        "zh-SG": "DD/MM/YYYY",
        "zh-TW": "YYYY/M/DD",
        "zu-ZA": "YYYY/MM/DD",
    },
    mobileAndTabletDevicesRegex: /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i,
    mobileAndTabletCodesRegex: /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i,
    eusa: 'https://www.avetta.com/legal/end-user-service-agreement',
    privacyPolicy: 'https://www.avetta.com/legal/privacy-policy',
    noticeAtCollection: 'https://www.avetta.com/legal/notice-at-collection',
    transferOfMyPersonalData: 'https://avetta.webflow.io/legal/privacy-policy#disclosures',
    sensitiveData: 'https://www.avetta.com/legal/end-user-service-agreement#sensitive-data',
    activeRequiredConsents: ["eusa", "privacyPolicy", "noticeAtCollection", "transferOfMyPersonalData", "sensitiveData" ]
}

export default SharedConstants;
