import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import i18n from "../../shared/i18n/i18n";

let loading, unitEnrolment, activity;

function loadUnit() {
    loading = true;
    Requester.get(Gateway.admin + "units/enrolments/" + unitEnrolment.id)
        .then(function (result) {
            if (result && result.properties.data) {
                const json = JSON.parse(result.properties.data);
                if (json.interactions.childArray)
                    activity = Object.values(json.interactions.childArray);
                else
                    activity = Object.values(json.interactions);
            }
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        loading = false;
    });
}

function formatValue(text) {
    return text.replaceAll("urn:scormdriver:", "").replaceAll(/_|%20/g, " ").split("[,]").join(", ");
}

const Modal_Scorm_Activity = {
    oninit: function (vnode) {
        unitEnrolment = vnode.attrs.unitEnrolment;
        activity = [];
        loadUnit();
    },
    view: function () {
        return [
            !loading ?
                activity && activity.length > 0 ? [
                    m(".table-wrapper", [
                        m("table.full-width fixed-table ellipsis activity-table no-hover", [
                            m("thead",
                                m("tr", [
                                    m("th", i18n.t("question")),
                                    m("th", i18n.t("correct_response")),
                                    m("th", i18n.t("learner_response")), undefined,
                                    m("th", {style: "width: 10%;"}, i18n.t("time")),
                                    m("th", {style: "width: 10%;"}, i18n.t("result"))
                                ])
                            ),
                            m("tbody", activity.map(function (inter) {
                                const correctResponses = inter.correct_responses.childArray || Object.values(inter.correct_responses);
                                return [
                                    m("tr.break-word", [
                                        m("td", inter.description ? formatValue(inter.description) : inter.id),
                                        m("td", correctResponses.map(function (res) {
                                            return [
                                                res.pattern ? formatValue(res.pattern) : ""
                                            ]
                                        })),
                                        m("td", inter.learner_response ? formatValue(inter.learner_response) : inter.student_response ? formatValue(inter.student_response) : ""),
                                        m("td", [+inter.latency.replace(/[^0-9.]+/g, ""), "s"]),
                                        m("td", inter.result)
                                    ])
                                ];
                            }))
                        ])
                    ])
                ] : m(".text-gray padding", i18n.t("no_activity"))
                : m(".text-gray padding", [i18n.t("loading"), m(".loading-dots")])
        ];
    }
};

export default Modal_Scorm_Activity;
