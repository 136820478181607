import { UnitTypes } from '../../admin/values';
import Auth from '../auth';

let UnitService = {
    getEditUnitRight: function(unit) {
        switch (unit.properties.type) {
            case UnitTypes.scorm:
                return Auth.rights.unit_scorm_edit;
            case UnitTypes.form:
                return Auth.rights.unit_form_edit;
            case UnitTypes.event:
                return Auth.rights.unit_event_edit;
            case UnitTypes.doc:
                return Auth.rights.unit_document_edit;
            case UnitTypes.cert:
                return Auth.rights.unit_certificate_edit;
            case UnitTypes.action:
                return Auth.rights.unit_action_edit;
            case UnitTypes.approval:
                return Auth.rights.unit_approval_edit;
            case UnitTypes.download:
                return Auth.rights.unit_download_edit;
            case UnitTypes.upload:
                return Auth.rights.unit_upload_edit;
        }
    }
}

export default UnitService;