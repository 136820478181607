import SessionController from "../../shared/session";
import m from "mithril";

let loading = false;

const Page_Logout = {
    oninit: function () {
        SessionController.logout();
    },
    view: function () {
        return m(".text", i18n.t("you_have_been_logged_out"))
    }
}

export default Page_Logout;
