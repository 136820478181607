import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import i18n from "../../shared/i18n/i18n";
import ModalManager from "../../shared/modal";
import PublicModal_Certificate_Preview from "./modal_certificate_preview";

let loading = false;
let filter = null;
let search = null;
let certs = null;

function loadCertificates() {
    loading = true;
    certs = null;
    Requester.post(Gateway.public + "enrolments/certificates")
        .then(function (result) {
            certs = result;
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        loading = false;
    });
}

function getCertificate(cert) {
    ModalManager.open(i18n.t("certificate_preview"), ["modal-big"], PublicModal_Certificate_Preview, {url: Gateway.public + "units/certificate/" + cert.id + "?enrolmentId=" + cert.courseEnrolmentId});
}

function certStyle(c) {
    let s = document.createElement("style");
    if (c.isPortrait)
        s.innerHTML = "body { margin: 0; } * { line-height: 1; } #cert-wrapper { top: 0; left: 0; bottom: 0; right: 0; } #cert-content { transform: scale(0.188); position: absolute; transform-origin: top left; width: 210mm; height: 297mm; margin: 1px; } }";
    else
        s.innerHTML = "body { margin: 0; } * { line-height: 1; } #cert-wrapper { top: 0; left: 0; bottom: 0; right: 0; } #cert-content { transform: scale(0.188); position: absolute; transform-origin: top left; width: 297mm; height: 210mm; margin: 1px; } }";
    return s;
}

function sortCertsPortraitFirst(certList) {
    let sortedCerts = [...certList.filter(c => c.isPortrait), ...certList.filter(c => !c.isPortrait)];
    return sortedCerts;
}

const PublicComponent_Certificates = {
    oninit: function () {
        loadCertificates();
    },
    view: function () {
        return [
            !loading ? [
                certs ? [
                    certs.length > 0 ?
                    sortCertsPortraitFirst(certs).map(function (cert) {
                            return [
                                m(".cert-icon", {
                                    onclick: function () {
                                        getCertificate(cert);
                                    }
                                }, [
                                    m(".cert-icon-image", {class: cert.isPortrait ? "portrait" : "landscape"}, m("iframe", {
                                        srcdoc: cert.html,
                                        onload: function () {
                                            this.contentDocument.head.appendChild(certStyle(cert));
                                        }
                                    })),
                                    m(".cert-icon-info", [
                                        m("div", cert.courseName),
                                        m("div", cert.completionDate)
                                    ])
                                ])
                            ];
                        })
                        : m(".text-gray padding", i18n.t("no_certificates"))
                ] : m(".text-gray padding", i18n.t("unable_to_load_certificates"))
            ] : m(".text-gray padding", [i18n.t("loading"), m(".loading-dots")])
        ];
    }
};
export default PublicComponent_Certificates;
