import m from "mithril";
import Toasts from "../../shared/toasts";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import ModalManager from "../../shared/modal";
import i18n from "../../shared/i18n/i18n";

let userId, callback, loading, saving, courses, modalId;

function loadCourses() {
    loading = true;

    Requester.get(Gateway.admin + "courses/assignable?userId=" + userId)
        .then(function (result) {
            courses = result.properties.courses;
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        loading = false;
    });
}

function assignCourses() {
    saving = true;
    let selectedCourses = [];
    let sel = document.getElementById("user-assign-course-select");
    for (let i = 0; i < sel.options.length; i++) {
        if (sel.options[i].selected)
            selectedCourses.push(sel.options[i].value);
    }

    if (selectedCourses.length === 0) {
        Toasts.add("error", i18n.t("cant_save"), i18n.t("select_courses"));
        saving = false;
    } else {
        Requester.post(Gateway.admin + "courses/enrolments", {courseIds: selectedCourses, userId: userId})
            .then(function (response) {
                callback();
                ModalManager.close(modalId);
                if (response.properties.errors && response.properties.errors.length > 0) {
                    let errs = [];
                    for (let i = 0; i < response.properties.errors.length; i++)
                        errs.push(response.properties.errors[i].message);
                    Toasts.add("warn", i18n.t("successful_but_with_some_errors"), errs.join("\n"), true);
                } else
                    Toasts.add("success", i18n.t("saved_changes"), "");
            }).catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        }).finally(function () {
            saving = false;
        });
    }
}

const Modal_User_Assign_Courses = {
    oninit: function (vnode) {
        userId = vnode.attrs.userId;
        callback = vnode.attrs.callback;
        loadCourses();
    },
    view: function () {
        return [
            m(".form-section required", [
                !loading ? [
                    courses ? [
                        m(".form-label", i18n.t("select_courses")),
                        m("select[multiple]#user-assign-course-select",
                            courses.map(function (course) {
                                return [
                                    m("option", {value: course.id}, course.name)
                                ];
                            }))
                    ] : i18n.t("no_courses")
                ] : [i18n.t("loading"), m(".loading-dots")]
            ]),
            m(".form-buttons", [
                !saving ? m("button", {onclick: assignCourses}, i18n.t("save")) : m("button.btn-text no-click", [i18n.t("saving"), m(".loading-dots")])
            ])
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
};
export default Modal_User_Assign_Courses;
