import m from "mithril";
import FormUtilities from "../../shared/utilities/form_utilities";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import SessionController from "../../shared/session";
import Toasts from "../../shared/toasts";
import i18n from "../../shared/i18n/i18n";
import Loading from "../../shared/loading";
import RouterService from "../../shared/services/router.service";

let loading = false;
let saving = false;
let values = {};

function submit() {
    if (FormUtilities.validateFields("input")) {
        saving = true;
        Requester.post(Gateway.identity + "invitation", values)
            .then(function (result) {
                SessionController.setSession(result).then(r => {
                    RouterService.navigateToAccount();
                });
            })
            .catch(function (error) {
                Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
            })
            .finally(function () {
                saving = false;
            });
    }
}

const Page_Invitation_Accept = {
    oninit: function () {
        values = {
            firstName: "",
            lastName: "",
            password: "",
            confirmPassword: "",
            userId: m.route.param("user"),
            token: m.route.param("token")
        };
        if (SessionController.session && SessionController.session.identity) {
            RouterService.navigateToAccount();
            Toasts.add("success", i18n.t("already_logged_in"), "");
        }
    },
    view: function () {
        return [
            m("div", [
                !loading ? [
                    m(".form-header", i18n.t("signup")),
                    m("form", {
                        onsubmit: function (e) {
                            e.preventDefault();
                            submit();
                        }
                    }, [
                        m(".form-section required", [
                            m(".form-label", i18n.t("first_name")),
                            m("input[type=text][autocomplete=off]", {
                                oninput: function () {
                                    values.firstName = this.value;
                                }, value: values.firstName
                            })
                        ]),
                        m(".form-section required", [
                            m(".form-label", i18n.t("last_name")),
                            m("input[type=text][autocomplete=off]", {
                                oninput: function () {
                                    values.lastName = this.value;
                                }, value: values.lastName
                            })
                        ]),
                        m(".form-section required", [
                            m(".form-label", i18n.t("password")),
                            m("input[type=password]", {
                                oninput: function () {
                                    values.password = this.value;
                                }, value: values.password
                            })
                        ]),
                        m(".form-section required", [
                            m(".form-label", i18n.t("confirm_password")),
                            m("input[type=password]", {
                                oninput: function () {
                                    values.confirmPassword = this.value;
                                }, value: values.confirmPassword
                            })
                        ]),
                        m(".form-buttons", [
                            !saving ? m("button", {"type": "submit"}, i18n.t("submit")) : m("button.btn-text no-click", [i18n.t("submit"), m(".loading-dots")])
                        ]),
                        m(".form-section mt-5", [
                            i18n.t("agreement"),
                            m(".form-buttons flex-left mt-1", [
                                m("a", {
                                    href: SessionController.session.environment.homepageUrl + "/terms",
                                    target: "_blank"
                                }, i18n.t("terms_of_use")),
                                m("a.ml-2", {
                                    href: SessionController.session.environment.homepageUrl + "/privacy",
                                    target: "_blank"
                                }, i18n.t("privacy_policy"))
                            ])
                        ])
                    ])
                ] : m(Loading)
            ])
        ];
    }
};
export default Page_Invitation_Accept;
