import m from "mithril";
import i18n from "../../../shared/i18n/i18n";
import Auth from "../../../shared/auth";
import FormUtilities from "../../../shared/utilities/form_utilities";
import FormElementsMenu from "./FormElementsMenu";


let sectionCopy = null;
let optionsForDeps = [];

function revertToolbox() {
    m.mount(document.querySelector(".form-toolbox"), FormElementsMenu);
}

function saveUndoHistory() {
    if (undoHistory.length >= 1)
        undoHistory.splice(0, 1);

    undoHistory.push(JSON.parse(JSON.stringify(unit)));
}

function saveSection() {
    if (FormUtilities.validateFields(".section-edit input")) {
        for (let i = 0; i < unit.sections.length; i++) {
            if (unit.sections[i].id === sectionCopy.id)
                unit.sections.splice(i, 1, JSON.parse(JSON.stringify(sectionCopy)));
        }
        revertToolbox();
    }
}

function removeSection() {
    for (let i = 0; i < unit.sections.length; i++) {
        if (unit.sections[i].id === sectionCopy.id) {
            saveUndoHistory();
            unit.sections.splice(i, 1);
        }
    }
    revertToolbox();
}

function checkSectionOps(opId) {
    for (let i = 0; i < sectionCopy.prerequisiteOptionIds.length; i++)
        if (sectionCopy.prerequisiteOptionIds[i] === opId)
            return true;

    return false;
}

function updateSectionOptionDeps(options) {
    sectionCopy.prerequisiteOptionIds = [];
    for (let i = 0; i < options.length; i++)
        if (options[i].selected)
            sectionCopy.prerequisiteOptionIds.push(options[i].value);
}

function getSectionOptions(sectionId) {
    let options = [];
    for (let i = 0; i < unit.sections.length; i++) {
        if (unit.sections[i].id === sectionId)
            return options;

        for (let v = 0; v < unit.sections[i].fields.length; v++) {
            if (unit.sections[i].fields[v].type === "checkbox" || unit.sections[i].fields[v].type === "radio")
                for (let x = 0; x < unit.sections[i].fields[v].options.length; x++)
                    options.push(unit.sections[i].fields[v].options[x]);
        }
    }

    return options;
}


let unit;
let undoHistory;
const EditSectionToolbox = {
    oninit: function (vnode) {
        unit = vnode.attrs.unit;
        undoHistory = vnode.attrs.undoHistory;
        sectionCopy = JSON.parse(JSON.stringify(vnode.attrs.sec));
        optionsForDeps = getSectionOptions(sectionCopy.id);
    },
    view: function () {
        return [
            m(".form-toolbox", [
                m("h4.mb-2", i18n.t("edit_section")),
                sectionCopy ? [
                    m(".form full-width section-edit", [
                        m(".form-section required", [
                            m(".form-label", i18n.t("name")),
                            m("input[type=text]", {
                                oninput: function () {
                                    sectionCopy.name = this.value;
                                }, value: sectionCopy.name
                            })
                        ]),
                        m(".form-section", [
                            m(".form-label", i18n.t("description")),
                            m("textarea", {
                                oninput: function () {
                                    sectionCopy.information = this.value;
                                }, value: sectionCopy.information
                            })
                        ]),
                        optionsForDeps.length > 0 ? [
                            m(".form-section", [
                                m(".form-label", i18n.t("dependencies")),
                                m("select[multiple].section-option-deps-select", {
                                    onchange: function () {
                                        updateSectionOptionDeps(this.options);
                                    }
                                }, optionsForDeps.map(function (op) {
                                    return [
                                        m("option", {
                                            value: op.id, oncreate: function (e) {
                                                if (checkSectionOps(op.id)) {
                                                    e.dom.setAttribute("selected", "selected");
                                                }
                                            }
                                        }, op.value)
                                    ];
                                }))
                            ])
                        ] : "",
                        m(".form-buttons", [
                            Auth.validateRights(["unit_form_edit"], [
                                m("button.btn-error flex-float-left", {onclick: removeSection}, i18n.t("delete_section")),
                                m("button", {onclick: saveSection}, i18n.t("save_section"))
                            ]),
                            m("button.ml-1", {onclick: revertToolbox}, i18n.t("cancel"))
                        ])
                    ])
                ] : m("button.ml-1", {onclick: revertToolbox}, i18n.t("cancel"))
            ])
        ];
    }
};
export default EditSectionToolbox;
