import m from "mithril";
import i18n from "../i18n/i18n";
import SharedConstants from "../shared_constants";
import Requester from "../request";
import Gateway from "../app_settings";
import Toasts from "../toasts";
import SessionController from "../session";
import ModalManager from "../modal";
import TextUtilities from '../utilities/text_utilities';

let saving, modalId, hasAgreedSensitiveData, hasAgreedThirdPartyDataProcessors, hasAgreedEndUserServiceAgreement, hasAgreedPPandNoticeAtColl;
function submit() {
    saving = true;
    var policyTypes = { PolicyTypes:SharedConstants.activeRequiredConsents };
    Requester.post(Gateway.identity + "accept", policyTypes)
        .then(function () {
            Toasts.add("success", i18n.t("success"), "");
            SessionController.session.identity.consentRequired = false;
            SessionController.setCurrentSession();
            ModalManager.close(modalId);
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
            saving = false;
        });
}
function getSubmitButtonClass() {
    return saving || !hasAgreedSensitiveData || !hasAgreedThirdPartyDataProcessors || !hasAgreedEndUserServiceAgreement || !hasAgreedPPandNoticeAtColl ? "disabled" : "";
}

const Modal_Consent_Form = {
    view: function () {
        return [
            m("h4", i18n.t("terms_and_privacy_sub_heading")),
            m("form", {
                onsubmit: function (e) {
                    e.preventDefault();
                    submit();
                }
            }, [
                m(".form-section checkbox-section", [
                    m("p.perm-checkbox", {
                            onclick: function () {
                                hasAgreedEndUserServiceAgreement = !hasAgreedEndUserServiceAgreement;
                            }
                        }, [
                            !!hasAgreedEndUserServiceAgreement ? m("i.icon-checkbox-checked") : m("i.icon-checkbox-empty text-gray"), " ",
                            m.trust(TextUtilities.createTextWithLinks('end_user_service_agreement_text', [{
                                linkTextKey: 'end_user_service_agreement',
                                linkUrl: SharedConstants.eusa
                            }])),
                        ]
                    ),
                ]),
                m(".form-section checkbox-section", [
                    m("p.perm-checkbox", {
                            onclick: function () {
                                hasAgreedPPandNoticeAtColl = !hasAgreedPPandNoticeAtColl;
                            }
                        }, [
                            !!hasAgreedPPandNoticeAtColl ? m("i.icon-checkbox-checked") : m("i.icon-checkbox-empty text-gray"), " ",
                            m("text",
                                [
                                    i18n.t("privacy_policy_text_prefix") + " ",
                                    m("a.hyperlink", { href: SharedConstants.privacyPolicy, target: "_blank"}, i18n.t("privacy_policy")),
                                    " " + i18n.t( "privacy_policy_text_postfix") + " ",
                                    m("a.hyperlink", { href: SharedConstants.noticeAtCollection, target: "_blank"}, i18n.t("notice_at_collection")),
                                    " " + i18n.t("notice_at_collection_text_postfix")
                                ]),
                        ],
                    ),
                ]),
                m(".form-section checkbox-section", [
                    m("p.perm-checkbox", {
                            onclick: function () {
                                hasAgreedThirdPartyDataProcessors = !hasAgreedThirdPartyDataProcessors;
                            }
                        }, [
                            !!hasAgreedThirdPartyDataProcessors ? m("i.icon-checkbox-checked") : m("i.icon-checkbox-empty text-gray"), " ",
                            m.trust(TextUtilities.createTextWithLinks('third_party_data_processors_text', [{
                                    linkTextKey: 'third_party_data_processors',
                                    linkUrl: SharedConstants.transferOfMyPersonalData
                                }]
                            ))
                        ]
                    )
                ]),
                m(".form-section checkbox-section", [
                    m("p.perm-checkbox", {
                            onclick: function () {
                                hasAgreedSensitiveData = !hasAgreedSensitiveData;
                            }
                        }, [
                            !!hasAgreedSensitiveData ? m("i.icon-checkbox-checked") : m("i.icon-checkbox-empty text-gray"), " ",
                            m.trust(TextUtilities.createTextWithLinks('sensitive_data_text', [{
                                linkTextKey: 'sensitive_data',
                                linkUrl: SharedConstants.sensitiveData
                            }]))
                        ]
                    ),
                    m("button.btn.fill-width.full-btn.primary-btn.centre-content", {
                        "type": "submit",
                        class: getSubmitButtonClass()
                    }, i18n.t("continue"))
                ]),
            ])
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
};
export default Modal_Consent_Form;
