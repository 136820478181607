import i18n from "../../shared/i18n/i18n";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import RouterService from "../../shared/services/router.service";
import Component_Table_Footer from "../../shared/components/component_table_footer";
import DateUtilities from "../../shared/utilities/date_utilities";
import Auth from "../../shared/auth";

let keys, loading, selectedItems;

function load() {
    loading = true;
    keys = null;
    selectedItems = [];
    
    Requester.get(Gateway.admin + "account/keys")
        .then(function (result) {
            keys = result;
        }).catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        }).finally(function () {
            loading = false;
        });
}

function isExpired(key) {
    return key.status === 2;
}

function isExpiring(key) {
    return key.status === 1;
}

const Page_ApiKeys = {
    oninit: function (vnode) {
        load();
    },
    view: function () {
        return [
            m(".content-box", [
                !loading ? [
                    keys ? [
                        keys.length > 0 ? [
                            m(".table-wrapper", [
                                m("table.full-width fixed-table ellipsis", [
                                    m("thead",
                                        m("tr", [
                                            m("th", { style: "width: 18%;" }, m("div", { class: "clickable flex-row justify-between" }, [m("span", i18n.t("organisation"))])),
                                            m("th", { style: "width: 15%;" }, m("div", { class: "clickable flex-row justify-between" }, [m("span", i18n.t("expiry"))])),
                                            m("th", { style: "width: 15%;" }, m("div", { class: "clickable flex-row justify-between" }, [m("span", i18n.t("status"))])),
                                            m("th", { style: "width: 12%;" }, m("div", { class: "clickable flex-row justify-between" }))
                                        ])
                                    ),
                                    m("tbody", keys.map(function (key) {
                                        return m("tr", { style: key.status === 2 ? "background-color: red" : key.status === 1 ? "background-color: darkorange" : "" }, [
                                            Auth.validateRights(["org_view"], m("td.text-bolder", { onclick: function () { RouterService.navigateToOrganisation(key.organisationId); }}, key.organisation)),
                                            Auth.validateRights(["org_view"], m("td", { onclick: function () { RouterService.navigateToOrganisation(key.organisationId); }}, key.expiry ? DateUtilities.getFormattedDateFromUTC(key.expiry) : m(".loading-dots"))),
                                            Auth.validateRights(["org_view"], m("td", { onclick: function () { RouterService.navigateToOrganisation(key.organisationId); }}, i18n.t(key.statusName)))
                                        ])
                                    }))
                                ])
                            ]),
                            m(Component_Table_Footer, {
                                loading: loading,
                                item: "keys"
                            })
                        ] : m(".text-gray padding", i18n.t("no_keys"))
                    ] : m(".text-gray padding", i18n.t("unable_to_load_keys"))
                ] : m(".text-gray padding", [i18n.t("loading"), m(".loading-dots")])
            ])
        ];
    }
};

export default Page_ApiKeys;
