import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import i18n from "../../shared/i18n/i18n";
import SessionController from "../../shared/session";
import Loading from "../../shared/loading";
import RouterService from "../../shared/services/router.service";
let loading = true;

function verifyTokenAndRedirectToCourse(ticket, courseId) {
    Requester.post(Gateway.identity + "login/cas/validate",
        {
            "ticket": ticket
        },
        {"Content-Type": "application/json"})
        .then(function (result) {
            SessionController.setSession(result);
            localStorage.setItem("sso-logout", result.identity.ssoLogout);
            RouterService.navigateToPublicCourse(courseId);
        })
        .catch(function (error) {
            loading = false;
            Toasts.error(JSON.stringify(error.response));
        });
}


const Redirect_MobileAuth = {
    oninit: function (vnode) {
        if (SessionController.session && SessionController.session.identity) {
            RouterService.navigateOnLogin();
            Toasts.add("success", i18n.t("already_logged_in"), "");
        }

        if (m.route.param("ticket")) {
            verifyTokenAndRedirectToCourse(m.route.param("ticket"), m.route.param("courseId"));
        } else {
            RouterService.navigateToPublicLogin();
            Toasts.add("error", i18n.t("cant_verify"), i18n.t("course_id_and_ticket_must_be_provided"));
        }

    },
    view: function () {
        return [
            m(".relative", [
                loading ? m(Loading, {class: "loading-inline"})
                    : [
                        m("h3.mb-2", i18n.t("unable_to_log_in")),
                        m(".mb-1", i18n.t("please_try_again_or_contact_support")),
                        SessionController.session && SessionController.session.environment && !SessionController.session.environment.ssoOnly ? [
                            m(m.route.Link, {href: RouterService.getPublicLoginUrl(), class: "btn-text"}, i18n.t("back_to_log_in"))
                        ] : ""
                    ]
            ])
        ];
    }
};
export default Redirect_MobileAuth;
