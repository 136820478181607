import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import i18n from "../../shared/i18n/i18n";
import I18n from "../../shared/i18n/i18n";


let loading = false;
let filter = null;
let search = null;
let enrolments = null;

function loadEnrolments() {
    loading = true;
    enrolments = null;

    let params = [];
    if (filter)
        params.push("filter=" + filter);
    if (search)
        params.push("search=" + search);

    Requester.get(Gateway.public + "enrolments" + (params.length > 0 ? "?" + params.join("&") : ""))
        .then(function (result) {
            enrolments = result;
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        loading = false;
    });
}

const PublicComponent_Enrolments = {
    oninit: function (vnode) {
        loadEnrolments();
    },
    view: function () {
        return [
            m(".toolbar", {class: loading ? "disable-buttons" : ""}, [
                m(".toolbar-section"),
                m(".toolbar-section", [
                    m(".dropdown",
                        m("button.with-icon pl-2", [
                            filter === "passed" ? i18n.t("passed") : filter === "incomplete" ? i18n.t("incomplete") : filter === "not_passed" ? i18n.t("failed") : i18n.t("all_enrolments"),
                            m("i.icon-back rotate-270 ml-2")
                        ]),
                        m(".dropdown-list", [
                            m("div", {
                                class: !filter ? "active" : "", onclick: function () {
                                    filter = null;
                                    loadEnrolments();
                                }
                            }, i18n.t("all_enrolments")),
                            m(".label-circle label-success", {
                                class: filter === "passed" ? "active" : "",
                                onclick: function () {
                                    filter = "passed";
                                    loadEnrolments();
                                }
                            }, i18n.t("passed")),
                            m(".label-circle label-warning", {
                                class: filter === "incomplete" ? "active" : "",
                                onclick: function () {
                                    filter = "incomplete";
                                    loadEnrolments();
                                }
                            }, i18n.t("incomplete")),
                            m(".label-circle label-error", {
                                class: filter === "not_passed" ? "active" : "",
                                onclick: function () {
                                    filter = "not_passed";
                                    loadEnrolments();
                                }
                            }, i18n.t("failed"))
                        ])
                    ),
                    m(".has-icon-right", [
                        m("input[type=search]", {
                            oninput: function () {
                                search = this.value;
                            }, onkeyup: function (e) {
                                if (e.keyCode === 13) {
                                    loadEnrolments();
                                }
                            }, value: search, placeholder: I18n.t("search")
                        }),
                        m("i.icon-x c-hand", {
                            onclick: function () {
                                search = null;
                                loadEnrolments();
                            }
                        }),
                        m("i.icon-search c-hand", {
                            onclick: function () {
                                loadEnrolments();
                            }
                        })
                    ])
                ])
            ]),
            !loading ? [
                enrolments ? [
                    enrolments.length > 0 ? [
						m(".table-wrapper table-responsive", [
							m("table.full-width", [
								m("thead",
									m("tr", [
										m("th.pl-2", i18n.t("course")),
										m("th.pl-1", i18n.t("organisation")),
										m("th.pl-1", i18n.t("result"))
									])
								),
								m("tbody", enrolments.map(function (course) {
									return [
										m("tr", [
											m("td.pl-2 text-bolder", course.name),
											m("td.pl-1", course.organisationName),
											m("td.pl-1", course.enrolment.isCompleted && course.enrolment.isPassed ? m(".label-circle label-success", i18n.t("passed")) : course.enrolment.isCompleted && !course.enrolment.isPassed ? m(".label-circle label-error", i18n.t("failed")) : course.enrolment.isStarted ? m(".label-circle label-warning", i18n.t("in_progress")) : m(".label-circle", i18n.t("not_started")))
										])
									];
								}))
							])
						])
                    ] : m(".text-gray padding", i18n.t("no_enrolments"))
                ] : m(".text-gray padding", i18n.t("unable_load_enrolments"))
            ] : m(".text-gray padding", [i18n.t("loading"), m(".loading-dots")])
        ];
    }
};
export default PublicComponent_Enrolments;
