import i18n from "./i18n/i18n";

let Toasts = {
    error: function (error) {
        console.log('ERROR', error);
        if (error && error.properties) {
            let title = (error.properties.message || i18n.t("error")) + (error.properties.errorCode ? " (" + error.properties.errorCode + ")" : "");
            let body = error.properties.detail || ""
            if (error.properties.entities) {
                if (body)
                    body += "\n";
                let errorEntities = [];
                let index = 0;
                for (let k in error.properties.entities) {
                    errorEntities.push(index !== k ? (k + ": " + error.properties.entities[k]) : error.properties.entities[k]);
                    index++;
                }
                body += errorEntities.join("\n");
            }
            Toasts.add("error", title, body, true);
        }
        else {
            Toasts.add("error", i18n.t("error"), error ? error : i18n.t("reload_and_try_again"));
        }
    },

    add: function (type, title, message, persist) {
        let toaster = document.getElementById("toaster");
        if (toaster) {
            let toast = document.createElement("div");
            let timeStamp = "toast-" + new Date().getTime();
            toast.classList.add("toast");
            toast.id = timeStamp;

            if (type)
                toast.classList.add("toast-" + type);

            let content = document.createElement("div");
            content.classList.add("mr-1");

            if (title) {
                let titleElem = document.createElement("b");
                titleElem.innerHTML = title;
                content.appendChild(titleElem);
            }

            if (message) {
                let messageElem = document.createElement("div");
                messageElem.classList.add("toast-message");
                messageElem.style.whiteSpace = "pre";
                messageElem.innerHTML = message;
                content.appendChild(messageElem);
            }

            toast.appendChild(content);

            let closeButton = document.createElement("i");
            closeButton.classList.add("icon-x", "c-hand");
            closeButton.addEventListener("click", function () {
                let t = document.getElementById(timeStamp);
                toaster.removeChild(t);
            });
            toast.appendChild(closeButton);

            if (!persist) {
                setTimeout(function () {
                    let t = document.getElementById(timeStamp);
                    if (t)
                        toaster.removeChild(t);
                }, 5000);
            }

            toaster.appendChild(toast);
        }
    }
};

export default Toasts;
