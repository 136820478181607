import m from "mithril";
import ModalManager from '../modal';

let modalId, confirmCallback, cancelCallback;

function close() {
    ModalManager.close(modalId);
}

function confirmOnClick() {
    if (confirmCallback) {
        confirmCallback();
    }
    close();
}

function cancelOnClick() {
    if (cancelCallback) {
        cancelCallback();
    }
    close();
}

const Modal_Confirm = {
    oninit: function (vnode) {
        confirmCallback = vnode.attrs.confirmCallback;
        cancelCallback = vnode.attrs.cancelCallback;
    },
    view: function (vnode) {
        return [
            m(".form-section", [
                vnode.attrs.messageText
            ]),
            m(".form-buttons", [
                m(vnode.attrs.cancelButtonClasses ? vnode.attrs.cancelButtonClasses : "button", {onclick: cancelOnClick }, vnode.attrs.cancelButtonLabel),
                m(vnode.attrs.confirmButtonClasses ? vnode.attrs.confirmButtonClasses : "button.btn-error", { onclick: confirmOnClick }, vnode.attrs.confirmButtonLabel)
            ])
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
};

export default Modal_Confirm;
