import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import i18n from "../../shared/i18n/i18n";
import Toasts from "../../shared/toasts";
import ModalManager from "../../shared/modal";
import Modal_Confirm_Delete from "../../shared/components/modal_confirm_delete";
import Auth from "../../shared/auth";
import RouterService from "../../shared/services/router.service";
let saving = false;
let deleting = false;
let course = null;

function toggleIsActive() {
    Requester.post(Gateway.admin + "courses/" + (course.isActive ? "deactivate" : "activate"), [course.id])
        .then(function () {
            course.isActive = !course.isActive;
            Toasts.add("success", i18n.t("saved_changes"), "");
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        });
}

function toggleHideIfNotEnrolled() {
    Requester.post(Gateway.admin + "courses/" + course.id + "/toggle-visibility")
        .then(function () {
            course.hideIfNotEnrolled = !course.hideIfNotEnrolled;
            Toasts.add("success", i18n.t("saved_changes"), "");
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        });
}

function toggleAllowSelfEnrolment() {
    Requester.post(Gateway.admin + "courses/" + course.id + "/toggle-self-enrolment")
        .then(function () {
            course.allowSelfEnrolment = !course.allowSelfEnrolment;
            Toasts.add("success", i18n.t("saved_changes"), "");
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        });
}

function toggleAllowReEnrolment() {
    Requester.post(Gateway.admin + "courses/" + course.id + "/toggle-re-enrolment")
        .then(function () {
            course.allowReEnrolment = !course.allowReEnrolment;
            Toasts.add("success", i18n.t("saved_changes"), "");
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        });
}

function toggleAllowGuests() {
    Requester.post(Gateway.admin + "courses/" + course.id + "/toggle-guest-enrolment")
        .then(function () {
            course.allowGuestEnrolment = !course.allowGuestEnrolment;
            Toasts.add("success", i18n.t("saved_changes"), "");
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        });
}

function toggleAllowNonSequentialAccess() {
    Requester.post(Gateway.admin + "courses/" + course.id + "/toggle-non-sequential")
        .then(function () {
            course.allowNonSequentialAccess = !course.allowNonSequentialAccess;
            Toasts.add("success", i18n.t("saved_changes"), "");
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        });
}

function confirmDeleteCourse() {
    ModalManager.open(i18n.t("delete_course"), [], Modal_Confirm_Delete, {callback: deleteCourse});
}

function deleteCourse() {
    deleting = true;
    Requester.delete(Gateway.admin + "courses/" + course.id)
        .then(function () {
            RouterService.navigateToCourses();
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .finally(function () {
            deleting = false;
        });
}

const Component_Course_Settings = {
    oninit: function (vnode) {
        course = vnode.attrs.course;
    },
    view: function () {
        return [
            m(".content-box", [
                m(".form bordered-sections", {class: !Auth.validateRights(["courses_edit_settings"]) ? "disabled" : ""}, [
                    m(".form-section flex-row justify-between", [
                        m(".mr-2", [
                            m(".text-bolder", i18n.t("active")),
                            m("div", i18n.t("essentially_an_on_off_switch_for_the_course"))
                        ]),
                        m(".btn btn-toggle", {onclick: toggleIsActive, class: course.isActive ? "active" : ""})
                    ]),
                    m(".form-section flex-row justify-between", [
                        m(".mr-2", [
                            m(".text-bolder", i18n.t("hide_if_not_enrolled")),
                            m("div", i18n.t("only_show_this_course_in_the_library_to_users_who_are_enrolled"))
                        ]),
                        m(".btn btn-toggle", {
                            onclick: toggleHideIfNotEnrolled,
                            class: course.hideIfNotEnrolled ? "active" : ""
                        })
                    ]),
                    m(".form-section flex-row justify-between", [
                        m(".mr-2", [
                            m(".text-bolder", i18n.t("allow_self_enrolment")),
                            m("div", i18n.t("allow_self_enrolment_tip"))
                        ]),
                        m(".btn btn-toggle", {
                            onclick: toggleAllowSelfEnrolment,
                            class: course.allowSelfEnrolment ? "active" : ""
                        })
                    ]),
                    m(".form-section flex-row justify-between", [
                        m(".mr-2", [
                            m(".text-bolder", i18n.t("allow_reenrolment")),
                            m("div", i18n.t("allow_reenrolment_tip"))
                        ]),
                        m(".btn btn-toggle", {
                            onclick: toggleAllowReEnrolment,
                            class: course.allowReEnrolment ? "active" : ""
                        })
                    ]),
                    m(".form-section flex-row justify-between", [
                        m(".mr-2", [
                            m(".text-bolder", i18n.t("allow_non_sequential_unit_access")),
                            m("div", i18n.t("allow_units_in_this_course_to_be_accessed_in_any_order"))
                        ]),
                        m(".btn btn-toggle", {
                            onclick: toggleAllowNonSequentialAccess,
                            class: course.allowNonSequentialAccess ? "active" : ""
                        })
                    ]),
                    m(".form-section flex-row justify-between", [
                        m(".mr-2", [
                            m(".text-bolder", i18n.t("allow_guest_enrolment")),
                            m("div", i18n.t("allow_guest_enrolment_tip"))
                        ]),
                        m(".btn btn-toggle", {
                            onclick: toggleAllowGuests,
                            class: course.allowGuestEnrolment ? "active" : ""
                        })
                    ])
                ])
            ]),
            Auth.validateRights(["courses_delete"], [
                m(".content-box", [
                    !deleting && !saving ? m("button.btn-error", {onclick: confirmDeleteCourse}, i18n.t("delete_course")) : m("button.btn-text no-click text-error", [i18n.t("deleting"), m(".loading-dots")])
                ])
            ])
        ];
    }
};
export default Component_Course_Settings;
