import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import FormUtilities from "../../shared/utilities/form_utilities";
import i18n from "../../shared/i18n/i18n";
import SessionController from "../../shared/session";
import Auth from "../../shared/auth";
import RoleService from "../../shared/services/role.service";

let loading, saving, user, originalUser;

function loadProfileSettings() {
    loading = true;
    Requester.get(Gateway.identity + "settings")
        .then(function (result) {
            user = result;
            originalUser = JSON.parse(JSON.stringify(result));
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        loading = false;
    });
}

function saveChanges() {
    if (FormUtilities.validateFields("input")) {
        saving = true;
        Requester.post(Gateway.identity + "settings", user)
            .then(function () {
                Toasts.add("success", i18n.t("saved_changes"));
                loadProfileSettings();
            }).catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        }).finally(function () {
            saving = false;
        });
    }
}


const Component_Profile_Settings = {
    oninit: function () {
        loadProfileSettings();
    },
    view: function () {
        let userIdentity = SessionController.session.identity;

        return [
            !loading ? [
                userIdentity.sso ? [
                    m(".label label-warning mb-2", i18n.t("unable_edit_profile_via_single_sign_on"))
                ] : "",
                m(".form", { class: userIdentity.sso ? "disabled" : "" }, [
                    m(".form-section", { class: Auth.validateRights(["users_email_edit"], "hasRights") != null ? "" : "disabled" }, [
                        m(".form-label", i18n.t("first_name")),
                        m("input[type=text]", {
                            oninput: function () {
                                user.firstName = this.value;
                            }, value: user.firstName
                        })
                    ]),
                    m(".form-section", { class: Auth.validateRights(["users_email_edit"], "hasRights") != null ? "" : "disabled" }, [
                        m(".form-label", i18n.t("last_name")),
                        m("input[type=text]", {
                            oninput: function () {
                                user.lastName = this.value;
                            }, value: user.lastName
                        })
                    ]),
                    m(".form-section", { class: Auth.validateRights(["users_email_edit"], "hasRights") != null ? "" : "disabled" }, [
                        m(".form-label", i18n.t("email")),
                        m("input[type=text]", {
                            oninput: function () {
                                user.email = this.value;
                            }, value: user.email
                        })
                    ]),
                    user.username ?
                        m(".form-section", [
                            m(".form-label", i18n.t("username")),
                            m("input[type=text].disabled", {value: user.username})
                        ]) : "",
                    RoleService.intToRole(SessionController.session.identity.accountLevel) !== RoleService.roles.none ?
                        m(".form-section", [
                            m(".form-label", i18n.t("account_role")),
                            m("input[type=text].disabled", {value: RoleService.intToRole(SessionController.session.identity.accountLevel)})
                        ]) : "",
                    userIdentity.sso ? "" : [
                    m(".form-section required", [
                        m(".form-label", i18n.t("current_password")),
                        m("input[type=password]", {
                            oninput: function () {
                                user.currentPassword = this.value;
                                originalUser.currentPassword = this.value;
                            }, value: user.currentPassword
                        })
                    ]),
                    m(".form-section", [
                        m(".form-label", i18n.t("new_password")),
                        m("input[type=password]", {
                            oninput: function () {
                                user.newPassword = this.value;
                            }, value: user.newPassword
                        })
                    ]),
                    user.newPassword ? [
                        m(".form-section required", [
                            m(".form-label", i18n.t("confirm_new_password")),
                            m("input[type=password]", {
                                oninput: function () {
                                    user.confirmNewPassword = this.value;
                                }, value: user.confirmNewPassword
                            })
                        ])
                    ] : ""],
                    m(".form-buttons", {class: loading || JSON.stringify(user) === JSON.stringify(originalUser) || !user.currentPassword ? "disable-buttons" : ""}, [
                        !saving ? m("button", {onclick: saveChanges}, i18n.t("save_changes")) : m("button.btn-text no-click", [i18n.t("saving"), m(".loading-dots")])
                    ])
                ])
            ] : m(".text-gray padding", [i18n.t("loading"), m(".loading-dots")])
        ];
    }
}

export default Component_Profile_Settings;
