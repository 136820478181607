import m from "mithril";
import { Routes } from "./router";
import i18n from "./shared/i18n/i18n";
let css = require('./styles/styles.css');
let css2 = require('./styles/style.css');
let scss = require('./styles/SecondaryTheme.scss');
let font = require('./styles/fonts/font-style.css');
let public_style = require('./styles/public-styles.css');
let admin_style = require('./styles/admin-styles.css');
let responsive_style = require('./styles/responsive-styles.css');
require('../public/config');

i18n.setLocale();

let theme = localStorage.getItem("theme");
if (theme) {
    document.documentElement.setAttribute("theme", theme);
} else {
    document.documentElement.setAttribute("theme", 'light');
}
// m.route.prefix = ''; this is to change the routing type.
m.route(document.body, '/', Routes);
