import m from "mithril";
import Squire from "squire-rte/build/squire";
import Toasts from "../../../shared/toasts";
import i18n from "../../../shared/i18n/i18n";

let _this;
let editorId,
    currentEditor,
    editorHeader,
    editorBody,
    editorFooter,
    content,
    bg;
let scale = 1;
let saving = false;

function setup(el, section) {
    if (section === "header") {
        editorHeader = new Squire(el);
        editorHeader.setHTML(decodeURIComponent(content.header || ""));
        editorHeader.addEventListener("input", function () {
            content.header = editorHeader.getHTML();
        });
    } else if (section === "footer") {
        editorFooter = new Squire(el);
        editorFooter.setHTML(decodeURIComponent(content.footer || ""));
        editorFooter.addEventListener("input", function () {
            content.footer = editorFooter.getHTML();
        });
    } else {
        editorBody = new Squire(el);
        editorBody.setHTML(decodeURIComponent(content.body || ""));
        editorBody.addEventListener("input", function () {
            content.body = editorBody.getHTML();
        });
    }
}

const CertificateEditorInput = {
    getContent: function () {
        return content;
    },
    getEditor: function () {
        return currentEditor;
    },
    setScale: function () {
        const el = document.querySelector(".cert-scaler");
        scale = el.parentNode.clientWidth / el.clientWidth;
        el.style.transform = "scale(" + scale + ")";
        m.redraw();
    },
    setBg: function () {
        let file = document.querySelector(".certificate-bg-input").files[0];

        if (file) {
            if (file.size / 2048 / 2048 > 1)
                Toasts.add("error", i18n.t("file_too_big"), i18n.t("filenotbiggerthan") + "1mb");
            else
                bg = window.URL.createObjectURL(file);
        }

        document.querySelector(".bg-preview").setAttribute("src", bg);
    },
    oninit: function (vnode) {
        _this = this;
        if (vnode.attrs.content) {
            content = vnode.attrs.content;
            editorId = vnode.attrs.id;
        }

        if (vnode.attrs.bg)
            bg = vnode.attrs.bg;
    },
    oncreate: function () {
        window.onresize = function () {
            if (document.querySelector(".cert-scaler"))
                _this.setScale();
        };
        _this.setScale();
    },
    onremove: function () {
        window.onresize = null;
        bg = null;
        scale = 1;
        content = null;
        currentEditor = null;
        editorHeader = null;
        editorBody = null;
        editorFooter = null;
    },
    view: function () {
        return [
            m("div", {id: editorId}, [
                m("img.bg-preview", {src: bg ? bg : ""}),
                m(".cert-editor",
                    m(".cert-scaler", {style: "transform: scale(" + scale + ");"}, [
                        m(".text-editor-input cert-header", {
                            oncreate: function (e) {
                                setup(e.dom, "header");
                                currentEditor = editorHeader;
                            }, onclick: function () {
                                currentEditor = editorHeader;
                            }, onfocus: function () {
                                currentEditor = editorHeader;
                            }
                        }),
                        m(".text-editor-input cert-body", {
                            oncreate: function (e) {
                                setup(e.dom, "body");
                            }, onclick: function () {
                                currentEditor = editorBody;
                            }, onfocus: function () {
                                currentEditor = editorBody;
                            }
                        }),
                        m(".text-editor-input cert-footer", {
                            oncreate: function (e) {
                                setup(e.dom, "footer");
                            }, onclick: function () {
                                currentEditor = editorFooter;
                            }, onfocus: function () {
                                currentEditor = editorFooter;
                            }
                        }),
                    ])
                ),
                m("input[type=file][hidden].certificate-bg-input", {
                    onchange: function () {
                        _this.setBg();
                    }
                })
            ])
        ];
    }
};
export default CertificateEditorInput;
