import m from 'mithril';

let count = 0;

let ModalManager = {
    open: function (title, classes, component, attributes, onCrossClose) {
        const id = ModalManager.generateModalId();
        let modal = document.createElement("div");
        modal.id = ModalManager.getModalElementId(id);
        if (component.setModalId) {
            component.setModalId(id);
        }
        modal.classList.add("modal");
        if (classes) {
            for (let i = 0; i < classes.length; i++) {
                modal.classList.add(classes[i]);
            }
        }
        document.body.appendChild(modal);
        m.mount(modal, {
            view: function () {
                return m(Modal, {
                    title: title,
                    id: id,
                    onCrossClose: onCrossClose,
                    component: {
                        view: function () {
                            return attributes ? m(component, attributes) : m(component);
                        }
                    }
                });
            }
        });
    },

    close: function (id) {
        const modal = ModalManager.getModalElement(id);
        if (modal) {
            m.mount(modal.querySelector("#modal-target"), null);
            document.body.removeChild(modal);
        }
    },

    closeAll: function () {
        for (let i = count; i >= 0; i--) {
            const modal = ModalManager.getModalElement(i);
            if (modal) {
                m.mount(modal.querySelector("#modal-target"), null);
                document.body.removeChild(modal);
            }
        }
    },

    generateModalId: function () {
        return count++;
    },

    getModalElementId: function(id) {
        return "modal" + id;
    },

    getModalElement: function(id) {
        const elementId = ModalManager.getModalElementId(id);
        return document.getElementById(elementId);
    }
}

function Modal() {
    let current = null;
    let id = null;
    let onCrossClose = null;

    function close() {
        ModalManager.close(id);

        if (onCrossClose) {
            onCrossClose();
        }
    }

    return {
        oninit: function (vnode) {
            id = vnode.attrs.id;
            current = ModalManager.getModalElement(vnode.attrs.id);
            onCrossClose = vnode.attrs.onCrossClose;
        },
        oncreate: function (vnode) {
            m.mount(current.querySelector("#modal-target"), vnode.attrs.component);
        },
        view: function (vnode) {
            return [
                m("#modal-bg"),
                m("#modal-inner",
                    m(".titlebar", [
                        m(".titlebar-section", vnode.attrs.title),
                        m(".titlebar-section", [
                            !current.classList.contains("no-close") ? m("i.icon-x c-hand", { onclick: close }) : ""
                        ])
                    ]),
                    m("#modal-target")
                )
            ];
        }
    }
}

export default ModalManager;
