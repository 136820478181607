import m from "mithril";
import ModalManager from "../../shared/modal";
import i18n from "../../shared/i18n/i18n";

let message;
let modalApprovalRejectVnode;
let modalId;

function submit() {
    if (modalApprovalRejectVnode.attrs.callback)
        modalApprovalRejectVnode.attrs.callback(modalApprovalRejectVnode.attrs.unitEnrolment, false, message,
            modalApprovalRejectVnode.attrs.user, true);

    //Clear message textField after submit
    message = '';
    ModalManager.close(modalId);
}

const Modal_Approval_Reject = {
    view: function (vnode) {
        modalApprovalRejectVnode = vnode; // if this doesn't work change this to vnode: https://mithril.js.org/components.html
        return [
            m(".form-section required", [
                m(".form-label", i18n.t("returning_unit_message"), m("br"), m("br"), i18n.t("message")),
                m("textarea", {
                    oninput: function () {
                        message = this.value;
                    }, value: message
                })
            ]),
            m(".form-buttons", [
                !!message ?
                m("button", {onclick: submit}, i18n.t("return")) : m("button.btn-text no-click", i18n.t("return"))
            ])
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
};
export default Modal_Approval_Reject;
