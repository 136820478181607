import m from 'mithril';
import Requester from '../../shared/request';
import Gateway from '../../shared/app_settings';
import Toasts from '../../shared/toasts';
import i18n from '../../shared/i18n/i18n';
import SessionController from '../../shared/session';
import Loading from '../../shared/loading';
import RouterService from '../../shared/services/router.service';
import ModalManager from "../../shared/modal";
import Modal_Scorm_Adblocker from "./modal_scorm_adblocker";

let originalUnit, interactiveUnit, enrId, exitCallback, loading, courseId;

function loadPlayer() {
    loading = true;
    Requester.get(Gateway.public + "units/interactive/" + originalUnit.id + "/launch?enrolmentId=" + enrId + "&unitEnrolmentId=" + originalUnit.enrolment.id)
        .then(function (result) {
            interactiveUnit = null;
            interactiveUnit = result;
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .finally(function () {
            loading = false;
            setTimeout(getAccessedOn, 5000);
        });
}

function activate() {
    let enr = {
        enrolmentId: enrId,
        unitEnrolmentId: interactiveUnit.enrolment.id,
        unitVersion: interactiveUnit.version,
        source: interactiveUnit.scormSource,
        data: interactiveUnit.enrolment.cmiData,
        version: interactiveUnit.scormVersion
    };

    document.getElementById("player-top").contentWindow.postMessage({
        method: "activate",
        data: enr
    }, SessionController.session.environment.storageUrl);
}

function deactivate() {
    document.getElementById("player-top").contentWindow.postMessage({method: "deactivate"}, SessionController.session.environment.storageUrl);
    document.getElementById('player-mount').innerHTML = null;
    if (courseId) {
        RouterService.navigateToPublicCourse(courseId);
    }
}

function save(data, finish) {
    let content = {
        unitEnrolmentId: interactiveUnit.enrolment.id,
        unitVersion: interactiveUnit.version,
        scormVersion: interactiveUnit.scormVersion,
        data: data
    };

    Requester.post(Gateway.public + "units/interactive/save-progress", content)
        .then(function (result) {
            if (finish) {
                exitCallback();
            }

            if (result.isCompleted) {
                Toasts.add("success", i18n.t("unit_completed"));
                m.redraw();
            }
            else
                Toasts.add("success", i18n.t("progress_saved"));
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        });
}

function receiveMessage(event) {
    if (!event.origin.endsWith(SessionController.session.environment.storageUrl))
        return;

    if (event.data.method === "update")
        save(event.data.data);

    if (event.data.method === "finish") {
        save(event.data.data, true);
    }
}
function getAccessedOn() {
    Requester.get(Gateway.public + "units/interactive/" + interactiveUnit.enrolment.id + "/accessed-on")
    .then(function (result) {
        if (!result.value) {
            adBlockerPrompt();
        }
    });
}

function adBlockerPrompt() {
    ModalManager.open(i18n.t("action_required"), [], Modal_Scorm_Adblocker, {closeScormCourse: deactivate}, deactivate);
}

function getHeaderName() {
    if (!interactiveUnit) {
        return null;
    }

    return SessionController.session.identity.name + ": " + interactiveUnit.name;
}

const Component_Player = {
    oninit: function (vnode) {
        enrId = vnode.attrs.enrolmentId;
        originalUnit = vnode.attrs.unit;
        loadPlayer();
        exitCallback = vnode.attrs.callback;
        courseId = vnode.attrs.courseId;
        window.addEventListener("message", receiveMessage, false);

    },
    onremove: function () {
        window.removeEventListener("message", receiveMessage);
    },
    view: function () {
        return [
            m("#player", [
                m(".flex-row justify-between align-center padding", [
                    m("h3.ellipsis", getHeaderName()),
                    m("button.btn-cta", {onclick: deactivate }, i18n.t("SaveAndExit"))
                ]),
                !loading ? [
                    interactiveUnit ? [
                        m("iframe#player-top", {
                            src: SessionController.session.environment.storageUrl + "/scorm-player/scorm_player.html",
                            onload: activate
                        })
                    ] : i18n.t("cant_load")
                ] : m(Loading)
            ])
        ];
    }
};
export default Component_Player;
