import m from "mithril";
import ModalManager from "../../shared/modal";
import Requester from "../../shared/request";
import Toasts from "../../shared/toasts";
import Gateway from "../../shared/app_settings";
import Auth from "../../shared/auth";
import Modal_Confirm_Delete from "../../shared/components/modal_confirm_delete";
import Modal_SSO_Edit from "./modal_sso_edit";
import i18n from "../../shared/i18n/i18n";


let acct, loading, configs, selected = [];

function newSsoConfig() {
    ModalManager.open(i18n.t("new_sso_configuration"), [], Modal_SSO_Edit, {callback: reloadSsoConfigs});
}

function editSsoConfig(config) {
    ModalManager.open(i18n.t("edit_sso_configuration"), [], Modal_SSO_Edit, {config: config, callback: reloadSsoConfigs});
}

function reloadSsoConfigs() {
    setTimeout(loadSsoConfigs, 500);
}

function loadSsoConfigs() {
    loading = true;
    Requester.get(Gateway.admin + "account/sso")
        .then(function (result) {
            configs = result;
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .finally(function () {
            loading = false;
        });
}

function confirmDeleteConfigs() {
    ModalManager.open(i18n.t("delete_sso_configurations"), [], Modal_Confirm_Delete, {callback: deleteSsoConfigs});
}

function deleteSsoConfigs() {
    loading = true;
    Requester.delete(Gateway.admin + "account/sso", selected)
        .then(function (result) {
            reloadSsoConfigs();
        })
        .catch(function (error) {
            loading = false;
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        });
}

function toggleAllSelected() {
    if (selected.length > 0)
        selected = [];
    else {
        if (configs) {
            for (let i = 0; i < configs.length; i++) {
                selected.push(configs[i].id);
            }
        }
    }
}

function toggleSelected(id) {
    const index = selected.indexOf(id);
    if (index === -1)
        selected.push(id);
    else
        selected.splice(index, 1);
}


const Component_Account_SSO = {
    oninit: function () {
        loadSsoConfigs();
    },
    view: function () {
        return [
            m(".content-box", [
                m(".toolbar", {class: loading ? "disable-buttons" : ""}, [
                    m(".toolbar-section", [
                        Auth.validateRights([Auth.rights.account_single_sign_on_configuration_create], m("button.with-icon", {onclick: newSsoConfig}, [m("i.icon-plus"), i18n.t("new_sso_configuration")])),
                        Auth.validateRights([Auth.rights.account_single_sign_on_configuration_delete], m("button.with-icon", {
                            onclick: confirmDeleteConfigs,
                            class: selected.length > 0 ? "" : "disabled"
                        }, [m("i.icon-delete"), i18n.t("delete")])),
                    ])
                ]),
                !loading ? [
                    configs ? [
                        configs.length > 0 ? [
                            m(".table-wrapper", [
                                m("table.full-width", [
                                    m("thead",
                                        m("tr", [
                                            Auth.validateRights([
                                                    Auth.rights.account_single_sign_on_configuration_create,
                                                    Auth.rights.account_single_sign_on_configuration_delete
                                                ],
                                                m("th", m(".toolbar-checkbox", {onclick: toggleAllSelected}, m("i", {class: selected.length > 0 ? "icon-checkbox-checked" : "icon-checkbox-empty"})))
                                            ),
                                            m("th", i18n.t("name")),
                                            m("th", i18n.t("type")),
                                            m("th", i18n.t("service_url"))
                                        ])
                                    ),
                                    m("tbody", configs.map(function (config) {
                                        return m("tr.c-hand", [
                                            Auth.validateRights([
                                                    Auth.rights.account_single_sign_on_configuration_create,
                                                    Auth.rights.account_single_sign_on_configuration_delete
                                                ], 
                                                m("td.list-checkbox c-hand pl-1 pr-1", {
                                                    onclick: function () {
                                                        toggleSelected(config.id);
                                                    }, style: "width: 18px;"
                                                }, m("i", {class: selected.indexOf(config.id) !== -1 ? "icon-checkbox-checked" : "icon-checkbox-empty"}))
                                            ),
                                            m("td.text-bolder", {
                                                onclick: function () {
                                                    editSsoConfig(config);
                                                }
                                            }, config.name),
                                            m("td", {
                                                onclick: function () {
                                                    editSsoConfig(config);
                                                }
                                            }, config.type === "cas" ? "CAS" : config.type === "jwt" ? "JWT" : i18n.t("invalid")),
                                            m("td", "~/login/" + config.type + "/" + config.code)
                                        ])
                                    }))
                                ])
                            ])
                        ] : m(".text-gray padding", i18n.t("no_sso_configurations"))
                    ] : m(".text-gray padding", i18n.t("unable_to_load_sso_configurations"))
                ] : m(".text-gray padding", [i18n.t("loading"), m(".loading-dots")])
            ])
        ];
    }
};

export default Component_Account_SSO;
