import SharedConstants from "../shared_constants";

const ResponsiveUtilities = {
    mobileAndTabletCheck: function () {
        let check = false;
        (function (a) { if (SharedConstants.mobileAndTabletDevicesRegex.test(a) || SharedConstants.mobileAndTabletCodesRegex.test(a.substr(0, 4))) check = true; })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    }
}

export default ResponsiveUtilities;
