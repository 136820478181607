import m from 'mithril';
import Requester from '../../shared/request';
import Gateway from '../../shared/app_settings';
import Toasts from '../../shared/toasts';
import ModalManager from '../../shared/modal';
import i18n from '../../shared/i18n/i18n';
import modal_text from "../../shared/components/modal_text";
import modal_text_view from "../../shared/components/modal_text_view";

let reloadCallback,
    loading,
    resubmitted,
    unitResubmit,
    isResubmitted;

let feebackResubmit = {};

function submitForApproval(unit, course) {
    loading = true;

    if(isResubmitted === true) {
        unitResubmit =  unit.enrolment.resubmitted = true;
    }

    Requester.post(Gateway.public + 'units/approval/', {
        UnitEnrolmentId: unit.enrolment.id,
        EnrolmentId: course.enrolment.id,
        UnitResubmit: unitResubmit
    })
        .then(function (result) {
            unit.enrolment.submitted = true;

            resubmitted = true;
            if (reloadCallback)
                reloadCallback();
        })
        .catch(function (error) {
            Toasts.add('error', i18n.t('error'), i18n.t(error.response.properties.errorCode));
        })
        .then(function () {
            loading = false;
        });
}

function unitSubmitForApproval(unit, course, unitInstructions)  {
    feebackResubmit ? isResubmitted = true : false;

    if(isResubmitted) {
        submitForApproval(unit, course);
    }
    else {
        ModalManager.open(i18n.t("submit_approval_btn"), [],
            unit.enrolment.isCompleted && unit.enrolment.isPassed ?
                modal_text_view :  modal_text, {
                callback: function () {
                    submitForApproval(unit, course);
                    resubmitted = true;
                },
                text: [unitInstructions, m("br"), m("br"), m("br")],
                btnText: i18n.t("submit_approval_btn")
            });
    }
}

function unitViewFeedback(unit, course){
    ModalManager.open(unit.name, [],
        unit.enrolment.isCompleted && unit.enrolment.isPassed ?
            modal_text_view :  modal_text, {
        callback: function () {
            unitSubmitForApproval(unit, course);
            resubmitted = true;
        },
            text: [m("h4", i18n.t("approver_name"), m(".username-approver", unit.enrolment.approverName)),
                m("br"),  m("br"),  m("br"),
                m("h4", i18n.t('approver_comment')),
                m("br"),
                unit.enrolment.approverFeedback, m("br"), m("br"), m("br"),
                unit.enrolment.isCompleted && unit.enrolment.isPassed ? '' :
                    m(".text-italic",i18n.t('reSubmit_message'))
            ],
        btnText: i18n.t("reSubmit_approval")
    });
}

function isResubmitForApproval(unitEnrolment) {
    return !unitEnrolment.isPassed
        && !unitEnrolment.resubmitted
        &&  unitEnrolment.submitted
        &&  unitEnrolment.unitReturned;
}

function isAdminTriggeredReturn(unitEnrolment){
    return !unitEnrolment.submitted && unitEnrolment.unitReturned
}

function isFeedbackAvailable(unitEnrolment){
    return unitEnrolment.approverFeedback
        && unitEnrolment.isCompleted
        && unitEnrolment.isPassed;
}

function isSubmitRequest (unitEnrolment){
    return !unitEnrolment.isCompleted
        && !unitEnrolment.submitted
        && !unitEnrolment.unitReturned
        && !unitEnrolment.resubmitted;
}

function isAwaitingApproval(unitEnrolment){
    return unitEnrolment.submitted &&
        !unitEnrolment.isPassed &&
        !unitEnrolment.unitReturned;
}

function getUnitInstructions(selectedUnitId){
     return Requester.get(Gateway.admin + "units/" + selectedUnitId)
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        }).finally(function () {
            loading = false;
        });
}

const Component_Unit_Actions_Approval = {
    oninit: function (vnode) {
        reloadCallback = vnode.attrs.reloadCallback;
    },
    view: function (vnode) {
        let unit = vnode.attrs.unit;
        let course = vnode.attrs.course;
        reloadCallback = vnode.attrs.reloadCallback;
        return [
            unit?.enrolment?.allowAccess ? [
                isSubmitRequest(unit.enrolment) ? m('button', {
                    onclick: function () {
                        feebackResubmit = false;
                        getUnitInstructions(unit.id).then(
                            result => unitSubmitForApproval(unit, course, result.properties.instructions)
                        );
                    },
                    class: 'btn-cta' + (loading  ? 'disabled' : '')
                }, i18n.t('submit_for_approval')) : '',

                isAwaitingApproval(unit.enrolment) ?
                    m('button.with-icon', {
                        onclick: function () {
                            feebackResubmit = false;
                            unitViewFeedback(unit, course);
                        },
                        class: resubmitted = 'disabled'
                    }, resubmitted ? [i18n.t('awaiting_approval'), m('i.icon-check ml-1 pr-0')] : i18n.t('k_awaiting_approval'))
                    : '',

                isResubmitForApproval(unit.enrolment) ||
                isAdminTriggeredReturn(unit.enrolment) ?
                    m('button', {
                        onclick: function () {
                            feebackResubmit = true;
                            unitViewFeedback(unit, course);
                        },
                        class: 'btn-cta' + (!unit.enrolment.isCompleted ? ' ml-2' : '')
                    }, i18n.t('resubmit_for_approval'))
                    : '',

                isFeedbackAvailable(unit.enrolment)?
                    m('button', {
                        onclick: function () {
                            feebackResubmit = false;
                            unitViewFeedback(unit, course);
                        },
                        class: 'btn-cta' + (!unit.enrolment.isCompleted ? ' ml-2' : '')
                    }, i18n.t("view_feedback"))
                    : ''
            ] : ''

        ];
    }
};
export default Component_Unit_Actions_Approval;
