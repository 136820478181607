import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import i18n from "../../shared/i18n/i18n";
import SessionController from "../../shared/session";
import Loading from "../../shared/loading";
import RouterService from "../../shared/services/router.service";

let loading = true;

function verifyToken(ticket, courseId) {
    let path = !!courseId ? `path=public/courses/${courseId}&` : '';
    let serviceUrl = `login/cas?${path}ticket=` + ticket;
    Requester.post(Gateway.identity + serviceUrl,
        {
            "ticket": ticket
        },
        {"Content-Type": "application/json"})
        .then(function (result) {
            SessionController.setSession(result);
            localStorage.setItem("sso-logout", result.identity.ssoLogout);
            if(!!courseId) {
                RouterService.navigateToPublicCourse(courseId);
            } else {
                RouterService.navigateOnLogin();
            }
        })
        .catch(function (error) {
            loading = false;
            SessionController.getSession().then(session => {
                if(!!session.environment.logoutUrl) {
                    // If SSO login was successful any further attempts to login will automatically sign in,
                    // Visit the LogoutUrl to invalidate the session and redirect to the home page.
                    window.location = session.environment.logoutUrl + "?service=" + window.location.protocol + "//" + window.location.host;
                }
            })
            Toasts.error(JSON.stringify(error.response));
        });
}


const PublicPage_Login_SSO_CAS = {
    oninit: function (vnode) {
        if (SessionController.session && SessionController.session.identity) {
            if(!!vnode.attrs.courseId) {
                RouterService.navigateToPublicCourse(vnode.attrs.courseId);
            }
            else {
                RouterService.navigateOnLogin();
                Toasts.add("success", i18n.t("already_logged_in"), "");
            }
        }

        if (m.route.param("ticket")) {
            verifyToken(m.route.param("ticket"), vnode.attrs.courseId);
        } else {
            RouterService.navigateToPublicLogin();
        }

    },
    view: function () {
        return [
            m(".relative", [
                loading ? m(Loading, {class: "loading-inline"})
                    : [
                        m("h3.mb-2", i18n.t("unable_to_log_in")),
                        m(".mb-1", i18n.t("please_try_again_or_contact_support")),
                        SessionController.session && SessionController.session.environment && !SessionController.session.environment.ssoOnly ? [
                            m(m.route.Link, {href: RouterService.getPublicLoginUrl(), class: "btn"}, i18n.t("back_to_login"))
                        ] : ""
                    ]
            ])
        ];
    }
};
export default PublicPage_Login_SSO_CAS;
