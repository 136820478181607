import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import SessionController from "../../shared/session";
import i18n from "../../shared/i18n/i18n";

let loading, unitEnrolment, unit;

function loadUnitEnrolment(unitEnr) {
    loading = true;
    Requester.get(Gateway.admin + "units/enrolments/" + unitEnr)
        .then(function (result) {
            unitEnrolment = result.properties;
            loadUnit(unitEnrolment.unitId);
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        loading = false;
    });
}

function loadUnit(unitId) {
    Requester.get(Gateway.admin + "units/" + unitId)
        .then(function (result) {
            unit = result.properties;
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .then(function () {
            loading = false;
        });
}

function activate() {
    const enr = {
        unitVersion: unit.unitVersion,
        source: unit.scormSource,
        data: unitEnrolment.data,
        version: unitEnrolment.scormVersion
    };

    document.getElementById("player-top").contentWindow.postMessage({
        method: "activate",
        data: enr
    }, SessionController.session.environment.storageUrl);
}


const Modal_Scorm_Progress_Preview = {
    oninit: function (vnode) {
        loadUnitEnrolment(vnode.attrs.unitEnrolment.id);
    },
    view: function () {
        return [
            m(".flex-auto", [
                !loading ?
                    m(".scorm-preview", unit && unit.scormSource ?
                        m("iframe#player-top", {
                            src: SessionController.session.environment.storageUrl + "/scorm-player/scorm_preview_player.html",
                            onload: activate
                        })
                        : i18n.t("cant_load")
                    )
                    : [i18n.t("loading"), m(".loading-dots")]
            ])
        ];
    }
}

export default Modal_Scorm_Progress_Preview;
