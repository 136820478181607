import m from "mithril";
import Component_Unit_Document_Settings from "./component_unit_document_settings";
import i18n from "../../../shared/i18n/i18n";
import Component_Unit_Document_Preview from "./component_unit_document_preview";

let loading = false;
let tab = "settings";
let unit = null;

const Page_Unit_Document = {
    oninit: function (vnode) {
        unit = vnode.attrs.unit;

        if (unit && unit.properties.documentFileName)
            tab = "preview";
    },
    view: function () {
        return [
            m(".content-box", [
                m(".toolbar", {class: loading ? "disable-buttons" : ""}, [
                    m(".toolbar-section with-menu", [
                        m("h1", i18n.t("document_unit")),
                        m(".toolbar-menu", [
                            m("div", {
                                class: tab === "preview" ? "active" : "", onclick: function () {
                                    tab = "preview";
                                }
                            }, i18n.t("preview")),
                            m("div", {
                                class: tab === "settings" ? "active" : "", onclick: function () {
                                    tab = "settings";
                                }
                            }, i18n.t("settings"))
                        ])
                    ])
                ])
            ]),
            !loading ? [
                unit ? [
                    tab === "settings" ? m(Component_Unit_Document_Settings, {unit: unit.properties})
                        : tab === "preview" ? m(Component_Unit_Document_Preview, {unit: unit.properties})
                            : ""
                ] : m(".text-gray padding", i18n.t("unable_to_load_unit"))
            ] : m(".text-gray padding", [i18n.t("loading"), m(".loading-dots")])
        ];
    }
};
export default Page_Unit_Document;
