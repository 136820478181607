import m from "mithril";
import i18n from "../../shared/i18n/i18n";
import avettaPegaLogo from '../../shared/img/avetta-pega-logo-linear.svg';
import { SUPPORT_LINK } from "../../shared/constants/constants";

const Contact_Support_team = {
    view: function () {
        return [
            m("a.btn.fill-width.contact-our-team",
                {
                    target: "_blank", href: SUPPORT_LINK,
                    style: {
                        height: "100%",
                    }
                },
                m.trust(avettaPegaLogo),
                m("div", {
                    style: {
                        padding: "0px 1px",
                    }
                }, [
                    m("span", i18n.t("need_help") + " "),
                    m("a.hyperlink",{
                        style: {
                            whiteSpace: "normal", // Allow text to wrap
                            wordBreak: "break-word" // Break long words
                        }
                    }, i18n.t("contact_our_support_team"))
                ]),
            )
        ];
    }
};

export default Contact_Support_team;
