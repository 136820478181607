import Gateway from "../app_settings";
import Requester from "../request";

const UserService = {
    getAssignableRoles: function (isInternalRole) {
        return Requester.get(`${Gateway.admin}users/assignable-roles?isInternalRole=${isInternalRole}`);
    },

    getAccountUsers: function (filter, search, limit, offset, organisationId = null, includeAccountUsers = true, sortOnField = null, sortDescending = null) {
        let params = [];

        if (search) {
            params.push("search=" + search);
        }

        if (filter) {
            params.push("filter=" + filter);
        }

        params.push("offset=" + offset);
        params.push("limit=" + limit);

        if (organisationId) {
            params.push("organisationId=" + organisationId);
        }

        params.push("includeAccountUsers=" + includeAccountUsers);

        if (sortOnField) {
            params.push("sortOnField=" + sortOnField);

            if (sortDescending != null) {
                params.push("sortDescending=" + sortDescending);
            }
        }

        return Requester.get(`${Gateway.admin}account/users?${params.join("&")}`);
    },

    updateRole: function (userIds, role) {
        return Requester.post(`${Gateway.admin}users/role`, {
            userIds: userIds,
            role: role
        });
    },

    removeFromOrganisation: function(userId) {
        return Requester.post(`${Gateway.admin}users/remove`, userId);
    }
}

export default UserService;