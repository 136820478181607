import m from "mithril";
import i18n from "../i18n/i18n";
import Toasts from "../toasts";

const FileUploader = {
    oninit: function(vnode) {
        this.id = vnode.attrs.id || "default-file-input"; // default id
        this.accept = vnode.attrs.accept || "*"; // Default accept all
        this.maxSizeInMB = vnode.attrs.maxSizeInMB || 100; // Default 100MB
        this.onFileSelect = vnode.attrs.onFileSelect;
        this.maxSizeInBytes = this.maxSizeInMB * 1000 * 1000;
    },
    oncreate: function(vnode) {
        vnode.dom.addEventListener('change', this.handleFileSelect.bind(this));
    },
    handleFileSelect: function(event) {
        const file = event.target.files[0];

        if (file) {
            if (file.size > this.maxSizeInBytes) {
                Toasts.add("error", i18n.t("file_size_exceeds_limit", {'max_file_size' : `${this.maxSizeInMB}`}));
                event.target.value = ''; // Clear the file input
            } else {
                // You can add more functionality here, like displaying file info
                if (typeof this.onFileSelect === 'function') {
                    this.onFileSelect(file);
                }
            }
        }
    },
    view: function() {
        return m("input[type=file]", {
            id: this.id,
            accept: this.accept
        });
    }
};


export default FileUploader;
