import Auth from "../../shared/auth";
import i18n from "../../shared/i18n/i18n";
import Component_Organisation_Details from "./component_organisation_details";

function Page_AdminOrganisation() {
    let loading = false;
    let tab = "details";
    let currentPage;

    function init({ attrs }) {
        currentPage = "settings";
    }
    
    return {
        oninit: init,
        view: () => (
            <div class="content-box">
                <div class={`toolbar ${loading ? "disable-buttons" : ""}`}>
                    <div class="toolbar-section with-menu">
                        <h1>{i18n.t("organisation")}</h1>
                        <div class="toolbar-menu">
                            {Auth.validateRights(["org_view"], (
                                <div class={tab === "details" ? "active" : ""} onclick={() => tab = "details"}>
                                    {i18n.t("details")}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {!loading ? (
                        tab === "details" ? <Component_Organisation_Details /> : null
                    ) : (
                        <div class="text-gray padding">
                            {i18n.t("loading")}
                            <div class="loading-dots"/>
                        </div>
                    )
                }
            </div>
        )
    }
}

export default Page_AdminOrganisation;
