import m from "mithril";
import SessionController from "../../shared/session";
import Component_Header from "./header";
import Toasts from "../../shared/toasts";
import Loading from "../../shared/loading";
import i18n from "../../shared/i18n/i18n";
import RouterService from "../../shared/services/router.service";
import ModalManager from "../../shared/modal";
import Modal_Consent_Form from "../../shared/components/modal_consent_form";

function auth() {
    SessionController.checkSession();

    if (!SessionController.session || !SessionController.session.identity) {
        SessionController.getSession().then(function () {
            if (!SessionController.session.identity) {
                RouterService.navigateToPublicLogin();
            }
        });
    }
}

function openConsent() {
    if (SessionController?.session?.identity.consentRequired) {
        ModalManager.open(i18n.t("terms_and_privacy"), ['no-close', 'modal-mid'], Modal_Consent_Form);
    }
}

const PublicLayout = {
    oninit: function (vnode) {
        if (m.route.param("sso-auth")) {
            SessionController.verifySso(m.route.param("sso-auth"))
                .catch(function (error) {
                    Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
                    auth();
                });
        } else {
            auth();
        }
        openConsent();
    },
    view: function (vnode) {
        return [m("main.flex-column", [
                m(Component_Header),
                m("#content", SessionController.session ? vnode.children : m(Loading)),
            ]),
            m('#player-mount'),
            m('#document-mount'),
            m("#toaster")
        ];
    }
};

export default PublicLayout;
