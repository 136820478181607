import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import i18n from "../../shared/i18n/i18n";
import SessionController from "../../shared/session";
import {
    getBackgroundClass,
    getCourseLabelResult,
    getLabelMyClass,
    getLabelResult
} from "../../shared/utilities/status_utilities";
import iconGridSolid from "../../shared/img/icon-grid-solid.svg";
import Loading from "../../shared/loading";
import RouterService from "../../shared/services/router.service";

let loading,
    filter,
    orderBy,
    search,
    myCourses;
function loadCourses() {
    loading = true;
    let params = [];
    if (filter)
        params.push('filter=' + filter);
    if (search)
        params.push('search=' + search);
    if (orderBy)
        params.push('orderBy=' + orderBy);

    Requester.get(Gateway.public + 'courses/my-courses' + (params.length > 0 ? '?' + params.join('&') : ''))
        .then(function (result) {
            myCourses = result.courses;
        })
        .catch(function (error) {
            console.log('error', error);
            Toasts.add('error', i18n.t('error'), i18n.t(error.response?.properties.errorCode));
            myCourses = [];
        })
        .finally(function () {
            loading = false;
        });
}

const PublicComponent_My_Courses = {
    oninit: function () {
        loadCourses();
    },
    view: function () {
        return [
            m('.centered-layout center-this', [
                !loading ? [
                    m('.toolbar courses-toolbar', { class: loading ? 'disable-buttons' : '' }, [
                        m('.has-icon-right search-courses', [
                            m('input[type=search].SecondaryTheme', {
                                oninput: function () {
                                    search = this.value;
                                }, onkeyup: function (e) {
                                    if (e.keyCode === 13) {
                                        loadCourses();
                                    }
                                }, value: search, placeholder: i18n.t('search')
                            }),
                            m('i.icon-x c-hand', {
                                onclick: function () {
                                    search = null;
                                    loadCourses();
                                },
                            }),
                            m('i.icon-search c-hand', {
                                onclick: function () {
                                    loadCourses();
                                }
                            })
                        ]),
                        m('.toolbar-section courses-toolbar-item', [
                            m('.dropdown', { class: SessionController.session && SessionController.session.identity ? '' : 'disabled' },
                                m('button.btn.btn-secondary pl-2 pr-1 ml-2', [
                                    filter ? i18n.t(filter) : [m('i.icon-filter'), i18n.t('all_my_courses')],
                                    m('i.icon-back rotate-270 ml-2')
                                ]),
                                m('.dropdown-list mb-0 pb-0', [
                                    m('div', {
                                        class: !filter ? 'active' : '', onclick: function () {
                                            filter = null;
                                            loadCourses();
                                        }
                                    }, i18n.t('all_my_courses')),
                                    m('div', {
                                        class: filter === 'passed' ? 'active' : '', onclick: function () {
                                            filter = 'passed';
                                            loadCourses();
                                        }
                                    }, i18n.t('passed')),
                                    m('div', {
                                        class: filter === 'in_progress' ? 'active' : '',
                                        onclick: function () {
                                            filter = 'in_progress';
                                            loadCourses();
                                        }
                                    }, i18n.t('in_progress')),
                                    m('div', {
                                        class: filter === 'not_started' ? 'active' : '',
                                        onclick: function () {
                                            filter = 'not_started';
                                            loadCourses();
                                        }
                                    }, i18n.t('not_started')),
                                    m('div', {
                                        class: filter === 'failed' ? 'active' : '', onclick: function () {
                                            filter = 'failed';
                                            loadCourses();
                                        }
                                    }, i18n.t('failed')),
                                ])
                            ),

                        ]),
                        m('.toolbar-section courses-toolbar-item', [
                            m('.dropdown', { class: SessionController.session && SessionController.session.identity ? '' : 'disabled' },
                                m('button.btn.btn-secondary pl-2 pr-1 ml-2', [
                                    orderBy ? i18n.t(orderBy) : i18n.t('courseName'),
                                    m('i.icon-back rotate-270 ml-2')
                                ]),
                                m('.dropdown-list mb-0 pb-0', [
                                    m('div', {
                                        class: !orderBy ? 'active' : '', onclick: function () {
                                            orderBy = null;
                                            loadCourses();
                                        }
                                    }, i18n.t('courseName')),
                                    m('div', {
                                        class: orderBy === 'failed' ? 'active' : '', onclick: function () {
                                            orderBy = 'courseNameDesc';
                                            loadCourses();
                                        }
                                    }, i18n.t('courseNameDesc')),
                                    m('div', {
                                        class: orderBy === 'creationDate' ? 'active' : '',
                                        onclick: function () {
                                            orderBy = 'creationDate';
                                            loadCourses();
                                        }
                                    }, i18n.t('creationDate')),
                                    m('div', {
                                        class: orderBy === 'creationDateDesc' ? 'active' : '',
                                        onclick: function () {
                                            orderBy = 'creationDateDesc';
                                            loadCourses();
                                        }
                                    }, i18n.t('creationDateDesc')),
                                    m('div', {
                                        class: orderBy === 'lastModifiedDate' ? 'active' : '', onclick: function () {
                                            orderBy = 'lastModifiedDate';
                                            loadCourses();
                                        }
                                    }, i18n.t('lastModifiedDate')),
                                    m('div', {
                                        class: orderBy === 'lastModifiedDateDesc' ? 'active' : '', onclick: function () {
                                            orderBy = 'lastModifiedDateDesc';
                                            loadCourses();
                                        }
                                    }, i18n.t('lastModifiedDateDesc'))
                                ])
                            ),

                        ]),
                        m('button.btn.btn-secondary searchButton ml-2 courses-toolbar-item', {
                            onclick: function () {
                                loadCourses();
                            },
                        }, i18n.t('search')),
                    ]),
                    !myCourses || myCourses.length == 0 ? [m('.text-center mt-5', !search && !filter ? [i18n.t('no_my_courses') + ' ', m('strong', i18n.t('select_all_course_tab_name')) ]: i18n.t('no_courses_filter'))] :
                        [
                            myCourses.length === 1 && !search && !filter ? [
                                m(m.route.Link, { href: RouterService.getPublicCourseUrl(myCourses[0].id) }, [
                                    m('.hero', [
                                        m('.hero-content', [
                                            m('h1', myCourses[0].name),
                                            m('.flex-row align-center ', myCourses[0].enrolment ? m('.rounded-pill ml-2', { class: getBackgroundClass(myCourses[0].enrolment) }, getLabelResult(myCourses[0].enrolment)) : '')
                                        ]),
                                        m('.hero-img backslash-polygon', { style: !!myCourses[0].imagePath ? 'background-image: url(\'' + myCourses[0].imagePath + '\')' : '' })
                                    ])
                                ])
                            ] : [
                                m('.mt-1', [
                                    m('.course-box-list', myCourses.map(function (course, index) {
                                        return [
                                            m(m.route.Link, { href: RouterService.getPublicCourseUrl(course.id) },
                                                m('.flex-column', [
                                                    course.imagePath && m('.img relative', { style: !!course.imagePath ? 'background-image: url(\'' + course.imagePath + '\')' : '' }),
                                                    !course.imagePath && m('.defaultCourseImage', [m.trust(iconGridSolid)]),
                                                    m('.padding flex-column flex-auto full-height pb-0', [
                                                        m('.flex-auto full-height', [
                                                            m('.text-bold', course.name)
                                                        ]),
                                                        m('.form-buttons justify-between', [
                                                            course.enrolment ? m('.label-circle', { class: getLabelMyClass(course.enrolment) }, getCourseLabelResult(course.enrolment)) : m('div'),
                                                            m('button.btn.btn-primary', [
                                                                course.enrolment ? i18n.t('openCourse') : i18n.t('courseDetails')
                                                            ]),
                                                        ]),
                                                    ]),
                                                    course.enrolment ? [
                                                        m('.course-progress-bar', m('div', {
                                                            style: 'width: ' + course.enrolment.percentageComplete + '%',
                                                            class: course.enrolment.isCompleted ? (course.enrolment.isPassed ? 'bg-success' : 'bg-error') : course.enrolment.isStarted ? 'bg-warning' : ''
                                                        }))
                                                    ] : m('div', { style: 'height: 5px;' })
                                                ])
                                            )
                                        ];
                                    }))
                                ])
                            ]
                        ]
                ] : m(Loading)
            ])
        ];
    }
};
export default PublicComponent_My_Courses;
