import m from "mithril";
import ModalManager from "../../shared/modal";
import Modal_Form from "./modal_form";
import i18n from "../../shared/i18n/i18n";

let reloadCallback;

function openForm(readOnly, unit, course) {
    let classes = !readOnly ? ["no-close"] : [];
    ModalManager.open(unit.name, classes, Modal_Form, {
        unit: unit,
        enrolmentId: course.enrolment.id,
        readOnly: readOnly,
        callback: reloadCallback
    });
}

const Component_Unit_Actions_Form = {
    oninit: function (vnode) {
        let unit = vnode.attrs.unit;
        let course = vnode.attrs.course;
        let unitNumber = vnode.attrs.unitNumber;
        reloadCallback = vnode.attrs.reloadCallback;
        if (unit && course && unitNumber && unit.id === unitNumber) {
            return unit?.enrolment?.allowAccess
                ? openForm(false, unit, course)
                : openForm(true, unit, course)
        }
    },
    view: function (vnode) {
        let unit = vnode.attrs.unit;
        let course = vnode.attrs.course;
        reloadCallback = vnode.attrs.reloadCallback;
        return unit?.enrolment?.allowAccess ? [
            unit.enrolment.isCompleted && !unit.allowRelaunchIfCompleted ?
            m("button", {
                onclick: function () {
                    openForm(true, unit, course);
                }, class: "btn-cta"
            }, i18n.t('view_form')) :
            m("button", {
                onclick: function () {
                    openForm(false, unit, course);
                }, class: unit.enrolment?.isCompleted ? "" : "btn-cta"
            }, unit.enrolment?.isCompleted ? i18n.t('edit_form') : i18n.t('complete_form'))
        ] : unit.enrolment?.isCompleted && unit.allowRelaunchIfCompleted ? m("button", {
            onclick: function () {
                openForm(true, unit, course);
            }
        }, i18n.t('review')) : "";
    }
};
export default Component_Unit_Actions_Form;
