import m from "mithril";
const {Sortable} = require("sortablejs")
import ModalManager from "../../shared/modal";
import i18n from "../../shared/i18n/i18n";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import Modal_Confirm_Delete from "../../shared/components/modal_confirm_delete";
import Auth from "../../shared/auth";
import UnitUtilities from "../../shared/utilities/unit_utilities";
import {UnitTypes} from "../values";
import Modal_Course_Units_Add from "./modal_course_units_add";
import Modal_Course_Unit_Settings from "./modal_course_unit_settings";
import RouterService from "../../shared/services/router.service";
import UnitService from "../../shared/services/unit.service";

let loading, saving, course, selected = [], courseHasEdits;
let syncChangesStatus = false;
let syncChangesBtnState = true;
let totalEnrolments = 0;

function addUnits() {
    ModalManager.open(i18n.t("select_units"), [], Modal_Course_Units_Add, {courseId: course.properties.id, reloadCallback: applyEdits});
}

function applyEdits() {
    courseHasEdits = true;
    reloadUnits();
}

function reloadUnits() {
    loading = true;
    selected = [];

    Requester.get(Gateway.admin + "units?courseId=" + course.properties.id)
        .then(function (result) {
            course.entities = result.entities;
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        loading = false;
    });
}

function reorderUnit(id, oldIndex, newIndex) {
    Requester.post(Gateway.admin + "courses/" + course.properties.id + "/units/reorder", {unitId: id, index: newIndex})
        .then(function () {
            courseHasEdits = true;
            Toasts.add("success", i18n.t("saved_changes"), "");
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        });
}

function confirmSync() {
    ModalManager.open(i18n.t("sync_course_enrolments"), [], Modal_Confirm_Delete, {
        callback: syncCourse,
        text: [i18n.t("synchronise_added"), m("br"), m("br"), i18n.t("may_take_some_time")],
        btnText: i18n.t("start_sync")
    });
}

function syncCourse() {
    saving = true;
    syncChangesBtnState = false;
    Requester.post(Gateway.admin + "courses/" + course.properties.id + "/sync")
        .then(function (result) {
            courseHasEdits = false;
            syncChangesStatus = result.isSyncChangesComplete;
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .finally(function () {
            saving = false;
        });
}

function editUnitSettings(unit) {
    ModalManager.open(i18n.t("unit_settings"), [], Modal_Course_Unit_Settings, {course: course, unit: unit, callback: reloadUnits});
}

function toggleAllSelected() {
    if (selected.length > 0)
        selected = [];
    else {
        if (course.entities) {
            for (let i = 0; i < course.entities.length; i++) {
                selected.push(course.entities[i].properties.id);
            }
        }
    }
}

function toggleSelected(id) {
    const index = selected.indexOf(id);
    if (index === -1)
        selected.push(id);
    else
        selected.splice(index, 1);
}

function changeUnitStatus(status) {
    Requester.post(Gateway.admin + "courses/" + course.properties.id + "/units/" + (status ? "activate" : "deactivate"), selected)
        .then(function () {
            applyEdits();
            Toasts.add("success", i18n.t("saved_changes"), "");
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        });
}

function confirmRemoveUnit() {
    ModalManager.open(i18n.t("remove_units_from_course"), [], Modal_Confirm_Delete, {
        callback: removeUnits,
        text: "Are you sure you want to remove the selected units from this course?",
        btnText: "Remove"
    });
}

function removeUnits() {
    Requester.delete(Gateway.admin + "courses/" + course.properties.id + "/units", selected)
        .then(function () {
            applyEdits();
            Toasts.add("success", i18n.t("saved_changes"), "");
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
            loading = false;
        });
}

function loadCourseSyncBtnState(courseId){
    Requester.get(Gateway.admin + "courses/" + courseId + "/enrolments")
        .then(function (result) {
            totalEnrolments = result.properties.totalCount;
            totalEnrolments !== 0 ?
                Requester.get(Gateway.admin + "courses/" + courseId + "/course-sync-changes-btn-state")
                    .then(function (result) {
                        syncChangesBtnState = result.isSyncChangesBtnEnabled;
                    })
                    .catch(function (error) {
                        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
                    }) : '';
        })
}

function SyncChangesBtnState(){
    if(syncChangesBtnState){
        return [m("i.icon-reload"), i18n.t("sync_changes")];
    }

    if(syncChangesStatus){
        Toasts.add("success", i18n.t("saved_changes"), i18n.t("enrolments_up_to_date"));
        syncChangesStatus = false;
        syncChangesBtnState = true;
        return [m("i.icon-reload"), i18n.t("sync_changes")];
    }
    else if(!syncChangesStatus && !syncChangesBtnState){
        syncChangesBtnState = false;
        return [m("i.icon-reload"), i18n.t("sync_inProgress")];
    }
}

function applySyncBtnStyle(courseHasEdits, syncChangesStatus, syncChangesBtnState){
    if(courseHasEdits) {
        return "btn-cta btn-error btn-pulse";
    }
    else if(!syncChangesStatus && !syncChangesBtnState) {
        return "disabled";
    }
    else{
        return "";
    }
}

const Component_Course_Units = {
    oninit: function (vnode) {
        loadCourseSyncBtnState(vnode.attrs.course.properties.id);
        course = vnode.attrs.course;
        if (!course.entities)
            course.entities = [];
    },
    view: function () {
        return [
            m(".content-box", {class: saving ? "disabled" : ""}, [
                !loading ? [
                    m(".toolbar", [
                        m(".toolbar-section", [
                            Auth.validateRights(["courses_edit_content"],
                                m(".dropdown", {class: selected.length === 0 ? "disabled" : ""},
                                    m("button.with-icon pl-2", [
                                        m("i.icon-visible"),
                                        i18n.t("change_visibility"),
                                        m("i.icon-back rotate-270 ml-2")
                                    ]),
                                    m(".dropdown-list", [
                                        m("div", {
                                            onclick: function () {
                                                changeUnitStatus(true);
                                            }
                                        },  i18n.t("visible")),
                                        m("div", {
                                            onclick: function () {
                                                changeUnitStatus(false);
                                            }
                                        }, i18n.t("hidden"))
                                    ])
                                )
                            ),
                            Auth.validateRights(["courses_edit_content"], m("button.with-icon", {
                                onclick: confirmRemoveUnit,
                                class: selected.length === 0 ? "disabled" : ""
                            }, [m("i.icon-delete"), i18n.t("remove_from_course")])),
                            selected && selected.length > 0 ? m("span.ml-1", [selected.length, " selected"]) : ""
                        ]),
                        m(".toolbar-section", [
                            Auth.validateRights(["courses_add_unit"], m("button.with-icon btn-cta", {onclick: addUnits}, [m("i.icon-copy"), i18n.t("add_units")])),
                            Auth.validateRights(["courses_sync"], m("button#sync-btn.with-icon", {
                                onclick: confirmSync,
                                class: applySyncBtnStyle(courseHasEdits, syncChangesStatus, syncChangesBtnState)
                                }, SyncChangesBtnState()))
                        ])
                    ]),
                    course.entities && course.entities.length > 0 ? [
                        m(".table-wrapper", [
                            m("table.full-width.mb-50", {class: Auth.validateRights(["courses_edit_content"]) ? "sortable-table" : ""}, [
                                m("thead",
                                    m("tr", [
                                        m("th", m(".toolbar-checkbox", {onclick: toggleAllSelected}, m("i", {class: selected.length > 0 ? "icon-checkbox-checked" : "icon-checkbox-empty"}))),
                                        m("th", i18n.t("name")),
                                        m("th", i18n.t("type")),
                                        m("th", i18n.t("visible")),
                                        m("th", i18n.t("actions")),
                                        Auth.validateRights(["courses_edit_content"], m("th", "")),
                                    ])
                                ),
                                m("tbody", {
                                    oncreate: Auth.validateRights(["courses_edit_content"], function (v) {
                                        Sortable.create(v.dom, {
                                            draggable: "tr",
                                            handle: ".icon-arrange",
                                            onEnd: function (e) {
                                                if (e.oldIndex !== e.newIndex) {
                                                    reorderUnit(e.item.getAttribute("data-id"), e.oldIndex, e.newIndex);
                                                }
                                            }
                                        });
                                    })
                                }, course.entities.map(function (unit) {
                                    return m("tr", {"data-id": unit.properties.id}, [
                                        m("td.list-checkbox c-hand pl-1 pr-1", {
                                            onclick: function () {
                                                toggleSelected(unit.properties.id);
                                            }, style: "width: 18px;"
                                        }, m("i", {class: selected.indexOf(unit.properties.id) !== -1 ? "icon-checkbox-checked" : "icon-checkbox-empty"})),
                                        m("td.text-bolder", unit.properties.name),
                                        m("td.with-icon", [
                                            m("i", {class: UnitUtilities.getUnitTypeIcon(unit.properties.type)}),
                                            unit.properties.type
                                        ]),
                                        m("td", unit.properties.isActive && unit.properties.linkIsActive ? m("i.icon-check") : !unit.properties.isActive ? m(".text-gray", i18n.t("unit_inactive")) : ""),
                                        m("td", [
                                            unit.properties.type === UnitTypes.event ?  m('.dropdown', { class: ''},
                                                m('button.btn pl-1 pr-1 flex-display',{ class: ''} ,[
                                                    m('.btn-text', i18n.t("actions")),
                                                    m('i.icon-back rotate-270')
                                                ]),
                                                m('.dropdown-list mb-0 pb-0',
                                                    Auth.validateRights([UnitService.getEditUnitRight(unit)],  m('div.btn-text', {
                                                        class: '',
                                                        onclick: function () {
                                                            RouterService.navigateToUnit(unit.properties.id);
                                                        }
                                                    }, [m('div', {class: ""}, i18n.t("edit_unit"))])),
                                                    Auth.validateRights([UnitService.getEditUnitRight(unit)],  m('div.btn-text', {
                                                        class: '',
                                                        onclick: function () {
                                                            RouterService.navigateToUnit(unit.properties.id);
                                                        }
                                                    }, [m('div', {class: ""}, i18n.t("view_sessions"))])),

                                                )
                                            ):   Auth.validateRights([UnitService.getEditUnitRight(unit)], m("button.btn-text", {
                                                onclick: function () {
                                                    RouterService.navigateToUnit(unit.properties.id);
                                                }
                                            }, i18n.t("edit_unit"))),
                                            unit.properties.type === UnitTypes.scorm || unit.properties.type === UnitTypes.form ? Auth.validateRights(["courses_edit_content"], m("button.btn-text", {
                                                onclick: function () {
                                                    editUnitSettings(unit);
                                                }
                                            }, i18n.t("options"))) : ""
                                        ]),
                                        Auth.validateRights(["courses_edit_content"],
                                        m("td", m("i.icon-arrange")))
                                    ])
                                }))
                            ])
                        ])
                    ] : m(".text-gray padding", i18n.t("no_units"))
                ] : m(".text-gray padding", [i18n.t("loading"), m(".loading-dots")])
            ])
        ];
    }
};
export default Component_Course_Units;
