import m from "mithril";
import Component_Unit_Download_Settings from "./component_unit_download_settings";
import i18n from "../../../shared/i18n/i18n";

let loading = false;
let tab = "settings";
let unit = null;

const Page_Unit_Download = {
    oninit: function (vnode) {
        unit = vnode.attrs.unit;
    },
    view: function () {
        return [
            m(".content-box", [
                m(".toolbar", {class: loading ? "disable-buttons" : ""}, [
                    m(".toolbar-section with-menu", [
                        m("h1", i18n.t("download_unit")),
                        m(".toolbar-menu", [
                            m("div", {
                                class: tab === "settings" ? "active" : "", onclick: function () {
                                    tab = "settings";
                                }
                            }, i18n.t("settings"))
                        ])
                    ])
                ])
            ]),
            !loading ? [
                unit ? [
                    tab === "settings" ? m(Component_Unit_Download_Settings, {unit: unit.properties})
                        : ""
                ] : m(".text-gray padding", i18n.t("unable_to_load_unit"))
            ] : m(".text-gray padding", [i18n.t("loading"), m(".loading-dots")])
        ];
    }
};
export default Page_Unit_Download;
