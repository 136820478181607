import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import i18n from "../../shared/i18n/i18n";
import FormUtilities from "../../shared/utilities/form_utilities";
import ModalManager from "../../shared/modal";
import Auth from "../../shared/auth";

let loading, saving, config, modalId, callback, hasWriteRights;

function loadSso() {
    loading = true;
    Requester.get(Gateway.admin + "account/sso/" + config.id, config)
        .then(function (result) {
            config = result;
            loading = false;
        })
        .catch(function () {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        });
}

function saveSso() {
    if (!config.type) {
        Toasts.add("error", "", i18n.t("please_specify_sso_type"));
    } else {
        if (FormUtilities.validateFields("input")) {
            saving = true;
            Requester.post(Gateway.admin + "account/sso-" + config.type + (config.id ? ("/" + config.id) : ""), config)
                .then(function (result) {
                    if (callback) {
                        callback();
                    }
                    ModalManager.close(modalId);
                    Toasts.add("success", i18n.t("sso_configuration_saved"), "");
                })
                .catch(function (error) {
                    saving = false;
                    Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
                });
        }
    }
}


const Modal_SSO_Edit = {
    oninit: function (vnode) {
        config = vnode.attrs.config || {};
        hasWriteRights = Auth.validateRights([ Auth.rights.account_single_sign_on_configuration_create, Auth.rights.account_single_sign_on_configuration_update], true);
        if (config.id) {
            loadSso();
        }

        callback = vnode.attrs.callback;
    },
    view: function () {
        return [
            !loading ? [
                m(".form-section required", { class: hasWriteRights ? "" : "disabled" },
                    [
                    m(".form-label", i18n.t("name")),
                    m("input[type=text]", {
                        oninput: function () {
                            config.name = this.value;
                        }, value: config.name
                    })
                ]),
                !config.id ? [
                    m(".form-section required", { class: hasWriteRights ? "" : "disabled" },
                    [
                        m(".form-label", i18n.t("type")),
                        m(".dropdown required",
                            m("button.with-icon pl-2", [
                                config.type === "cas" ? "CAS" : config.type === "jwt" ? "JWT" : i18n.t("select_sso_type"),
                                m("i.icon-back rotate-270 ml-2")
                            ]),
                            m(".dropdown-list", [
                                m("div", {
                                    class: config.type === "cas" ? "active" : "", onclick: function () {
                                        config.type = "cas";
                                    }
                                }, "CAS")
                            ])
                        )
                    ])
                ] : "",
                config.type === "cas" ? [
                    m(".form-section required", { class: hasWriteRights ? "" : "disabled" },
                        [
                        m(".form-label", i18n.t("cas_server_url")),
                        m("input[type=text]", {
                            oninput: function () {
                                config.serverUrl = this.value;
                            }, value: config.serverUrl
                        })
                    ]),
                    m(".form-section required", { class: hasWriteRights ? "" : "disabled" },
                        [
                        m(".form-label", i18n.t("identifier")),
                        m(".dropdown required",
                            m("button.with-icon pl-2", [
                                config.identifier === "userId" ? i18n.t("user_id") : config.identifier === "username" ? i18n.t("username") : config.identifier === "email" ? i18n.t("email") : i18n.t("select_identifier"),
                                m("i.icon-back rotate-270 ml-2")
                            ]),
                            m(".dropdown-list", [
                                m("div", {
                                    class: config.identifier === "userId" ? "active" : "", onclick: function () {
                                        config.identifier = "userId";
                                    }
                                }, i18n.t("user_id")),
                                m("div", {
                                    class: config.identifier === "username" ? "active" : "", onclick: function () {
                                        config.identifier = "username";
                                    }
                                }, i18n.t("username")),
                                m("div", {
                                    class: config.identifier === "email" ? "active" : "", onclick: function () {
                                        config.identifier = "email";
                                    }
                                }, i18n.t("email"))
                            ])
                        )
                    ]),
                    config.code ? [
                        m(".form-section required", { class: hasWriteRights ? "" : "disabled" },
                        [
                            m(".form-label", i18n.t("service_url")),
                            m("input[readonly].form-input", {value: ("/login/" + config.type + "/" + config.code)})
                        ])
                    ] : ""
                ] : config.type === "jwt" ? [
                        m(".form-section required", { class: hasWriteRights ? "" : "disabled" },
                            [
                        m(".form-label", i18n.t("login_url")),
                        m("input[type=text]", {
                            oninput: function () {
                                config.loginUrl = this.value;
                            }, value: config.loginUrl
                        })
                    ]),
                        m(".form-section required", { class: hasWriteRights ? "" : "disabled" },
                            [
                        m(".form-label", i18n.t("logout_url")),
                        m("input[type=text]", {
                            oninput: function () {
                                config.logoutUrl = this.value;
                            }, value: config.logoutUrl
                        })
                    ])
                ] : "",
                    [
                        m("hr"),
                        m(".form-buttons", [
                            !saving ? m("button", { class: hasWriteRights ? "" : "disabled", onclick: saveSso }, i18n.t("save")) : m("button.btn-text no-click", [i18n.t("saving"), m(".loading-dots")])
                        ])
                    ]
            ] : m(".text-gray", [i18n.t("loading"), m(".loading-dots")])
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
};
export default Modal_SSO_Edit;
