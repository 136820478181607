import m from "mithril";
import Requester from '../../shared/request';
import Toasts from '../../shared/toasts';
import Gateway from '../../shared/app_settings';
import i18n from "../../shared/i18n/i18n";
import ModalManager from "../../shared/modal";
import Modal_Confirm_Delete from "../../shared/components/modal_confirm_delete";
import Auth from "../../shared/auth";
import DateUtilities from "../../shared/utilities/date_utilities";
import Modal_User_Change_Orgs from "./modal_user_change_orgs";
import RouterService from "../../shared/services/router.service";
import RoleService from "../../shared/services/role.service";

let loading = true;
let currentPage = "users";
let user;

function loadUser(id) {
    loading = true;
    Requester.get(Gateway.admin + "account/users/" + (id || user.id))
        .then(function (result) {
            user = result;
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        loading = false;
    });
}

function resendInvitation() {
    Requester.post(Gateway.admin + "account/users/" + user.id + "/resend-invitation")
        .then(function (response) {
            if (response && response.Errors)
                Toasts.add("error", i18n.t("some-errors"), response.Errors.join(", "));
            else
                Toasts.add("success", i18n.t("invitation_sent"), "");
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        });
}

function confirmRemoveUser() {
    ModalManager.open(i18n.t("remove_user_from_account"), [], Modal_Confirm_Delete, {
        text: i18n.t("want_remove_user_from_account"),
        btnText: i18n.t("remove_user"),
        callback: removeUser
    });
}

function removeUser() {
    Requester.post(Gateway.admin + "account/users/" + user.id + "/remove")
        .then(function (response) {
            Toasts.add("success", i18n.t("user_removed"), "");
            RouterService.navigateToAccountUsers();
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("unable_to_remove_user"), error.properties.errors);
        });
}

function changeOrgAccess() {
    ModalManager.open(i18n.t("organisation_access"), ["modal-mid"], Modal_User_Change_Orgs, {userIds: [user.id], callback: loadUser});
}

function getOrganisations(user) {
    if (RoleService.isInternalRole(user.role)) {
        return i18n.t(user.role);
    }

    if (!user.organisations) {
        return null;
    }

    return user.organisations.map(organisation => organisation.name).join(", ");
}

const Page_User = {
    oninit: function (vnode) {
        loadUser(vnode.attrs.id);
        currentPage = "users";
    },
    view: function () {
        return [
            m(".content-box", [
                m(".toolbar", {class: loading ? "disable-buttons" : ""}, [
                    m(".toolbar-section", [
                        m("h1", i18n.t("user"))
                    ]),
                    m(".toolbar-section", [
                        user && user.hasAccountMembership ? [
                            user.pending ? Auth.validateRights(["users_create"], m("button.with-icon btn-cta", {onclick: resendInvitation}, [m("i.icon-mail"), i18n.t("resend_invitation")])) : "",
                            // TODO - Disabled until backend returns organisation role data
                            // m("button.with-icon", {onclick: changeOrgAccess}, [m("i.icon-user-id"), i18n.t("organisation_access")]),
                            Auth.validateRights(["account_user_remove"], m("button.with-icon", {onclick: confirmRemoveUser}, [m("i.icon-delete"), i18n.t("remove_user")]))
                        ] : ""
                    ])
                ])
            ]),
            m(".content-box details-list", [
                m("h3", i18n.t("details")),
                !loading ?
                    user ? [
                        user.pending ?
                            m(".form-section", [
                                m(".text-error", i18n.t("pending_invitation"))
                            ])
                            : "",
                        m(".form-section", [
                            m(".text-label", i18n.t("name")),
                            user.firstName ? (user.firstName + " " + user.lastName) : m(".text-gray", i18n.t("invited"))
                        ]),
                        m(".form-section", [
                            m(".text-label", i18n.t("email")),
                            user.email
                        ]),
                        m(".form-section", [
                            m(".text-label", i18n.t("username")),
                            user.username
                        ]),
                        m(".form-section", [
                            m(".text-label", i18n.t("organisations")),
                            getOrganisations(user)
                        ]),
                        user.role !== RoleService.roles.none ?
                        m(".form-section", [
                            m(".text-label", i18n.t("account_role")),
                            i18n.t(user.role)
                        ]) : "",
                        m(".form-section", [
                            m(".text-label", i18n.t("last_login")),
                            DateUtilities.getFormattedDate(user.lastActive)
                        ])
                    ] : m(".text-gray padding", i18n.t("unable_to_load_users"))
                    : m(".text-gray padding", [i18n.t("loading"), m(".loading-dots")])
            ])
        ];
    }
};

export default Page_User;
