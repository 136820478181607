import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import i18n from "../../shared/i18n/i18n";
import RouterService from "../../shared/services/router.service";

/*
Fetches the SSO Login links from the Organisations configuration.
If we want to redirect after SSO has verified the ticket we can
append the url path to the end. This allows /public (default)
and /account redirect.
 */
function getSsoLinks(path) {
    Requester.get(Gateway.identity + "sso")
        .then(function (result) {
            let ssoLinks = result;
            window.location.href = ssoLinks[0].url + (!!path ? path : "");
        })
        .catch(function (error) {
            // If SSO is not configured for the organisation we (for now) will redirect to regular login page
            RouterService.navigateToPublicLogin();
        })
        .finally(function () {
        });
}

const Redirect_PegGateway_LoginPage = {
    oninit: function (vnode) {
        document.documentElement.setAttribute("spud-theme", "light");
        // Redirect to the Pegasus Gateway login page
        getSsoLinks(vnode.attrs.path);
    },
    view: function (vnode) {
        return m("p", i18n.t("loading") + "...")
    }
};

export default Redirect_PegGateway_LoginPage;
