import m from "mithril";
import Toasts from "../../shared/toasts";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import i18n from "../../shared/i18n/i18n";
import Auth from "../../shared/auth";
import Colour_Picker from "../../shared/components/component_colour_picker";
let loading, saving, uploadingLogo, uploadingBg, org;

function getDetails() {
    loading = true;
    Requester.get(Gateway.admin + "organisation/details")
        .then(function (result) {
            org = result;
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        loading = false;
    });
}

function setColour(c, t) {
    org.properties.primaryColour = c;
    org.properties.lightText = t;
}

function saveDetails() {
    if (org.properties.primaryColour && !new RegExp("^#?(([0-9a-fA-F]{2}){3}|([0-9a-fA-F]){3})$").test(org.properties.primaryColour)) {
        Toasts.add("error", i18n.t("cant_save"), i18n.t("invalid_colour_cbde50"));
    } else {
        Requester.post(Gateway.admin + "organisation/details", org.properties)
            .then(function () {
                if (document.getElementById("org-logo-file-input").files[0])
                    uploadLogo();

                if (document.getElementById("org-bg-file-input").files[0])
                    uploadBg();

                Toasts.add("success", i18n.t("saved_changes"));
            })
            .catch(function (error) {
                Toasts.add("error", i18n.t("cant_save"), error.response.properties.detail);
            });
    }
}

function uploadLogo() {
    let d = new FormData();
    let file = document.getElementById("org-logo-file-input").files[0];

    if (file) {
        if (file.size / 2048 / 2048 > 1)
            Toasts.add("error", i18n.t("file_too_big"), i18n.t("file_less_than") + "1mb");

        uploadingLogo = true;
        d.append("file", file, file.name);
        Requester.upload(Gateway.admin + "organisation/upload-logo", d, ".bar-1")
            .then(function () {
                Toasts.add("success", i18n.t("saved_changes"), i18n.t("logo_uploaded"));
            })
            .catch(function (error) {
                Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
            })
            .finally(function () {
                uploadingLogo = false;
            });
    }
}

function uploadBg() {
    const d = new FormData();
    const file = document.getElementById("org-bg-file-input").files[0];

    if (file) {
        if (file.size / 2048 / 2048 > 2)
            Toasts.add("error", i18n.t("file_too_big"), i18n.t("file_less_than") + "2mb");

        uploadingBg = true;
        d.append("file", file, file.name);
        Requester.upload(Gateway.admin + "organisation/upload-background", d, ".bar-2")
            .then(function (filename) {
                Toasts.add("success", i18n.t("saved_changes"), i18n.t("background_uploaded"));
            })
            .catch(function (error) {
                Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
            })
            .finally(function () {
                uploadingBg = false;
            });
    }
}

const Component_Organisation_Details = {
    oninit: function (vnode) {
        getDetails();
    },
    view: function () {
        return [
            m(".content-box", [
                org ? [
                    m(".form", {class: !Auth.validateRights(["org_edit"]) ? "disabled" : ""}, [
                        m(".form-section required", [
                            m(".form-label", i18n.t("name")),
                            m("input[type=text]", {
                                oninput: function () {
                                    org.properties.name = this.value;
                                }, value: org.properties.name
                            })
                        ]),
                        m(".form-section required", [
                            m(".form-label", i18n.t("onsite_scope_id")),
                            m("input[type=number]", { oninput: function () {  org.properties.scopeId = this.value; }, value:  org.properties.scopeId })
                        ]),
                        m(".form-section", [
                            m(".form-label", i18n.t("hostname")),
                            m("input[type=text].disabled", {value: org.properties.hostname})
                        ]),
                        m(".form-section", [
                            m(".form-label", i18n.t("about")),
                            m("textarea", {
                                oninput: function () {
                                    org.properties.about = this.value;
                                }, value: org.properties.about
                            })
                        ]),
                        m(".form-section", [
                            m(".form-label", i18n.t("contact_email")),
                            m("input[type=text]", {
                                oninput: function () {
                                    org.properties.contactEmail = this.value;
                                }, value: org.properties.contactEmail
                            })
                        ]),
                        m(".form-section", [
                            m(".form-label", i18n.t("primary_colour")),
                            m(Colour_Picker, {
                                colour: org.properties.primaryColour,
                                lightText: org.properties.lightText,
                                callback: setColour
                            })
                        ]),
                        m(".form-section", [
                            m(".form-label inline-tip", {"data-tip": "300x100px 1MB max"}, i18n.t("logo")),
                            m("input[type=file].form-input#org-logo-file-input"),
                            uploadingLogo ? [
                                m(".loading-bar mt-1", m(".loading-width bar-1"))
                            ] : ""
                        ]),
                        m(".form-section", [
                            m(".form-label inline-tip", {"data-tip": "1920x1200px 2MB max"}, i18n.t("background_image")),
                            m("input[type=file].form-input#org-bg-file-input"),
                            uploadingBg ? [
                                m(".loading-bar mt-1", m(".loading-width bar-2"))
                            ] : ""
                        ]),
                        Auth.validateRights(["org_edit"], m(".form-buttons", [
                            !saving ? m("button", {onclick: saveDetails}, i18n.t("save")) : m("button.btn-text no-click", [i18n.t("saving"), m(".loading-dots")])
                        ]))
                    ])
                ] : m(".text-gray padding", [i18n.t("loading"), m(".loading-dots")])
            ])
        ];
    }
};
export default Component_Organisation_Details;
