import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import i18n from "../../shared/i18n/i18n";
import Toasts from "../../shared/toasts";
import Auth from "../../shared/auth";
import ModalManager from "../../shared/modal";

let course, unit, modalId;

function toggleAllowRelaunch(el) {
    Requester.post(Gateway.admin + "courses/" + course.properties.id + "/units/" + unit.properties.id + "/toggle-allow-relaunch")
        .then(function () {
            unit.properties.allowRelaunchIfCompleted = !unit.properties.allowRelaunchIfCompleted;
            Toasts.add("success", i18n.t("saved_changes"), "");
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        });
}

function toggleAllowRetry(el) {
    Requester.post(Gateway.admin + "courses/" + course.properties.id + "/units/" + unit.properties.id + "/toggle-allow-retry")
        .then(function () {
            unit.properties.allowRetryIfFailed = !unit.properties.allowRetryIfFailed;
            Toasts.add("success", i18n.t("saved_changes"), "");
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        });
}

function getToggleAllowRelaunchClass() {
    return Auth.validateRights(["courses_edit_content"]) && unit.properties.isActive
        ? ""
        : "disabled";
}

function getToggleAllowRetryClass() {
    return Auth.validateRights(["courses_edit_content"]) && unit.properties.type.toLowerCase() != "form" && unit.properties.allowRelaunchIfCompleted
        ? ""
        : "disabled";
}

const Modal_Course_Unit_Settings = {
    oninit: function (vnode) {
        course = vnode.attrs.course;
        unit = vnode.attrs.unit;
    },
    view: function () {
        return [
            m(".form bordered-sections", {class: getToggleAllowRelaunchClass()}, [
                m(".form-section flex-row justify-between", [
                    m(".mr-2", [
                        m(".text-bolder", unit.properties.type.toLowerCase() === "form" ? i18n.t("allow_resubmission") : i18n.t("allow_relaunch")),
                        m("div", unit.properties.type.toLowerCase() === "form" ? i18n.t("allow_resubmission_description") : i18n.t("allow_relaunch_description"))
                    ]),
                    m(".btn btn-toggle", {
                        onclick: toggleAllowRelaunch,
                        class: unit.properties.allowRelaunchIfCompleted ? "active" : ""
                    })
                ]),
                m(".form-section flex-row justify-between", {class: getToggleAllowRetryClass()}, [
                    m(".mr-2", [
                        m(".text-bolder", i18n.t("allow_retry")),
                        m("div", i18n.t("allow_the_user_to_retry_this_module_if_its_been_failed_i_e_relaunching_will_reset_the_status"))
                    ]),
                    m(".btn btn-toggle", {
                        onclick: toggleAllowRetry,
                        class: unit.properties.allowRetryIfFailed ? "active" : ""
                    })
                ])
            ]),
            m(".form-buttons", [
                m("button", {
                    onclick: function () {
                        ModalManager.close(modalId);
                    }
                }, i18n.t("close"))
            ])
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
};
export default Modal_Course_Unit_Settings;
