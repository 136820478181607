import m from "mithril";
import ModalManager from "../../shared/modal";
import i18n from "../../shared/i18n/i18n";
import { changeEnrolmentStatusPrompt } from '../../shared/utilities/confirmStatusChange_utilities';
import { Datepicker } from 'vanillajs-datepicker';
import DateUtilities from '../../shared/utilities/date_utilities';
import iconCalendar from "../../shared/img/icon-calendar.svg"

let modalId, confirmCallback, enrolments, status, dateFormat, selectedDate;

function close() {
    ModalManager.close(modalId);
}
function formatDate(date) {
    return date.replace(/(\d{4})-(\d{2})-(\d{2}).*/, '$3/$2/$1');
}
function confirmOnClick() {
    close();
    changeEnrolmentStatusPrompt(confirmCallback, status, formatDate(selectedDate));
}

const Modal_Edit_Enrolment = {
    oninit: function (vnode) {
        confirmCallback = vnode.attrs.confirmCallback;
        enrolments = vnode.attrs.enrolments;
        status = vnode.attrs.status;
        dateFormat = DateUtilities.getLocaleDateString(i18n.locale).toLowerCase();
        selectedDate = Datepicker.formatDate(new Date(), dateFormat, i18n.locale);
    },
    view: function () {
        return [
            // m('h2.text-center', i18n.t('edit_enrolment')),
            m('p.text-center', i18n.t('course') + ": " +
                (enrolments ? enrolments.map(e => e.properties.courseName).join(', ') : '')),
            m(".form-section required", [
                m(".form-label", i18n.t("effective_date"), ),
                m('#datepicker-container .datepicker-container', [
                    m('input#datepicker[type=text] .datepicker-input .calendar', {
                        oncreate: v => {
                            new Datepicker(v.dom, {
                                language: i18n.locale,
                                format: dateFormat,
                                updateOnBlur: true,
                                container: '.modal'
                            });
                        },
                        onblur: function () {
                            selectedDate = this.value;
                        },
                        value: selectedDate
                    }),
                    m('svg.calendar-icon', { style: 'width: 2rem;' }, [
                        m.trust(iconCalendar)
                    ])
                ])
            ]),
            m('.form-buttons flex-right mt-1', [
                m("button.btn-save", {onclick: confirmOnClick}, i18n.t("save_changes")),
                m('button.btn-cancel', {onclick: () => close()}, i18n.t('cancel'))
            ])
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
};
export default Modal_Edit_Enrolment;
