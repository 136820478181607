import m from "mithril";
import i18n from "../i18n/i18n";
import ModalManager from '../modal';
import Modal_Confirm from '../components/modal_confirm';

export function changeEnrolmentStatusPrompt(confirmCallback, status, effectiveDate) {
    const statusKey = status ?? 'not_started';
    const statusText = i18n.t(statusKey);
    const consequenceKey = `change_status_to_${statusKey}_consequence`;
    const consequenceText = i18n.t(consequenceKey);
    const statusChangeOrResetMessageKey = status === 'passed' ? 'change_status_confirm' : 'reset_status_confirm';
    const attributes = {
        confirmCallback: () => confirmCallback(status, effectiveDate),
        cancelCallback: null,
        messageText: [
            i18n.t(statusChangeOrResetMessageKey, { 'status': statusText.toUpperCase() }),
            m('br'), m('br'),
            consequenceText === consequenceKey || consequenceText],
        confirmButtonLabel: i18n.t('yes'),
        cancelButtonLabel: i18n.t('no'),
        confirmButtonClasses: 'button.btn-yes',
        cancelButtonClasses: 'button.btn-no'
    };
    ModalManager.open(i18n.t('change_status_confirmation'), [], Modal_Confirm, attributes);
}

