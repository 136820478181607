export const UnitTypes = {
    scorm: "Scorm",
    form: "Form",
    event: "Event",
    doc: "Document",
    cert: "Certificate",
    action: "Action",
    approval: "Approval",
    download: "Download",
    upload: "Upload"
};

export const getUnitTypesi18nText = (unitType) => {
    switch (unitType) {
        case UnitTypes.scorm:
            return "interactive";
        case UnitTypes.form:
            return "form";
        case UnitTypes.event:
            return "face_to_face";
        case UnitTypes.doc:
            return "document";
        case UnitTypes.cert:
            return "certificate";
        case UnitTypes.action:
            return "action";
        case UnitTypes.approval:
            return "approval";
        case UnitTypes.download:
            return "download";
        case UnitTypes.upload:
            return "upload";
        default:
            return "all_units";
    }
};

export const EmailTemplateTypes = {
    System_Verification: "Verification",
    System_Reset_Pwd: "Reset Password",
    System_New_Enrolments: "New Enrolments",
    System_Org_Invitation: "Invitation"
};

export const ACTION_TYPES = {
    sendEmail: "SendEmail"
};

export const EmailPlaceholders = {
    CONTENT_PLACEHOLDER: "{{{@content}}}",
    USERNAME_PLACEHOLDER: "{{{@user-name}}}",
    COURSENAME_PLACEHOLDER: "{{{@course-name}}}",
    UNITNAME_PLACEHOLDER: "{{{@unit-name}}}",
    TENANT_PLACEHOLDER: "{{{@tenant-name}}}",
    URL_PLACEHOLDER: "{{{@action-url}}}",
    CODE_PLACEHOLDER: "{{{@code}}}"
};
