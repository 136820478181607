import m from "mithril";
import i18n from "../i18n/i18n";

let callback, offset, limit, total, start, end;

function submit() {
    callback(limit, offset);
}

const Component_Table_Footer = {
    oninit: function (vnode) {
        callback = vnode.attrs.callback;
        offset = vnode.attrs.offset;
        limit = vnode.attrs.limit;
        total = vnode.attrs.total;
        start = limit * offset + 1;
        end = (total > (limit * offset + limit) ? limit * offset + limit : total);
    },
    view: function (vnode) {
        return [
            m(".toolbar table-footer", {class: vnode.attrs.loading ? "disable-buttons" : ""}, [
                m(".toolbar-section",
                    !total || total === 0 ? ""
                        : total < limit ? [total, " ", i18n.t(vnode.attrs.item)]
                            : [i18n.t('paging_count_' + vnode.attrs.item, { 'start': start, end, total})]
                ),
                m(".toolbar-section", [
                    total > 25 ? [
                        m(".dropdown dropup mr-1",
                            m("button.with-icon pl-2", {style: "min-width: auto;"}, [
                                limit,
                                m("i.icon-back rotate-270 ml-2")
                            ]),
                            m(".dropdown-list", [
                                m("div", {
                                    class: limit === 100 ? "active" : "", onclick: function () {
                                        offset = 0;
                                        limit = 100;
                                        submit();
                                    }
                                }, i18n.t("100")),
                                m("div", {
                                    class: limit === 50 ? "active" : "", onclick: function () {
                                        offset = 0;
                                        limit = 50;
                                        submit();
                                    }
                                }, i18n.t("50")),
                                m("div", {
                                    class: limit === 25 ? "active" : "", onclick: function () {
                                        offset = 0;
                                        limit = 25;
                                        submit();
                                    }
                                }, i18n.t("25"))
                            ])
                        ),
                        i18n.t("items_per_page")
                    ] : ""
                ]),
                m(".toolbar-section", [
                    total > limit ? [
                        offset > 0 ? m("button.with-icon", {
                            onclick: function () {
                                offset--;
                                submit();
                            }
                        }, [m("i.icon-back"), i18n.t("previous")]) : "",
                        total > limit * offset + limit ? m("button.with-icon icon-right", {
                            onclick: function () {
                                offset++;
                                submit();
                            }
                        }, [i18n.t("next"), m("i.icon-forward")]) : ""
                    ] : ""
                ])
            ])
        ];
    }
};

export default Component_Table_Footer;
