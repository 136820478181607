import i18n from "../../shared/i18n/i18n";

import m from "mithril";
import Requester from '../../shared/request';
import Gateway from '../../shared/app_settings';
import ModalManager from '../../shared/modal';
import Toasts from '../../shared/toasts';
import FormUtilities from '../../shared/utilities/form_utilities';

let saving = false;
let org = {
    name: "",
    scopeId:"",
    subdomain: ""
};
let modalId;

function saveOrg() {
    if (FormUtilities.validateFields("input")) {
        saving = true;
        Requester.post(Gateway.admin + "account/organisations", org)
            .then(function (result) {
                Modal_Organisation_New.callback();
                ModalManager.close(modalId);
                Toasts.add("success", i18n.t("organisation_created"), "");
            })
            .catch(function (error) {
                saving = false;
                Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
            });
    }
}

let Modal_Organisation_New = {
    callback: "",
    oninit: function (vnode) {
        Modal_Organisation_New.callback = vnode.attrs.callback;
    },
    view: function () {
        return [
            m(".form-section required", [
                m(".form-label", i18n.t("name")),
                m("input[type=text]", { oninput: function () { org.name = this.value; }, value: org.name })
            ]),
            m(".form-section required", [
                m(".form-label", i18n.t("onsite_scope_id")),
                m("input[type=number]", { oninput: function () { org.scopeId = this.value; }, value: org.scopeId })
            ]),
            m(".form-section required", [
                m(".form-label", i18n.t("subdomain")),
                m("input[type=text]", {
                    oninput: function () { org.subdomain = this.value.toLowerCase().replace(/\s+/g, ''); },
                    value: org.subdomain,
                    placeholder: org.name ? "e.g. " + org.name.toLowerCase().replace(/\s+/g, '') : ""
                })
            ]),
            m(".form-buttons", [
                !saving ? m("button", { onclick: saveOrg }, i18n.t("save")) : m("button.btn-text no-click", [i18n.t("saving"), m(".loading-dots")])
            ])
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
}

export default Modal_Organisation_New;
