import SessionController from "../../shared/session";
import m from "mithril";
import OrgFileService from "../../shared/storage/org_file_service";
import i18n from "../../shared/i18n/i18n";

let loading = false;

function setBg() {
    if (SessionController.session.environment && SessionController.session.environment.backgroundName)
        document.getElementById("auth-right").style.backgroundImage = "url('" + OrgFileService.getBackgroundPath() + "')";
}

const PublicLayout_Auth = {
    oninit: function () {
        document.documentElement.setAttribute("spud-theme", "light");

        SessionController.checkSession();

        if (!SessionController.session) {
            loading = true;
            SessionController.getSession()
                .finally(function () {
                    loading = false;
                    m.redraw();
                });
        }
    },
    view: function (vnode) {
        return [!loading || SessionController.session ?
                m("#page-wrapper", [
                    m("#page", [
                        m("main.flex-row", [
                            m("#auth-left", [
                                vnode.children
                            ]),
                            m("#auth-right", {oncreate: setBg})
                        ])
                    ])
                ])
                : m("div", i18n.t("loading_dots")),
                m("#toaster")
            ];
    }
}
export default PublicLayout_Auth
