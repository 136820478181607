import m from "mithril";
import FormUtilities from "../../shared/utilities/form_utilities";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import SessionController from "../../shared/session";
import Toasts from "../../shared/toasts";
import Loading from "../../shared/loading";
import i18n from "../../shared/i18n/i18n";
import RouterService from "../../shared/services/router.service";

let loading,
    saving,
    auth_pwd;

function submit() {
    if (FormUtilities.validateFields("input")) {
        saving = true;
        Requester.post(Gateway.identity + "reauth", auth_pwd)
            .then(function (result) {
                SessionController.setSession(result).then(r => {
                    RouterService.navigateToAccount();
                });
            })
            .catch(function (error) {
                Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
            })
            .finally(function () {
                saving = false;
            });
    }
}

const Page_Reauthorise = {
    oninit: function () {
        if (!SessionController.session || !SessionController.session.identity) {
            loading = true;
            SessionController.getSession()
                .then(function () {
                    if (!SessionController.session.identity) {
                        RouterService.navigateToAccountLogin();
                    }
                    if (!SessionController.sso()) {
                        RouterService.navigateToAccount();
                        Toasts.add("success", i18n.t("already_logged_in"), "");
                    }
                })
                .catch(function () {
                    RouterService.navigateToAccountLogin();
                })
                .finally(function () {
                    loading = false;
                });
        }
    },
    view: function () {
        return [
            m("div", [
                !loading ? [
                    m("form", {
                        onsubmit: function (e) {
                            e.preventDefault();
                            submit();
                        }
                    }, [
                        m(".form-section", [
                            i18n.t("welcome_back") + SessionController.session.identity.name + i18n.t("confirm_password_to_continue")
                        ]),
                        m(".form-section required", [
                            m(".form-label", i18n.t("password")),
                            m("input[type=password]", {
                                oninput: function () {
                                    auth_pwd = this.value;
                                }, value: auth_pwd
                            })
                        ]),
                        m(".form-buttons", [
                            !saving ? m(m.route.Link, {
                                href: RouterService.getAccountForgotPasswordUrl(),
                                class: "btn-text flex-float-left",
                                style: "margin-left: 0;"
                            }, i18n.t("forgot_password")) : "",
                            !saving ? m("button", {"type": "submit"}, i18n.t("submit")) : m("button.btn-text no-click", [i18n.t("submit"), m(".loading-dots")])
                        ])
                    ])
                ] : m(Loading)
            ])
        ];
    }
};

export default Page_Reauthorise;
