import m from "mithril";
import Requester from "../../../shared/request";
import Gateway from "../../../shared/app_settings";
import i18n from "../../../shared/i18n/i18n";
import Toasts from "../../../shared/toasts";
import ModalManager from "../../../shared/modal";
import Auth from "../../../shared/auth";
import FormUtilities from "../../../shared/utilities/form_utilities";
import Component_Agenda_Input from "./component_agenda_input";
import {Datepicker} from "vanillajs-datepicker";
import Component_Autocomplete from "../../../shared/components/component_autocomplete";
import DateUtilities from "../../../shared/utilities/date_utilities";

let saving, loading, closeCallback, session, unitId, sessionId, sessionStart, sessionEnd, modalId;

function loadSession() {
    loading = true;
    Requester.get(Gateway.admin + "units/event/sessions/" + sessionId)
        .then(function (result) {
            session = result.properties;
            initDates(result);
            if (!session.agendaItems)
                session.agendaItems = [];
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        }).finally(function () {
            if (session && sessionStart) {
                let startDateTimeArray = sessionStart.includes("T")? sessionStart.split("T") : sessionStart.split(" ");

                if (startDateTimeArray.length > 0) {
                    session.startDate = startDateTimeArray[0];
                    startDate = startDateTimeArray[0];
                }

                if (startDateTimeArray.length > 1) {
                    session.startTime = startDateTimeArray[1];
                    startTime = startDateTimeArray[1];
                }
            }
            if (session && sessionEnd) {
                const endDateTimeArray = sessionEnd.includes("T")? sessionEnd.split("T") : sessionEnd.split(" ");

                if (endDateTimeArray.length > 0) {
                    session.endDate = endDateTimeArray[0];
                    endDate = endDateTimeArray[0];
                }

                if (endDateTimeArray.length > 1) {
                    session.endTime = endDateTimeArray[1];
                    endTime = endDateTimeArray[1];
                }
            }
            loading = false;
        });
}

function initDates(result) {
    realStartDate = new Date(result.properties.startDateTime);
    realEndDate = new Date(result.properties.endDateTime);
    sessionStart = formattedDate(result.properties.start ? result.properties.start : result.properties.startDateTime);
    sessionEnd = formattedDate(result.properties.end ? result.properties.end : result.properties.endDateTime);
}

function setInstructorValue(autocompleteValue) {
    session.instructorId = autocompleteValue.id || null;
    session.instructorName = autocompleteValue.name || autocompleteValue;
}

function formattedDate (date) {
    if (!date)
        return "";
    const localDateStartsWithMonth = new Date(date).toLocaleString(i18n.locale, {year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit", hour12: false }).replace(', ', 'T');
    return localDateStartsWithMonth;
}
let startDate, startTime, endDate, endTime
let realStartDate, realEndDate
function saveSession() {
    if (FormUtilities.validateFields(".modal .form-section-container input")) {

        session.startTime = startTime;
        session.endTime = endTime;
        let month = 30 * 24 * 60 * 60 * 1000;
        if (session.endTime < session.startTime)
            Toasts.add("error", i18n.t("cant_save"), i18n.t("start_before_end"));
        else if (realEndDate < realStartDate)
            Toasts.add("error", i18n.t("cant_save"), i18n.t("before_to_date"));
        else if (Math.round((realEndDate - realStartDate) / month) > 12)
            Toasts.add("error", i18n.t("cant_save"), i18n.t("within_12_months"));
        else {
            saving = true;
            // merge time with date
            let startTimeSlp = session.startTime.split(":");
            let endTimeSlp = session.endTime.split(":");
            if(startTimeSlp[0] && startTimeSlp[1])
                realStartDate.setHours(startTimeSlp[0], startTimeSlp[1]);
            if(endTimeSlp[0] && endTimeSlp[1])
                realEndDate.setHours(endTimeSlp[0], endTimeSlp[1]);
            session.startDateTime = DateUtilities.getISODateInLocalTimeZone(realStartDate);
            session.endDateTime = DateUtilities.getISODateInLocalTimeZone(realEndDate);
            Requester.post(Gateway.admin + "units/event/" + unitId + "/sessions/" + session.id, session)
                .then(function (result) {
                    ModalManager.close(modalId);
                    closeCallback();
                }).catch(function (error) {
                    Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
                }).finally(function () {
                    saving = false;
                });
        }
    }
}

const Modal_Unit_Event_Session_Settings = {
    oninit: function (vnode) {
        session = vnode.attrs.session
        unitId = vnode.attrs.unitId;
        sessionId = vnode.attrs.sessionId;
        closeCallback = vnode.attrs.callback;
        loadSession();
        },
    view: function () {
        return [
            session && !loading ? [
                m(".form", {class: !Auth.validateRights(["unit_event_edit"]) ? "disabled" : ""}, [
                    m(".form-section-container", [
                        m(".form-section required", [
                            m(".form-label", i18n.t("name")),
                            m("input[type=text]", {
                                oninput: function () {
                                    session.name = this.value;
                                }, value: session.name
                            })
                        ]),
                        m(".form-section", [
                            m(".form-label", i18n.t("info")),
                            m("textarea", {
                                oninput: function () {
                                    session.information = this.value;
                                }, value: session.information
                            })
                        ]),
                        m(".form-section-shared", [
                            m(".form-section", [
                                m(".form-label", i18n.t("min_places")),
                                m("input[type=number]", {
                                    oninput: function () {
                                        session.minPlaces = this.value;
                                    }, value: session.minPlaces
                                })
                            ]),
                            m(".form-section", [
                                m(".form-label", i18n.t("max_places")),
                                m("input[type=number]", {
                                    oninput: function () {
                                        session.maxPlaces = this.value;
                                    }, value: session.maxPlaces
                                })
                            ])
                        ]),
                        m(".form-section required", [
                            m(".form-label", i18n.t("instructor")),
                            m(Component_Autocomplete, {
                                endpoint: "users",
                                initialValue: {
                                    name: session.instructorName,
                                    id:  session.instructorId
                                },
                                propertyName: "name",
                                updateCallback: setInstructorValue,
                                submitCallback: setInstructorValue,
                            })
                        ]),
                        m(".form-section required", [
                            m(".form-label", i18n.t("location")),
                            m("input[type=text]", {
                                oninput: function () {
                                    session.location = this.value;
                                }, value: session.location
                            })
                        ]),
                        m(".form-section-shared", [
                            m(".form-section required", [
                                m(".form-label", i18n.t("from")),
                                m("input[type=text] .calendar", {
                                    oncreate: v => {
                                        new Datepicker(v.dom, {
                                            language: i18n.locale,
                                            updateOnBlur: true,
                                            container: ".modal",
                                        });
                                    },
                                    onblur: function () {
                                        startDate = this.value;
                                        realStartDate = this.datepicker.getDate();
                                    },
                                    value: startDate
                                })
                            ]),
                            m(".form-section required", [
                                m(".form-label", i18n.t("to")),
                                m("input[type=text] .calendar", {
                                    oncreate: v => {
                                        new Datepicker(v.dom, {
                                            language: i18n.locale,
                                            updateOnBlur: true,
                                            container: ".modal",
                                        });
                                    },
                                    onblur: function () {
                                        realEndDate = this.datepicker.getDate();
                                        endDate = this.value;
                                    },
                                    value: endDate
                                })
                            ])
                        ]),
                        m(".form-section-shared", [
                            m(".form-section required", [
                                m(".form-label", i18n.t("start_time")),
                                m("input[type=time][lang="+i18n.locale+"]", {
                                    onblur: function () {
                                        startTime = this.value;
                                    },
                                    value: startTime
                                })
                            ]),
                            m(".form-section required", [
                                m(".form-label", i18n.t("end_time")),
                                m("input[type=time][lang="+i18n.locale+"]", {
                                    onblur: function () {
                                        endTime = this.value;
                                    },
                                    value: endTime
                                })
                            ])
                        ])
                    ]),
                    m(Component_Agenda_Input, {array: session.agendaItems}),
                    Auth.validateRights(["unit_event_edit"], [
                        m(".form-buttons", [
                            !saving ? m("button.btn-cta", {onclick: saveSession}, i18n.t("save")) : m("button.btn-text no-click", [i18n.t("saving"), m(".loading-dots")])
                        ])
                    ])
                ])
            ] : m(".text-gray padding", [i18n.t("loading"), m(".loading-dots")])
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
};
export default Modal_Unit_Event_Session_Settings;
