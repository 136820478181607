import m from "mithril";
const Captcha_Badge = {
    oninit: function (vnode) {
    },
    view: function (vnode) {
        return [
            m("small", { class: "grecaptcha-badge-replacement" }, [
                "This site is protected by reCAPTCHA and the Google",
                m("a", { class: "hyperlink", target: "_blank", href: "https://policies.google.com/privacy" }, " Privacy Policy"),
                " and",
                m("a", { class: "hyperlink", target: "_blank", href: "https://policies.google.com/terms" }, " Terms of Service"),
                " apply."
            ])
        ];
    }
};

export default Captcha_Badge;
