import m from "mithril";
import Requester from "../../../shared/request";
import Gateway from "../../../shared/app_settings";
import i18n from "../../../shared/i18n/i18n";
import Toasts from "../../../shared/toasts";
import ModalManager from "../../../shared/modal";
import FormUtilities from "../../../shared/utilities/form_utilities";
import Component_Agenda_Input from "./component_agenda_input";
import Component_Autocomplete from "../../../shared/components/component_autocomplete";
import {Datepicker} from "vanillajs-datepicker";
import DateUtilities from "../../../shared/utilities/date_utilities";

let saving = false;
let unit = null;
let callback;
let modalId;
const session = {
    name: "",
    information: "",
    location: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    startDateTime: "",
    endDateTime: "",
    minPlaces: null,
    maxPlaces: null,
    agendaItems: []
};
let realStartDate, realEndDate;

function saveSession() {
    if (FormUtilities.validateFields(".modal .form-section-container input")) {

        let month = 30 * 24 * 60 * 60 * 1000;
        if (session.endTime < session.startTime)
            Toasts.add("error", i18n.t("cant_save"), i18n.t("start_before_end"));
        else if (realEndDate < realStartDate)
            Toasts.add("error", i18n.t("cant_save"), i18n.t("before_to_date"));
        else if (Math.round((realEndDate - realStartDate) / month) > 12)
            Toasts.add("error", i18n.t("cant_save"), i18n.t("within_12_months"));
        else {
            saving = true;
            const startTimeSlp = session.startTime.split(":");
            const endTimeSlp = session.endTime.split(":");
            if(startTimeSlp[0] && startTimeSlp[1])
                realStartDate.setHours(startTimeSlp[0], startTimeSlp[1]);
            if(endTimeSlp[0] && endTimeSlp[1])
                realEndDate.setHours(endTimeSlp[0], endTimeSlp[1]);

            session.startDateTime = DateUtilities.getISODateInLocalTimeZone(realStartDate);
            session.endDateTime = DateUtilities.getISODateInLocalTimeZone(realEndDate);
            Requester.post(Gateway.admin + "units/event/" + unit.id + "/sessions", session)
                .then(function (result) {
                    callback();
                    ModalManager.close(modalId);
                }).catch(function (error) {
                Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
            }).finally(function () {
                saving = false;
            });
        }
    }
}

function setInstructorValue(autocompleteValue) {
    session.instructorId = autocompleteValue.id || null;
    session.instructorName = autocompleteValue.name || autocompleteValue;
}


const Modal_Unit_Event_Session_New = {
    oninit: function (vnode) {
        callback = vnode.attrs.callback;
        unit = vnode.attrs.unit;
        if (!unit)
            ModalManager.close(modalId);

        session.name = unit.name;
        session.location = unit.location;
        session.information = unit.information;
        session.startDateTime = unit.startDateTime;
        session.endDateTime = unit.endDateTime;
        session.minPlaces = unit.minPlaces;
        session.maxPlaces = unit.maxPlaces;
        session.startDate = "";
        session.endDate = "";

        if (session && unit.startDateTime) {
            const startDateTimeArray = unit.startDateTime.split(" ");

            if (startDateTimeArray.length > 0) {
                session.startDate = startDateTimeArray[0];
            }

            if (startDateTimeArray.length > 1) {
                session.startTime = startDateTimeArray[1];
            }
        }

        if (session && unit.endDateTime) {
            const endDateTimeArray = unit.endDateTime.split(" ");

            if (endDateTimeArray.length > 0) {
                session.endDate = endDateTimeArray[0];
            }

            if (endDateTimeArray.length > 1) {
                session.endTime = endDateTimeArray[1];
            }
        }
    },
    view: function () {
        return [
            m(".form-section-container", [
                m(".form-section required", [
                    m(".form-label", i18n.t("name")),
                    m("input[type=text]", {
                        oninput: function () {
                            session.name = this.value;
                        }, value: session.name
                    })
                ]),
                m(".form-section", [
                    m(".form-label", i18n.t("info")),
                    m("textarea", {
                        oninput: function () {
                            session.information = this.value;
                        }, value: session.information
                    })
                ]),
                m(".form-section required", [
                    m(".form-label", i18n.t("location")),
                    m("input[type=text]", {
                        oninput: function () {
                            session.location = this.value;
                        }, value: session.location
                    })
                ]),
                m(".form-section-shared", [
                    m(".form-section", [
                        m(".form-label", i18n.t("min_places")),
                        m("input[type=number]", {
                            oninput: function () {
                                session.minPlaces = this.value;
                            }, value: session.minPlaces
                        })
                    ]),
                    m(".form-section", [
                        m(".form-label", i18n.t("max_places")),
                        m("input[type=number]", {
                            oninput: function () {
                                session.maxPlaces = this.value;
                            }, value: session.maxPlaces
                        })
                    ])
                ]),
                m(".form-section required", [
                    m(".form-label", i18n.t("instructor")),
                    m(Component_Autocomplete, {
                        endpoint: "users",
                        updateCallback: setInstructorValue,
                        submitCallback: setInstructorValue
                    })
                ]),
                m(".form-section-shared", [
                    m(".form-section required", [
                        m(".form-label", i18n.t("from")),
                        m("input[type=text] .calendar", {
                            oncreate: v => {
                                new Datepicker(v.dom, {
                                    language: i18n.locale,
                                    updateOnBlur: true,
                                    container: ".modal"
                                });
                            },
                            onblur: function () {
                                realStartDate = this.datepicker.getDate();
                                session.startDate = this.value;
                            },
                            value: session.startDate
                        })
                    ]),
                    m(".form-section required", [
                        m(".form-label", i18n.t("to")),
                        m("input[type=text] .calendar", {
                            oncreate: v => {
                                new Datepicker(v.dom, {
                                    language: i18n.locale,
                                    updateOnBlur: true,
                                    container: ".modal"
                                });
                            },
                            onblur: function () {
                                realEndDate = this.datepicker.getDate();
                                session.endDate = this.value;
                            },
                            value: session.endDate
                        })
                    ])
                ]),
                m(".form-section-shared", [
                    m(".form-section required", [
                        m(".form-label", i18n.t("start_time")),
                        m("input[type=time][lang="+i18n.locale+"]", {
                            oninput: function () {
                                session.startTime = this.value;
                            }, value: session.startTime
                        })
                    ]),
                    m(".form-section required", [
                        m(".form-label", i18n.t("end_time")),
                        m("input[type=time][lang="+i18n.locale+"]", {
                            oninput: function () {
                                session.endTime = this.value;
                            }, value: session.endTime
                        })
                    ])
                ])
            ]),
            m(Component_Agenda_Input, {array: session.agendaItems}),
            m(".form-buttons", [
                !saving ? m("button", {onclick: saveSession}, i18n.t("save")) : m("button.btn-text no-click", [i18n.t("saving"), m(".loading-dots")])
            ])
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
};
export default Modal_Unit_Event_Session_New;
