import m from "mithril";
import DateUtilities from "../../shared/utilities/date_utilities";
import i18n from "../../shared/i18n/i18n";

const Modal_Unit_File_History = {
    view: function (vnode) {
        return [
            m(".form-section", [
                vnode.attrs.history && vnode.attrs.history.length > 0 ? [
                    m(".table-wrapper flex-auto", [
                        m("table.full-width fixed-table ellipsis", [
                            m("thead",
                                m("tr", [
                                    m("th", { style: "width: 7%;" }, i18n.t("version")),
                                    m("th", { style: "width: 25%;" }, i18n.t("date")),
                                    m("th", { style: "width: 53%;" }, i18n.t("file_name")),
                                    vnode.attrs.metadataTitle ? m("th", { style: "width: 15%;" }, vnode.attrs.metadataTitle) : "",
                                    m("th", { style: "width: 15%;" }, i18n.t("uploaded_by"))
                                ])
                            ),
                            m("tbody", vnode.attrs.history.map(function (record, index) {
                                return [
                                    m("tr", [
                                        m("td", index + 1),
                                        m("td", DateUtilities.getFormattedDateFromUTC(record.creationDate)),
                                        m("td", record.fileName),
                                        vnode.attrs.metadataTitle ? m("td", record.metadata) : "",
                                        m("td", record.uploaderName)
                                    ])
                                ];
                            }))
                        ])
                    ])
                ] : m(".text-gray padding", i18n.t("no_records"))
            ])
        ];
    }
};
export default Modal_Unit_File_History;
