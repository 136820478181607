import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import i18n from "../../shared/i18n/i18n";
import ModalManager from "../../shared/modal";
import SessionController from "../../shared/session";

let originalUnit, enrolmentId, eventUnit, exitCallback, saving, loading, modalId;

function loadEvent() {
    loading = true;
    Requester.get(Gateway.public + "units/event/" + originalUnit.id)
        .then(function (result) {
            eventUnit = null;
            eventUnit = result;
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .then(function () {
            loading = false;
        });
}

function selectSession(session) {
    saving = true;
    Requester.post(Gateway.public + "units/event?enrolmentId=" + enrolmentId, {
        id: originalUnit.enrolment.id,
        sessions: [{id: session.id, name: session.name}]
    })
        .then(function (result) {
            exitCallback();
            ModalManager.close(modalId);
            Toasts.add("success", i18n.t("progress_saved"));
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .then(function () {
            saving = false;
        });
}

function getFormattedDate(date) {
    return new Date(date).toLocaleTimeString(i18n.locale, {
        weekday: "long",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "2-digit"
    });
}

function canDisplay(session) {
    return session.enrolled || canEnrol(session);
}

function canEnrol(session) {
    if (session.enrolled) {
        return false;
    }

    return eventUnit.allowEnrolment
        && session.allowEnrolment
        && new Date(session.start) > new Date()
        && (!session.maximumPlaces || session.maximumPlaces > session.reservedPlaces)
        && (eventUnit.allowChangeSession || !eventUnit.enrolment || !eventUnit.enrolment.isCompleted)
        && SessionController.session.identity;
}

const Modal_Event = {
    oninit: function (vnode) {
        enrolmentId = vnode.attrs.enrolmentId;
        originalUnit = vnode.attrs.unit;
        exitCallback = vnode.attrs.callback;
        loadEvent();
    },
    view: function (vnode) {
        return [
            eventUnit ? [
                m(".form-section", [
                    eventUnit.description ? m(".mb-2", [
                        m(".text-bolder", i18n.t("about_event")),
                        eventUnit.description
                    ]) : "",
                    eventUnit.information ? m(".mb-2", [
                        m(".text-bolder", i18n.t("additional_information")),
                        eventUnit.information
                    ]) : "",
                    m(".mt-2", [
                        eventUnit.sessions && eventUnit.sessions.length > 0 ? [
                            m(".big-number-list sessions-list", [
                                eventUnit.sessions.map(function (session) {
                                    if (canDisplay(session)) {
                                        return [
                                            m("div", [
                                                m(".padding", [
                                                    m(".flex-row", [
                                                        m(".date-number mr-2", {class: session.enrolled ? "bg-warning" : ""}, [
                                                            new Date(session.start).getDate(),
                                                            m("div", new Date(session.start).toLocaleDateString(i18n.locale, {month: "long"}))
                                                        ]),
                                                        m("div", [
                                                            m(".text-bolder", session.name !== eventUnit.name ? m(".text-bold", session.name) : ""),
                                                            m(".mb-1", [
                                                                m(".text-bolder", i18n.t("location")),
                                                                session.location
                                                            ]),
                                                            m(".flex-row justify-between mb-1", [
                                                                m("div", [
                                                                    m(".text-bolder", i18n.t("start")),
                                                                    getFormattedDate(session.start)
                                                                ]),
                                                                m("div", [
                                                                    m(".text-bolder", i18n.t("end")),
                                                                    getFormattedDate(session.end)
                                                                ])
                                                            ]),
                                                            session.instructorName ? m(".mb-1", [
                                                                m(".text-bolder", i18n.t("instructor")),
                                                                session.instructorName
                                                            ]) : "",
                                                            !session.enrolled ? [
                                                                m("div", [
                                                                    m("button", {
                                                                        onclick: function () {
                                                                            selectSession(session);
                                                                        }
                                                                    }, i18n.t("select_session")),
                                                                ])
                                                            ] : "",
                                                        ])
                                                    ])
                                                ])
                                            ])
                                        ];
                                    }
                                })
                            ])
                        ] : i18n.t("no_sessions")
                    ])
                ])
            ] : m(".text-gray", [i18n.t("loading"), m(".loading-dots")])
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
};

export default Modal_Event;
