let UrlUtilities = {
	combinePath: function (...args) {
		if (!args[0])
			return "";

		for (let i = 0; i < args.length; i++) {
			if (args[i]) {
				if (i !== 0)
					args[i] = args[i].replace(new RegExp('^/'), '');
				if (i !== args.length - 1)
					args[i] = args[i].replace(new RegExp('/$'), '');
			}
		}

		return args.join('/');
	},
	createUrl: function(url, ...params) {
		if (!params)
			return url;

		return url + (url.indexOf("?") === -1 ? "?" : "&") + params.join("&");
    }
}

export default UrlUtilities;
