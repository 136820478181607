import m from "mithril";
import Requester from "../../../shared/request";
import Gateway from "../../../shared/app_settings";
import Toasts from "../../../shared/toasts";
import i18n from "../../../shared/i18n/i18n";
import ModalManager from "../../../shared/modal";
import Modal_Confirm_Delete from "../../../shared/components/modal_confirm_delete";
import Auth from "../../../shared/auth";
import UnitSettingsUtilities from "../unit_settings_utilities";
import FormUtilities from '../../../shared/utilities/form_utilities';
import RouterService from "../../../shared/services/router.service";
import Modal_Unit_File_History from "../modal_unit_file_history";
import UniqueName from "../../../shared/uniqueName";

let saving, uploading, deleting, unit, loading;

function saveUnitDetails() {
    if (!FormUtilities.validateFields(".details input")) {
        return;
    }

    saving = true;
    Requester.post(Gateway.admin + "units/document/" + unit.id, {
        id: unit.id,
        name: unit.name,
        description: unit.description,
        acknowledgeRequired: unit.acknowledgeRequired,
        acknowledgeStatement: unit.acknowledgeStatement
    })
        .then(function (result) {
            Toasts.add("success", i18n.t("saved_changes"));
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .then(function () {
            saving = false;
        });
}

function uploadDocument() {
    const d = new FormData();
    if (document.getElementById("document-file-input").files[0] !== null) {
        const file = document.getElementById("document-file-input").files[0];
        d.append("file", file, file.name);

        saving = false;
        uploading = true;

        Requester.upload(Gateway.admin + "units/document/" + unit.id + "/upload-file", d)
            .then(function () {
                Toasts.add("success", i18n.t("saved_changes"), "");
                unit.documentFileName = file.name;
            })
            .catch(function (error) {
                Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
            })
            .finally(function () {
                uploading = false;
            });
    } else {
        Toasts.add("error", i18n.t("cant_upload"), error);
        uploading = false;
    }
}

function confirmDeleteUnit() {
    ModalManager.open(i18n.t("delete_unit"), [], Modal_Confirm_Delete, {callback: deleteUnit});
}

function deleteUnit() {
    deleting = true;
    Requester.delete(Gateway.admin + "units/document/" + unit.id)
        .then(function () {
            RouterService.navigateToUnits();
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .finally(function () {
            deleting = false;
        });
}

function showHistory() {
    loading = true;
    Requester.get(Gateway.admin + "units/document/" + unit.id + "/history")
        .then(function (result) {
            if (result && result.length > 0) {
                ModalManager.open(i18n.t("history"), ["modal-wide"], Modal_Unit_File_History, {history: result});
            }
            else {
                Toasts.add("warn", i18n.t("no_history_found"));
            }
        })
        .catch(function (error) {
            debugger;
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .finally(() => loading = false);
}

const Component_Unit_Document_Settings = {
    oninit: function (vnode) {
        unit = vnode.attrs.unit;
    },
    view: function () {
        return [
            m(".content-box", [
                m("h3",  i18n.t("details")),
                m(".form .details", {class: !Auth.validateRights(["unit_document_edit"]) ? "disabled" : ""}, [
                    m(".form-section required", [
                        m(".form-label", i18n.t("name")),
                        m("input[type=text]", {
                            onchange: function () {
                                UniqueName.checkUniqueUnitName(unit.id, unit.name);
                            },
                            oninput: function () {
                                unit.name = this.value;
                            }, value: unit.name
                        })
                    ]),
                    m(".form-section", [
                        m(".form-label", i18n.t("description")),
                        m("textarea", {
                            oninput: function () {
                                unit.description = this.value;
                            }, value: unit.description
                        })
                    ]),
                    m(".form-section", [
                        m(".form-label", i18n.t("AcknowledgeRequired")),
                        m(".flex-row justify-between", [
                            m(".btn btn-toggle", {
                                onclick: function () {
                                    unit.acknowledgeRequired = !unit.acknowledgeRequired;
                                },
                                class: unit.acknowledgeRequired ? "active" : ""
                            })
                        ])
                    ]),
                    m(".form-section", [
                        m(".form-label", i18n.t("AcknowledgeStatement")),
                        m("textarea", {
                            oninput: function () {
                                unit.acknowledgeStatement = this.value;
                            }, value: unit.acknowledgeStatement
                        })
                    ]),
                    m(".form-buttons", [
                        !uploading && !saving && !(UniqueName.unitLoading) && (UniqueName.isUnitNameUnique) ? m("button", {onclick: saveUnitDetails}, i18n.t("save")) : m("button.btn-text no-click", [i18n.t("saving"), m(".loading-dots")])
                    ])
                ])
            ]),
            m(".content-box", [
                m("h3", i18n.t("content")),
                m(".form", {class: !Auth.validateRights(["unit_document_edit"]) ? "disabled" : ""}, [
                    m(".flex-horizontal-content mb-1", unit.documentFileName ? [
                        m("div.ellipsis", i18n.t("current_file") + ": " + unit.documentFileName),
                        m("button.btn-text", {
                            onclick: showHistory,
                            class: loading ? "disabled loading-dots" : ""
                        }, i18n.t("history"))
                    ] : ""),
                    m(".form-section", [
                        m(".form-label inline-tip", i18n.t("document")),
                        m("div",  i18n.t("supported_file_types_pdf")),
                        m("input[type=file].form-input#document-file-input"),
                        uploading ? [
                            m(".loading-bar mt-1", m(".loading-width"))
                        ] : ""
                    ]),
                    m(".form-buttons", [
                        !saving && !uploading ? m("button", {onclick: uploadDocument}, i18n.t("upload")) : m("button.btn-text no-click", [i18n.t("uploading"), m(".loading-dots")])
                    ])
                ])
            ]),
            m(".content-box", [
                m(".form", {class: !Auth.validateRights(["unit_document_edit"]) ? "disabled" : ""}, [
                    m(".flex-row justify-between", [
                        m("h3.mb-0", i18n.t("active")),
                        m(".btn btn-toggle", {
                            onclick: function () {
                                UnitSettingsUtilities.toggleActiveStatus([unit.id], !unit.isActive, saving);
                                unit.isActive = !unit.isActive;
                            },
                            class: unit.isActive ? "active" : ""
                        })
                    ])
                ])
            ]),
            Auth.validateRights(["unit_document_delete"], [
                m(".content-box", [
                    !deleting ? m("button.btn-error", {onclick: confirmDeleteUnit}, i18n.t("delete_unit")) : m("button.btn-text no-click text-error", [i18n.t("deleting"), m(".loading-dots")])
                ])
            ])
        ];
    }
};
export default Component_Unit_Document_Settings;
