import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import SessionController from "../../shared/session";
import Loading from "../../shared/loading";
import i18n from "../../shared/i18n/i18n";
import RouterService from "../../shared/services/router.service";

let loading = false;
let loadingSso = false;

let ssoLinks;

function getSsoLinks() {
    loadingSso = true;
    Requester.get(Gateway.identity + "sso")
        .then(function (result) {
            ssoLinks = result;
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .finally(function () {
            loadingSso = false;
        });
}

let session = () => SessionController.session;
let sessionEnv = () => SessionController.session.environment;
let sessionEnvReady = () => SessionController.session && SessionController.session.environment;

const Log_In_Button = {
    oninit: function () {
        loading = true;
        if (session() && session().identity) {
            RouterService.navigateOnLogin();
            Toasts.add("success", i18n.t("already_logged_in"), "");
        }

        if (sessionEnvReady() && sessionEnv().useExternalLogin && sessionEnv().externalLoginUrl)
            window.location = sessionEnv().externalLoginUrl;

        if (sessionEnvReady() && sessionEnv().ssoConfigured) {
            getSsoLinks();
        }

        loading = false;
    },
    view: function () {
        return [
            !loading && sessionEnvReady() && sessionEnv().ssoConfigured ? [
                !loadingSso ? [
                    ssoLinks && ssoLinks.length > 0 ? [
                        ssoLinks.map(function (link) {
                            return [
                                m("a.btn.fill-width.full-btn.primary-btn.centre-content",{href: link.url}, [
                                    m("span", i18n.t("sign-in"))
                                ])
                            ];
                        })
                    ] : ""
                ] : m(Loading, {class: "loading-inline"})
            ] : ""
        ];
    }
};
export default Log_In_Button;
