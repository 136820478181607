import m from "mithril";
import i18n from "../../../shared/i18n/i18n";

let editorId, input;

function setTextSize(size) {
    //input.state.getEditor().changeFormat({ tag: "span", attributes: { "class": size } }, null);
    input.state.getEditor().setFontSize(size);
    document.querySelector(".font-size-select").classList.remove("open");
}

function setTextColour(colour) {
    input.state.getEditor().setTextColour(colour);
    document.querySelector(".font-colour-select").classList.remove("open");
}

const CertificateEditorToolbar = {
    oninit: function (vnode) {
        if (vnode.attrs.input) {
            input = vnode.attrs.input;
            editorId = vnode.attrs.id;
        }
    },
    view: function () {
        return [
            m(".text-editor-toolbar", {id: editorId}, [
                m("i.icon-image tooltip tooltip-right", {
                    onclick: function () {
                        document.querySelector(".certificate-bg-input").click();
                    }, "data-tooltip": (i18n.t("background_image") + " (A4 2MB max)")
                }),
                m("i.icon-font-bold tooltip", {
                    onclick: function () {
                        let x = input.state.getEditor().bold();
                    }, "data-tooltip": i18n.t("bold")
                }),
                m("i.icon-font-italic tooltip", {
                    onclick: function () {
                        input.state.getEditor().italic();
                    }, "data-tooltip": i18n.t("italic")
                }),
                m("i.icon-underline tooltip", {
                    onclick: function () {
                        input.state.getEditor().underline();
                    }, "data-tooltip": i18n.t("underline")
                }),
                m(".tab-dropdown", [
                    m("i.icon-font-size tooltip", {
                        style: "vertical-align: sub;",
                        onclick: function () {
                            document.querySelector(".font-colour-select").classList.remove("open");
                            document.querySelector(".font-size-select").classList.toggle("open");
                        }, "data-tooltip": i18n.t("text_size")
                    }),
                    m("ul.font-size-select", [
                        m("li", {
                            onclick: function () {
                                setTextSize("0.3em");
                            }
                        }, "8"),
                        m("li", {
                            onclick: function () {
                                setTextSize("0.5em");
                            }
                        }, "12"),
                        m("li", {
                            onclick: function () {
                                setTextSize("0.75em");
                            }
                        }, "18"),
                        m("li", {
                            onclick: function () {
                                setTextSize("1em");
                            }
                        }, "24"),
                        m("li", {
                            onclick: function () {
                                setTextSize("1.3em");
                            }
                        }, "32"),
                        m("li", {
                            onclick: function () {
                                setTextSize("2em");
                            }
                        }, "48"),
                        m("li", {
                            onclick: function () {
                                setTextSize("2.6em");
                            }
                        }, "64")
                    ])
                ]),
                m(".tab-dropdown", [
                    m("i.icon-font-colour tooltip", {
                        style: "vertical-align: sub;",
                        onclick: function () {
                            document.querySelector(".font-size-select").classList.remove("open");
                            document.querySelector(".font-colour-select").classList.toggle("open");
                        }, "data-tooltip": i18n.t("text_colour")
                    }),
                    m("ul.font-colour-select", [
                        m("li", {
                            onclick: function () {
                                setTextColour("#ccc");
                            }, style: "background: #ccc;"
                        }),
                        m("li", {
                            onclick: function () {
                                setTextColour("#888");
                            }, style: "background: #888;"
                        }),
                        m("li", {
                            onclick: function () {
                                setTextColour("#555");
                            }, style: "background: #555;"
                        }),
                        m("li", {
                            onclick: function () {
                                setTextColour("#000");
                            }, style: "background: #000;"
                        }),
                        m("li", {
                            onclick: function () {
                                setTextColour("#f00");
                            }, style: "background: #f00;"
                        }),
                        m("li", {
                            onclick: function () {
                                setTextColour("#0f0");
                            }, style: "background: #0f0;"
                        }),
                        m("li", {
                            onclick: function () {
                                setTextColour("#00f");
                            }, style: "background: #00f;"
                        }),
                        m("li", {
                            onclick: function () {
                                setTextColour("#00f");
                            }, style: "background: #fff;"
                        })
                    ])
                ]),
                m("i.icon-align-left tooltip", {
                    onclick: function () {
                        input.state.getEditor().setTextAlignment("left");
                    }, "data-tooltip": i18n.t("align_left")
                }),
                m("i.icon-align-center tooltip", {
                    onclick: function () {
                        input.state.getEditor().setTextAlignment("center");
                    }, "data-tooltip": i18n.t("align_center")
                }),
                m("i.icon-align-right tooltip", {
                    onclick: function () {
                        input.state.getEditor().setTextAlignment("right");
                    }, "data-tooltip": i18n.t("align_right")
                }),
                m("i.icon-list-bullet tooltip", {
                    onclick: function () {
                        input.state.getEditor().makeUnorderedList();
                    }, "data-tooltip": i18n.t("list_bullet")
                }),
                m("i.icon-list-numbered tooltip", {
                    onclick: function () {
                        input.state.getEditor().makeOrderedList();
                    }, "data-tooltip": i18n.t("list_numbered")
                }),
                m("i.icon-list tooltip", {
                    onclick: function () {
                        input.state.getEditor().removeList();
                    }, "data-tooltip": i18n.t("remove_list")
                }),
                m("i.icon-indent-more tooltip", {
                    onclick: function () {
                        input.state.getEditor().increaseQuoteLevel();
                    }, "data-tooltip": i18n.t("indent_more")
                }),
                m("i.icon-indent-less tooltip", {
                    onclick: function () {
                        input.state.getEditor().decreaseQuoteLevel();
                    }, "data-tooltip": i18n.t("indent_less")
                }),
                m("i.icon-undo tooltip", {
                    onclick: function () {
                        input.state.getEditor().undo();
                    }, "data-tooltip": i18n.t("undo")
                }),
                m("i.icon-redo tooltip", {
                    onclick: function () {
                        input.state.getEditor().redo();
                    }, "data-tooltip": i18n.t("redo")
                }),
                m("i.icon-font-clear-style tooltip", {
                    onclick: function () {
                        input.state.getEditor().removeAllFormatting();
                    }, "data-tooltip": i18n.t("clear_style")
                })
            ])
        ];
    }
};

export default CertificateEditorToolbar;
