import m from 'mithril';
import Component_Player from './component_player';
import i18n from "../../shared/i18n/i18n";

let reloadCallback;

export function launchInteractive(unit, course) {
    m.mount(document.getElementById('player-mount'), {
        view: function () {
            return m(Component_Player, {
                courseId: course.id,
                enrolmentId: course.enrolment.id,
                unit: unit,
                callback: closeInteractive
            });
        }
    });
}

export function closeInteractive(u) {
    m.mount(document.getElementById('player-mount'), null);
    if (reloadCallback)
        reloadCallback();
}

const Component_Unit_Actions_Scorm = {
    oninit: function (vnode) {
        let unit = vnode.attrs.unit;
        let course = vnode.attrs.course;
        let unitNumber = vnode.attrs.unitNumber;
        reloadCallback = vnode.attrs.reloadCallback;
        if (unit && course && unitNumber && unit.id === unitNumber) {
            return unit && unit.enrolment.allowAccess ?
                unit?.enrolment?.isCompleted && !unit.allowRelaunchIfCompleted ?
                    '' : launchInteractive(unit, course)
                : '';
        }
    },
    view: function (vnode) {
        let unit = vnode.attrs.unit;
        let course = vnode.attrs.course;
        reloadCallback = vnode.attrs.reloadCallback;
        return unit?.enrolment?.allowAccess ?
            unit.enrolment.isCompleted && !unit.allowRelaunchIfCompleted ?
                '' : m('button', {
                    onclick: function () {
                        launchInteractive(unit, course);
                    }, class: unit?.enrolment?.isCompleted ? '' : 'btn-cta'
                }, (unit.enrolment.isCompleted && unit.allowRelaunchIfCompleted) ? i18n.t('review') : i18n.t('launch'))
            : '';
    }
};
export default Component_Unit_Actions_Scorm;
