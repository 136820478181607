import m from 'mithril';
import SessionController from '../../shared/session';
import PublicComponent_Enrolments from './component_enrolments';
import PublicComponent_Certificates from './component_certificates';
import PublicComponent_Organisations from './component_memberships';
import PublicComponent_Settings from './component_settings';
import Component_SubNavBar from '../layout/subNavBar';
import RouterService from '../../shared/services/router.service';

let loading = false;
let tab = 'enrolments';

function setTab(newTab) {
    tab = newTab;
}
let currentPage;
const PublicPage_Profile = {
    oninit: function () {
        if (!SessionController.session || !SessionController.session.identity) {
            RouterService.navigateToPublicCourses();
        }

        currentPage = 'profile';
        document.body.classList.add('profile-style');
        m.redraw();
    },
    onremove: function () {
        document.body.classList.remove('profile-style');
    },
    view: function () {
        return [
            m('.SecondaryTheme SecondaryTheme__center', [
                m(Component_SubNavBar, {tab: tab, callback: setTab}),
                m('.centered-layout', [
                    m('.content-box' + (!(tab == null || tab.trim() === '') ? ' ' + tab : ''), [
                        !loading ? [
                            tab === 'enrolments' ? m(PublicComponent_Enrolments)
                                : tab === 'certs' ? m(PublicComponent_Certificates)
                                    : tab === 'orgs' ? m(PublicComponent_Organisations)
                                        : tab === 'settings' ? m(PublicComponent_Settings)
                                            : ''
                        ] : m('.text-gray padding', ['Loading', m('.loading-dots')])
                    ])
                ])
            ])
        ];
    }
};
export default PublicPage_Profile;
