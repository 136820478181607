import m from "mithril";
import i18n from "../../../shared/i18n/i18n";


let loading = false;
let unit = null;
let closeCallback;

const Component_Unit_Document_Preview = {
    oninit: function (vnode) {
        unit = vnode.attrs.unit;
        closeCallback = vnode.attrs.closeCallback;
    },
    onremove: function () {
        unit.selected = false;
    },
    view: function (vnode) {
        return [
            m(".content-box flex-auto", [
                !loading ?
                    unit && unit.documentPath ?
                        m("iframe.document-preview", {src: unit.documentPath})
                        : i18n.t("cant_load")
                    : [i18n.t("loading"), m(".loading-dots")]
            ])
        ];
    }
};
export default Component_Unit_Document_Preview;
