import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import i18n from "../../shared/i18n/i18n";
import ModalManager from "../../shared/modal";

let saving, user, password, confirmPassword, modalId;

function sendResetPasswordEmail() {
    saving = true;
    Requester.post(Gateway.admin + "users/" + user.id + "/send-password-reset")
        .then(function () {
            ModalManager.close(modalId);
            Toasts.add("success", i18n.t("reset_password_email_sent"), "");
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        saving = false;
    });
}

function updateUserPassword() {
    if (password !== confirmPassword) {
        Toasts.add("error", i18n.t("cant_save"), i18n.t("please_ensure_both_fields_match"));
    } else {
        saving = true;
        Requester.post(Gateway.admin + "users/" + user.id + "/change-password", {password: password})
            .then(function () {
                ModalManager.close(modalId);
                Toasts.add("success", i18n.t("password_updated"), "");
            }).catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        }).finally(function () {
            saving = false;
        });
    }
}

const Modal_User_Reset_Password = {
    oninit: function (vnode) {
        user = vnode.attrs.user;
    },
    view: function () {
        return [
            user.email ? [
                m(".form-section", [
                    (user.firstName ? [user.firstName, " ", user.lastName] : "This user"), " has an email address (", user.email, ") to send a password reset prompt to.",
                    m("br"),
                    m("br"),
                    i18n.t("click_reset_password_email"),
                    m("br"),
                    m("br"),
                    i18n.t("email_trouble_message")
                ]),
                m(".form-buttons", [
                    !saving ? m("button", {onclick: sendResetPasswordEmail}, i18n.t("send_reset_password_email")) : m("button.btn-text no-click", [i18n.t("sending"), m(".loading-dots")])
                ])

            ] : [
                m(".form-section required", [
                    m(".form-label", i18n.t("new_password")),
                    m("input[type=password]", {
                        oninput: function () {
                            password = this.value
                        }, value: password
                    })
                ]),
                m(".form-section required", [
                    m(".form-label",i18n.t("confirm_new_password")),
                    m("input[type=password]", {
                        oninput: function () {
                            confirmPassword = this.value
                        }, value: confirmPassword
                    })
                ]),
                m(".form-buttons", [
                    !saving ? m("button", {onclick: updateUserPassword}, i18n.t("save")) : m("button.btn-text no-click", [i18n.t("saving"), m(".loading-dots")])
                ])
            ]
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
};
export default Modal_User_Reset_Password;
