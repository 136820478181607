import m from "mithril";
import ModalManager from "../../shared/modal";
import Modal_Event from "./modal_event";
import i18n from "../../shared/i18n/i18n";

let unit, course, reloadCallback;

function openEvent(readOnly) {
    ModalManager.open(unit.name, [], Modal_Event, {
        unit: unit,
        enrolmentId: course.enrolment ? course.enrolment.id : "",
        readOnly: readOnly,
        callback: reloadCallback
    });
}

const Component_Unit_Actions_Event = {
    oninit: function (vnode) {
        unit = vnode.attrs.unit;
        course = vnode.attrs.course;
        reloadCallback = vnode.attrs.reloadCallback;
        let unitNumber = vnode.attrs.unitNumber;
        if (unit && course && unitNumber && unit.id === unitNumber) {
            return !unit.enrolment
            ? openEvent(true)
            : !unit.enrolment.allowAccess
                ? ""
                : openEvent
        }
    },
    view: function () {
        return !unit?.enrolment ? m("button", {
                onclick: function () {
                    openEvent(true);
                }
            }, i18n.t("view_details"))
            : !unit.enrolment.allowAccess ? ""
                : m("button", {
                    onclick: openEvent,
                    class: unit.enrolment.isCompleted ? "" : "btn-cta"
                }, i18n.t("view_my_session"));
    }
}

export default Component_Unit_Actions_Event;
