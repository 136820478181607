import m from "mithril";
import i18n from "../../shared/i18n/i18n";
import Toasts from "../../shared/toasts";
import ModalManager from "../../shared/modal";
import FormUtilities from "../../shared/utilities/form_utilities";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";

let userId, loading, saving, username, callback, modalId;
let valid = true;

function changeUsername() {
    if (!valid) {
        Toasts.add("error", i18n.t("cant_save"), i18n.t("enter_valid_username") + window.ENV.USERNAME_PREFIX);
        return;
    }

    saving = true;
    Requester.post(Gateway.admin + "users/" + userId + "/username", {username: username})
        .then(function () {
            ModalManager.close(modalId);
            Toasts.add("success",  i18n.t("username_updated"), "");
            if (callback) {
                callback();
            }
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        saving = false;
    });
}

const Modal_User_Change_UserName = {
    oninit: function (vnode) {
        userId = vnode.attrs.userId;
        username = window.ENV.USERNAME_PREFIX;
        callback = vnode.attrs.callback;
    },
    view: function () {
        return [
            m(".form-section required", [
                !loading ? [
                        m(".form-label", i18n.t("username")),
                        m("input[type=text]", {
                            oninput: function () {
                                username = this.value;
                                valid = FormUtilities.validateUsername(this.value);
                            }, value: username
                        })
                ] : [i18n.t("loading"), m(".loading-dots")]
            ]),
            m(".form-buttons", [
                !saving ? m("button", {onclick: changeUsername}, i18n.t("save")) : m("button.btn-text no-click", [i18n.t("saving"), m(".loading-dots")])
            ])
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
};
export default Modal_User_Change_UserName;
