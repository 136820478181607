import m from 'mithril';
import i18n from '../i18n/i18n';
import ModalManager from '../modal';

let modalId;
const Modal_Hyperlink_Editor = {
    linkUrl: '',
    linkText: '',
    invalidUrl: false,
    invalidUrlMessage: '',
    callback: undefined,
    oninit: function (vnode) {
        this.callback = vnode.attrs.callback;
        this.linkUrl = vnode.attrs.linkUrl || '';
        this.linkText = vnode.attrs.linkText || '';
        this.validateLinkUrl();
    },
    validateLinkUrl: function () {
        try {
            new URL(this.linkUrl);
            this.invalidUrl = false;
            this.invalidUrlMessage = '';
        } catch (_) {
            this.invalidUrl = true;
            this.invalidUrlMessage = i18n.t('please_enter_a_valid_url');
        }
    },
    view: function (vnode) {
        return [
            m('.form-section required', [
                m('.form-label', i18n.t('link_url')),
                m('input[type=text]', {
                    oninput: (e) => {
                        this.linkUrl = e.target.value;
                        this.validateLinkUrl();
                    },
                    value: this.linkUrl,
                    placeholder: i18n.t('enter_a_valid_url'),
                }),
                this.invalidUrl && m('span', { style: { color: 'red' } }, this.invalidUrlMessage)
            ]),
            m('.form-section', [
                m('label', i18n.t('link_text_optional')),
                m('input[type=text]', {
                    oninput: (e) => {
                        this.linkText = e.target.value;
                    },
                    value: this.linkText,
                    placeholder: i18n.t('enter_a_link_text'),
                }),
            ]),
            m('.form-buttons', [
                m('button', {
                    onclick: () => {
                        if (this.callback)
                            this.callback({ linkUrl: this.linkUrl, linkText: this.linkText });
                        ModalManager.close(modalId);
                    },
                    disabled: this.invalidUrl,
                }, i18n.t('ok'))
            ])
        ];
    },
    setModalId: function (id) {
        modalId = id;
    }
};

export default Modal_Hyperlink_Editor;
