import m from "mithril";
import ModalManager from "../modal";
import i18n from "../i18n/i18n";

let modalId;
function close() {
    ModalManager.close(modalId);
}

const Modal_Text_View = {
    view: function (vnode) {
        return [
            m("", vnode.attrs.text),
            m(".form-buttons", [
                m("button", {onclick: close}, i18n.t("close"))
            ])
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
};

export default Modal_Text_View;
