import m from "mithril";
import Squire from "squire-rte/build/squire";
import i18n from "../i18n/i18n";
import Toasts from "../toasts";
import sanitizeHtml from "sanitize-html";
import ModalManager from "../modal";
import Modal_Hyperlink_Editor from './component_hyperlink_editor';
import TextUtilities from '../utilities/text_utilities';

function sanitizeInput(inputHtml) {
    const cleanHtml = sanitizeHtml(inputHtml, {
        allowedTags: sanitizeHtml.defaults.allowedTags.filter(tag => tag !== "a"),
        allowedAttributes: false,
    });

    return cleanHtml;
}

const TextEditor = {
    editor: null,
    content: null,
    oninit: function (vnode) {
        TextEditor.content = vnode.attrs.content;
    },
    setTextSize: function (size) {
        TextEditor.editor.setFontSize(size);
        document.querySelector(".font-size-select").classList.remove("open");
    },
    setTextColour: function (colour) {
        TextEditor.editor.setTextColour(colour);
        document.querySelector(".font-colour-select").classList.remove("open");
    },
    openHyperlinkEditor: function() {
        const editor = TextEditor.editor;
        if (editor) {
            const selection = editor.getSelection();
            const selectedText = editor.getSelectedText();
            let linkUrl = "";

            if (selection.startContainer.nodeType === Node.TEXT_NODE && selectedText !== "") {
                const textNode = selection.startContainer;
                const parentNode = textNode.parentNode;

                if (parentNode.tagName === "A") {
                    linkUrl = parentNode.getAttribute("href");
                }
            }

            ModalManager.open(i18n.t("insert_hyperlink"), [], Modal_Hyperlink_Editor, {
                    linkUrl: linkUrl,
                    linkText: selectedText,
                    callback: (result) => {
                        if (result && result.linkUrl) {
                            this.insertLink(result.linkUrl, result.linkText);
                        }
                    }
                }
            );
        }
    },
    insertLink: function (linkUrl, linkText) {
        const editor = TextEditor.editor;
        const linkHTML = `<a href="${linkUrl}" target="_blank">${linkText || linkUrl}</a>`;
        editor.insertHTML(linkHTML);
    },
    view: function () {
        return [
            m(".text-editor", [
                m(".text-editor-toolbar", [
                    m("i.icon-font-bold tooltip", { onclick: function () { TextEditor.editor.bold(); }, "data-tooltip": i18n.t("bold") }),
                    m("i.icon-font-italic tooltip", { onclick: function () { TextEditor.editor.italic(); }, "data-tooltip": i18n.t("italic") }),
                    m("i.icon-underline tooltip", { onclick: function () { TextEditor.editor.underline(); }, "data-tooltip": i18n.t("underline") }),
                    m(".tab-dropdown", [
                        m("i.icon-font-size tooltip", {
                            style: "vertical-align: sub;",
                            onclick: function () {
                                document.querySelector(".font-colour-select").classList.remove("open");
                                document.querySelector(".font-size-select").classList.toggle("open");
                            }, "data-tooltip": i18n.t("text_size")
                        }),
                        m("ul.font-size-select", [
                            m("li", { onclick: function () { TextEditor.setTextSize("0.3em"); } }, "8"),
                            m("li", { onclick: function () { TextEditor.setTextSize("0.5em"); } }, "12"),
                            m("li", { onclick: function () { TextEditor.setTextSize("0.75em"); } }, "18"),
                            m("li", { onclick: function () { TextEditor.setTextSize("1em"); } }, "24"),
                            m("li", { onclick: function () { TextEditor.setTextSize("1.3em"); } }, "32"),
                            m("li", { onclick: function () { TextEditor.setTextSize("2em"); } }, "48"),
                            m("li", { onclick: function () { TextEditor.setTextSize("2.6em"); } }, "64")
                        ])
                    ]),
                    m(".tab-dropdown", [
                        m("i.icon-font-colour tooltip", {
                            style: "vertical-align: sub;",
                            onclick: function () {
                                document.querySelector(".font-size-select").classList.remove("open");
                                document.querySelector(".font-colour-select").classList.toggle("open");
                            }, "data-tooltip": i18n.t("text_colour")
                        }),
                        m("ul.font-colour-select", [
                            m("li", { onclick: function () { TextEditor.setTextColour("#ccc"); }, style: "background: #ccc;" }),
                            m("li", { onclick: function () { TextEditor.setTextColour("#888"); }, style: "background: #888;" }),
                            m("li", { onclick: function () { TextEditor.setTextColour("#555"); }, style: "background: #555;" }),
                            m("li", { onclick: function () { TextEditor.setTextColour("#000"); }, style: "background: #000;" }),
                            m("li", { onclick: function () { TextEditor.setTextColour("#f00"); }, style: "background: #f00;" }),
                            m("li", { onclick: function () { TextEditor.setTextColour("#0f0"); }, style: "background: #0f0;" }),
                            m("li", { onclick: function () { TextEditor.setTextColour("#00f"); }, style: "background: #00f;" }),
                            m("li", { onclick: function () { TextEditor.setTextColour("#00f"); }, style: "background: #fff;" })
                        ])
                    ]),
                    m("i.icon-hyperlink tooltip", { onclick: function () { TextEditor.openHyperlinkEditor(); }, "data-tooltip": i18n.t("hyperlink") }),
                    m("i.icon-align-left tooltip", { onclick: function () { TextEditor.editor.setTextAlignment("left"); }, "data-tooltip": i18n.t("align_left") }),
                    m("i.icon-align-center tooltip", { onclick: function () { TextEditor.editor.setTextAlignment("center"); }, "data-tooltip": i18n.t("align_center") }),
                    m("i.icon-align-right tooltip", { onclick: function () { TextEditor.editor.setTextAlignment("right"); }, "data-tooltip": i18n.t("align_right") }),
                    m("i.icon-list-bullet tooltip", { onclick: function () { TextEditor.editor.makeUnorderedList(); }, "data-tooltip": i18n.t("list_bullet") }),
                    m("i.icon-list-numbered tooltip", { onclick: function () { TextEditor.editor.makeOrderedList(); }, "data-tooltip": i18n.t("list_numbered") }),
                    m("i.icon-list tooltip", { onclick: function () { TextEditor.editor.removeList(); }, "data-tooltip": i18n.t("remove_list") }),
                    m("i.icon-indent-more tooltip", { onclick: function () { TextEditor.editor.increaseQuoteLevel(); }, "data-tooltip": i18n.t("indent_more") }),
                    m("i.icon-indent-less tooltip", { onclick: function () { TextEditor.editor.decreaseQuoteLevel(); }, "data-tooltip": i18n.t("indent_less") }),
                    m("i.icon-undo tooltip", { onclick: function () { TextEditor.editor.undo(); }, "data-tooltip": i18n.t("undo") }),
                    m("i.icon-font-clear-style tooltip", { onclick: function () { TextEditor.editor.removeAllFormatting(); }, "data-tooltip": i18n.t("clear_style") })
                ]),
                m(".input richtext", {
                    oncreate: function (e) {
                        TextEditor.editor = new Squire(e.dom);
                        TextEditor.editor.setHTML(TextUtilities.safeDecodeURIComponent(TextEditor.content));
                        TextEditor.editor.addEventListener("input", function () { TextEditor.content = TextEditor.editor.getHTML(); });
                        // Listen for the willPaste event
                        TextEditor.editor.addEventListener("willPaste", (event) => {
                            let pastedHTML = "";
                            event.fragment.childNodes.forEach((node) => {
                                const tempDiv = document.createElement("div");
                                tempDiv.appendChild(node.cloneNode(true));
                                pastedHTML += tempDiv.innerHTML;
                            });

                            // Sanitize the HTML
                            const sanitizedHTML = sanitizeInput(pastedHTML);

                            // Clear the fragment and insert the sanitized content
                            while (event.fragment.firstChild) {
                                event.fragment.removeChild(event.fragment.firstChild);
                            }

                            const sanitizedFragment = document.createRange().createContextualFragment(sanitizedHTML);
                            event.fragment.appendChild(sanitizedFragment);
                        });
                    }
                })
            ])
        ];
    }
};

export default TextEditor;
